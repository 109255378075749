import {useState} from "react";
import useDeviceType from "../../hooks/useDeviceType";
import MSelect from "../../components/common/m-select/MSelect";

const Teacher = () => {
    const [activeTab, setActiveTab] = useState('not-done');
    const {isMobile} = useDeviceType();
    const options = [
        {
            name:'Отправленные',
            value: 'Submitted'
        },
        {
            name:'Сохраненные',
            value: 'Saved'
        }
    ]
    return (
        <div className="py-[50px] px-5 flex flex-col gap-[50px]">
            <p className={'font-medium text-[28px]'}>Главная</p>
            <div className={'flex gap-10'}>
                <div className={'flex-1'}>
                    <p className={'font-bold text-xl mb-4'}>Работы</p>
                    <div className={'flex mb-4'}>
                        {isMobile ? <MSelect options={options} border={true} className={'flex-auto'}/> : (
                            <>
                                <div className={'border-gray6 border-b w-4'}/>
                                <div
                                    className={`px-4 py-3 font-medium text-sm ${activeTab === 'not-done' ? 'border-gray6 border border-b-0 rounded-t-lg' : 'border-b text-main-color'}`}
                                    onClick={() => setActiveTab('not-done')}>
                                    Отправленные
                                </div>
                                <div className={'border-gray6 border-b w-4'}/>
                                <div
                                    className={`px-4 py-3 font-medium text-sm ${activeTab === 'done' ? 'border-gray6 border border-b-0 rounded-t-lg' : 'border-b text-main-color'}`}
                                    onClick={() => setActiveTab('done')}
                                >
                                    Сохраненные
                                </div>
                                <div className={'border-gray6 border-b w-full'}/>
                            </>
                        )}
                    </div>
                    <div>
                        <p className={'text-gray4'}>Данные отсутствуют</p>
                    </div>
                </div>
                <div className={'flex-1'}>
                    <p className={'font-bold text-xl mb-4'}>Журналы</p>
                    <p className={'text-gray4 mb-8'}>Данные отсутствуют</p>
                    <button className={'btn-primary'}>Все журналы</button>
                </div>
            </div>
        </div>
    )
}

export default Teacher;