import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Bookmark from '../../assets/img/bookmark.png'
import ActiveBookmark from '../../assets/img/bookmarkActive.png'
import {
    useAddBookToFavoriteMutation,
    useDeleteBookFromFavoriteMutation
} from "../../api/LibraryApi/LibraryService";
import {setIsError} from "../../store/reducers/notificationSlice";

function BookItem({book, ...props}) {
    const isAuth = useSelector(state => state.auth.isAuth);
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const isAddFavoriteAvailable = ['teacher', 'student', 'private_user'].includes(user.activeRole)
    const [isActive, setIsActive] = useState(book.is_favorite);
    const handleBookmarkClick = async (e) => {
        e.stopPropagation();
        if (isActive) {
            const deleteResult = await deleteBook({book_id: book.book_id})
            if(deleteResult.error){
                dispatch(setIsError('Delete failed'))
            } else {
                setIsActive(false)
            }
        } else {
            const addResult = await addBook({book_id: book.book_id})
            if(addResult.error){
                dispatch(setIsError('Add failed'))
            } else {
                setIsActive(true)
            }
        }
    }
    const [addBook] = useAddBookToFavoriteMutation();
    const [deleteBook] = useDeleteBookFromFavoriteMutation()

    return (
        <div>
            <div className="relative w-[156px] m-2 desktop:m-4 flex flex-col hover:cursor-pointer tablet:hidden">
                <Link to={`/library/${book.book_id}`} state={{url: `${book.url}/${book.book_id}/`}}>
                    <div className='h-[208px]'>
                        <img src={book.cover} alt={book.name}/>
                    </div>
                    <span className="text-gray-400 mt-2">"{book.publisher}"</span>
                    <span className="line-clamp-3">{book.name}</span>
                </Link>
                {isAuth && isAddFavoriteAvailable && <img
                    className={'absolute top-[-2px] cursor-pointer z-50 right-1'}
                    src={isActive ? ActiveBookmark : Bookmark}
                    alt={'Bookmark'}
                    onMouseDown={handleBookmarkClick}
                />}
            </div>
            <div className="relative w-[184px] m-2 tablet:flex desktop:hidden flex-col hover:cursor-pointer hidden">
                <Link to={`/library/${book.book_id}`} state={{url: `${book.url}/${book.book_id}/`}}>
                    <div className='h-[245px]'>
                        <img src={book.cover} alt={book.name}/>
                    </div>
                    <span className="text-gray-400 mt-2">"{book.publisher}"</span>
                    <span className="line-clamp-3">{book.name}</span>
                </Link>
                {isAuth && isAddFavoriteAvailable && <img
                    className={'absolute top-[-2px] cursor-pointer z-50 right-1'}
                    src={isActive ? ActiveBookmark : Bookmark}
                    alt={'Bookmark'}
                    onMouseDown={handleBookmarkClick}
                />}
            </div>
            <div className="relative w-[115px] m-1 desktop:m-4 flex-col hover:cursor-pointer hidden desktop:flex">
                <Link to={`/library/${book.book_id}`} state={{url: `${book.url}/${book.book_id}/`}}>
                    <div className='h-[153px]'>
                        <img src={book.cover} alt={book.name}/>
                    </div>
                    <span className="text-gray-400 mt-2">"{book.publisher}"</span>
                    <span className="line-clamp-3">{book.name}</span>
                </Link>
                {isAuth && isAddFavoriteAvailable && <img
                    className={'absolute top-[-2px] cursor-pointer z-50 right-1'}
                    src={isActive ? ActiveBookmark : Bookmark}
                    alt={'Bookmark'}
                    onMouseDown={handleBookmarkClick}
                />}
            </div>
        </div>
    );
}

export default BookItem;
