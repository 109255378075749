import GoogleAnalytics from "../../../assets/img/GoogleAnalytics.png";
import MIcon from "../m-icon/MIcon";
import React from "react";
import {useTranslation} from "react-i18next";

const MStatsTable = ({data=[], title = '', total = null, withShowMore = true }) => {
    const {t} = useTranslation();
    return (
        <div className={'bg-gray2 px-6 pt-9 pb-4 flex-col gap-4 flex flex-1 rounded-lg'}>
            <p className={'text-xl font-bold mb-2'}>{title}</p>
            {data.map(item=> (
                <div
                    className={'flex justify-between border-b-2 border-gray3 pb-4 font-medium'}
                    key={item.text}
                >
                    <p>{item.text}</p>
                    <p>{item.count}</p>
                </div>
            ))}
            {total && (
                <div className={'flex justify-between pb-4 font-medium'}>
                    <p className={'font-bold'}>Всего</p>
                    <p>{total}</p>
                </div>
            )}
            {withShowMore && (
                <div className={'flex justify-between items-center'}>
                    <img src={GoogleAnalytics} alt="GoogleAnalyticsImage"/>
                    <button className={'btn-secondary font-normal flex items-center gap-2'} disabled>
                        {t('superadminPage.more')}
                        <MIcon type={'arrowRight'} className={'text-disabled-text'}/>
                    </button>
                </div>
            )}
        </div>
    )
}
export default MStatsTable;