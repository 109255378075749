import {useGetListMutation} from "../../api/LibraryApi/LibraryService";
import React, {useEffect, useState} from "react";
import BookItem from "../../components/library/BookItem";
import {useDispatch} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import Spinner from "../../components/common/spinner/spinner";

const MyTextbooks = () => {
    const [getFavorite] = useGetListMutation();
    const dispatch = useDispatch();
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getData = async () => {
        setIsLoading(true)
        const favorite = await getFavorite(`?is_favorite=true`);
        if (favorite.error) {
            dispatch(setIsError('FError'))
        } else {
            setBooks(favorite.data.books)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <Spinner spinning={isLoading}>
            <div className="py-[50px] px-5 flex flex-col gap-10">
                <p className={'font-medium text-[28px]'}>Мои учебники</p>
                {books.length ? (
                    <div className="flex flex-wrap mb-6 justify-around desktop:justify-start">
                        {
                            books.map(book => {
                                return (
                                    <BookItem key={book.book_id} book={book} isFavorite={true}/>
                                )
                            })
                        }
                    </div>
                ) : (
                    <p className="font-bold">Найдено 0 учебников</p>
                )}
            </div>
        </Spinner>
    )
}
export default MyTextbooks;