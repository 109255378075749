import React, { useState } from 'react';

import MModal from '../../../common/m-modal/MModal';
import MInput from '../../../common/m-input/MInput';
import MError from '../../../common/m-error/MError';

import { useResetPasswordMutation } from '../../../../api/AuthApi/authService';

import { authRegex } from '../../../../models/auth';
import { useTranslation } from 'react-i18next';
import {setIsError} from "../../../../store/reducers/notificationSlice";
import {useDispatch} from "react-redux";

const ForgotPasswordModal = ({
    isForgotModal,
    setIsForgotModal,
    setIsConfirmPasswordResetModal
}) => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const [resetPassword] = useResetPasswordMutation();

    const {t} = useTranslation();

    const onHandleSendButton = async () => {
        if (!username.trim()) {
            setError('email');
        } else if (!authRegex.email.test(username)) {
            setError('invalidLogin');
        } else {
            const result = await resetPassword({ username });
            if (result.error) {
                console.error(result.error);
                dispatch(setIsError(t(`general.models.serverErrors.resetPassEmail.${result.error.status}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                setIsForgotModal(false)
                setIsConfirmPasswordResetModal(username)
            }
        }
    };

    return (
        <MModal
            active={isForgotModal}
            setActive={setIsForgotModal}
            onCloseModal={() => {
                setUsername('');
                setError('');
            }}
        >
            <div className="flex flex-col w-96">
                <h5 className="text-xl font-bold mx-auto my-3">
                    {t('general.header.forgotPassModal.title')}
                </h5>

                <MInput
                    placeholder={t('general.header.forgotPassModal.emailPlaceholder')}
                    value={username}
                    setValue={(e) => setUsername(e.target.value)}
                />

                {error && <MError error={error}/>}

                <p className="font-medium text-xs ml-4 mb-5">
                    {t('general.header.forgotPassModal.text')}
                </p>

                <button
                    className="btn-primary my-2"
                    onClick={onHandleSendButton}
                    disabled={!username}
                >
                    {t('general.header.forgotPassModal.send')}
                </button>
            </div>
        </MModal>
    );
}
export default ForgotPasswordModal;