export const userRoleOptions = [
    {
        value: 'student',
        extraClassName: '!text-[#76A6C4] !bg-[#E7F4FC]'
    },
    {
        value: 'teacher',
        extraClassName: '!text-[#DD914A] !bg-[#FFEFB7]'
    },
    {
        value: 'school_admin',
        extraClassName: '!text-[#EB6244] !bg-[#FFCAB4]'
    },
    {
        value: 'private_user',
        extraClassName: '!text-gray-400 !bg-gray-100'
    },
    {
        value: 'superadmin',
        extraClassName: '!text-green-400 !bg-green-100'
    },
    /*{
        value: 'expert_student',
        extraClassName: '!text-indigo-400 !bg-indigo-100'
    },
    {
        value: 'expert_teacher',
        extraClassName: '!text-emerald-400 !bg-emerald-100'
    }*/
]
export const userRoleOptionsForSchoolAdmin = [
    {
        value: 'student',
        extraClassName: '!text-[#76A6C4] !bg-[#E7F4FC]'
    },
    {
        value: 'teacher',
        extraClassName: '!text-[#DD914A] !bg-[#FFEFB7]'
    },
    {
        value: 'school_admin',
        extraClassName: '!text-[#EB6244] !bg-[#FFCAB4]'
    }
]


export const userStatusOptions = [
    {
        value: '0'
    },
    {
        value: '1'
    },
    {
        value: '2'
    },
    {
        value: '3'
    }
]

export const subscriptionPresenceOptions = [
    {
        value: 'yes'
    },
    {
        value: 'no'
    }
]
