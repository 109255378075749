import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Eula(props) {
    const {t} = useTranslation();
    return (
        <div className="flex justify-start flex-col px-8 w-full mb-8 tablet:w-[802px]">
            <h1 className="my-6 font-bold text-3xl">{t('eulaPage.title')}</h1>
            <p className="mb-6 font-bold">{t('eulaPage.subtitle')}</p>
            <p className="mb-6">
                {t('eulaPage.paragraph1')}
                <a className="underline text-blue3" href="mailto:dgmektep@inbox.ru">dgmektep@inbox.ru</a>.
            </p>
            <p className="mb-6">
                {t('eulaPage.paragraph2')}
            </p>
            <ol>
                <li>
                    <p className="font-bold mb-2">
                        1. {t('eulaPage.data.concepts.1')}
                    </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>1.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.concepts.1.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>1.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.concepts.1.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>1.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.concepts.1.3')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>1.4.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.concepts.1.4')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>1.5.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.concepts.1.5')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>1.6.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.concepts.1.6')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                            2. {t('eulaPage.data.termsOfUse.2')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>2.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.3')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.4.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.4')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.5.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.5')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.6.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.6')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.7.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.7')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.8.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.8')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.9.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.9')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>2.10.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.termsOfUse.2.10')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                           3. {t('eulaPage.data.technicalDevelopments.3')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>3.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.technicalDevelopments.3.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>3.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.technicalDevelopments.3.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>3.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.technicalDevelopments.3.3')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">

                        <p className="font-bold mb-2">
                            4. {t('eulaPage.data.paymentAndSettlement.4')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>4.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.paymentAndSettlement.4.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>4.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.paymentAndSettlement.4.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>4.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.paymentAndSettlement.4.3')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>4.4.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.paymentAndSettlement.4.4')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                           5. {t('eulaPage.data.intellectualProperty.5')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>5.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.intellectualProperty.5.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>5.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.intellectualProperty.5.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>5.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.intellectualProperty.5.3')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                            6. {t('eulaPage.data.confidentiality.6')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>6.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.confidentiality.6.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>6.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.confidentiality.6.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>6.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.confidentiality.6.3')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                           7. {t('eulaPage.data.personalDataProtection.7')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>7.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.personalDataProtection.7.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>7.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.personalDataProtection.7.2')}
                               <Link to={'/privacy'} className={'text-main-color underline border-main-color'}>
                                   {t('eulaPage.data.personalDataProtection.linkText')}
                               </Link>
                                {!t('eulaPage.data.personalDataProtection.afterLinkText').startsWith('eulaPage') && t('eulaPage.data.personalDataProtection.afterLinkText')}
                            </div>

                        </li>
                        <li className="flex flex-row py-2">
                            <div>7.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.personalDataProtection.7.3')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                            8. {t('eulaPage.data.responsibility.8')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>8.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.responsibility.8.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>8.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.responsibility.8.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>8.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.responsibility.8.3')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>8.4.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.responsibility.8.4')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>8.5.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.responsibility.8.5')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>8.6.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.responsibility.8.6')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                            9. {t('eulaPage.data.messageDelivery.9')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>9.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.messageDelivery.9.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>9.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.messageDelivery.9.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>9.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.messageDelivery.9.3')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>9.4.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.messageDelivery.9.4')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                            10. {t('eulaPage.data.suspensionAndTermination.10')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>10.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.2')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.3.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.3')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.4.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.4')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.5.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.5')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.6.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.6')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.7.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.7')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>10.8.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.suspensionAndTermination.10.8')}
                            </div>
                        </li>
                    </ol>
                </li>
                <li className="mt-6">
                        <p className="font-bold mb-2">
                            11. {t('eulaPage.data.disputeResolution.11')}
                        </p>
                    <ol>
                        <li className="flex flex-row py-2">
                            <div>11.1.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.disputeResolution.11.1')}
                            </div>
                        </li>
                        <li className="flex flex-row py-2">
                            <div>11.2.&nbsp;</div>
                            <div>
                                {t('eulaPage.data.disputeResolution.11.2')}
                            </div>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default Eula;
