import React from 'react';
import BlockCard from './BlockCard';
import block1 from '../../../assets/img/Block1.png'
import block2 from '../../../assets/img/Block2.png'
import block3 from '../../../assets/img/Block3.png'
import block4 from '../../../assets/img/Block4.png'
import { useTranslation } from 'react-i18next';

function Blocks(props) {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col items-center my-8">
            <h2 className="font-medium text-main-color mx-auto text-2xl my-6">
                {t('mainPage.blocks.mainTitle')}
            </h2>
            <div className="flex flex-wrap justify-center">
                <BlockCard
                    img={block1}
                    title={t('mainPage.blocks.blockTitle1')}
                    text={t('mainPage.blocks.blockText1')}
                />
                <BlockCard
                    img={block4}
                    title={t('mainPage.blocks.blockTitle4')}
                    text={t('mainPage.blocks.blockText4')}
                />
                <BlockCard
                    img={block3}
                    title={t('mainPage.blocks.blockTitle3')}
                    text={t('mainPage.blocks.blockText3')}
                />
                <BlockCard
                    img={block2}
                    title={t('mainPage.blocks.blockTitle2')}
                    text={t('mainPage.blocks.blockText2')}
                />
            </div>
        </div>
    );
}

export default Blocks;
