const TikTok = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="20"
              height="23"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M13.7495 16.2069V7.1548C13.7495 7.1548 14.8065 8.85544 18.6277 8.96028C18.8317 8.96552 19 8.80377 19 8.60757V6.0495C19 5.85854 18.8426 5.70802 18.6456 5.69754C15.6822 5.53504 14.2745 3.34091 14.1365 1.328C14.1233 1.14153 13.955 1 13.7611 1H10.7395C10.5379 1 10.375 1.15726 10.375 1.35196V15.7411C10.375 17.304 9.13798 18.6639 7.52094 18.738C5.67278 18.8226 4.16974 17.2905 4.39853 15.4805C4.55985 14.2015 5.62314 13.1546 6.94469 12.9771C7.20683 12.9419 7.46432 12.9397 7.71172 12.9659C7.93121 12.9898 8.1251 12.8318 8.1251 12.6184V10.0536C8.1251 9.87088 7.98162 9.71362 7.79238 9.70239C7.41313 9.67842 7.02458 9.68741 6.63059 9.73009C3.71526 10.0491 1.36299 12.3271 1.04036 15.142C0.614573 18.8563 3.61444 22 7.37513 22C10.8962 22 13.7502 19.2442 13.7502 15.8445"
                stroke="currentColor"
                strokeWidth="1.5"
            />
          </svg>
        </span>
    );
};

export default TikTok;
