import Rocket from '../../assets/img/Rocket.png'
import RightCloud from '../../assets/img/RightCloud.png'
import LeftCloud from '../../assets/img/LeftCloud.png'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const FooterInfo = ({children, color, width}) => {
    const navigate = useNavigate();
    const redirectToPackages = () => {
        navigate({ pathname: '/', hash: '#packages-section' });
    };

    const {t} = useTranslation();
    return (
        <div className={`bg-${color} relative w-[100vw] left-[50%] right-[50%] ml-[-50vw] tablet:min-h-[370px] text-white px-3 tablet:px-[45px] flex items-center justify-center`}>
            <div className={'w-[1302px] relative mb-11 mt-8 tablet:my-[45px]'}>
                <h3 className={'font-semibold text-[24px] tablet:text-[32px] mb-4'}>{t('privateUserAdvantagePage.footer.globalText')}</h3>
                <div className={`tablet:w-[515px] w-full`}>
                    {children}
                </div>
                {color !== 'private_user' && <button onClick={redirectToPackages} className={'px-8 py-4 w-fit rounded-lg text-black bg-orange1'}>{t('privateUserAdvantagePage.footer.globalText')}</button>}
                    <img className={`hidden tablet:block absolute top-[50%] translate-y-[-50%] ${color !=='private_user' ? 'right-[10%]' : 'right-[8%]'} z-0`}
                         src={Rocket} alt="Rocket"/>
                    <img className={'hidden tablet:block absolute top-6 right-[0%]'} src={RightCloud}
                         alt="Right cloud"/>
                    <img className={'hidden tablet:block absolute top-[-30px] right-[27%]'} src={LeftCloud}
                         alt="Left cloud"/>
            </div>
        </div>
    )
}
export default FooterInfo;