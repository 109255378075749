import React from 'react';

import MModal from '../../../common/m-modal/MModal';
import { useTranslation } from 'react-i18next';

const ConfirmResetPasswordModal = ({
    isConfirmResetPasswordModal,
    setIsConfirmResetPasswordModal
}) => {

    const {t} = useTranslation();

    return (
        <MModal
            active={!!isConfirmResetPasswordModal}
            setActive={setIsConfirmResetPasswordModal}
        >
            <div className="flex flex-col w-96">
                <h5 className="text-xl font-semibold mx-auto my-3">
                    {t('general.header.confirmPassModal.title')}
                </h5>

                <p className="font-medium text-sm text-center">
                    {t('general.header.confirmPassModal.emailSend')}
                    <span className="text-cyan-700"> {isConfirmResetPasswordModal} </span>
                    {t('general.header.confirmPassModal.toConfirm')}
                </p>
            </div>
        </MModal>
    );
};
export default ConfirmResetPasswordModal;