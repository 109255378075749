import './App.css';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Library from './views/library/Library';
import Search from './views/search/Search';
import Layout from './components/layout/Layout';
import Eula from './views/eula/Eula';
import Privacy from './views/privacy/Privacy';
import CookiesPage from './views/cookies/Cookies';
import Packages from './views/packages/Packages';
import Book from './components/book/Book';
import Faq from './views/faq/FAQ';
import PackagePage from './components/package/PackagePage';
import MainPageLayout from './views/main-page/mainPageLayout';
import PassChange from './views/passChange/PassChange';
import {useEffect} from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRole, setName, setUser } from './store/reducers/userSlice';
import {logOut, setCredentials, setIsAuth} from './store/reducers/authSlice';
import { useAuthUserMutation } from './api/AuthApi/authService';
import { useLazyGetUserViewQuery } from './api/UserApi/userView';
import ConfirmationRegistration from './views/confirmation/ConfirmationRegistration';
import ConfirmationReset from './views/confirmation/ConfirmationReset';
import MModalError from "./components/common/m-modal-error/MModalError";
import MModalSuccess from "./components/common/m-modal-success/MModalSuccess";
import {setIsError} from "./store/reducers/notificationSlice";
import {useTranslation} from "react-i18next";
import PrivateUserAdvantage from "./views/advantages/PrivateUserAdvantage";
import SchoolAdvantage from "./views/advantages/SchoolAdvantage";
import TeacherAdvantage from "./views/advantages/TeacherAdvantage";
import StudentAdvantage from "./views/advantages/StudentAdvantage";
import Support from "./views/support/support";
import Users from './views/users/Users';
import UserView from './views/users/UserView';
import SuperAdmin from './views/superAdmin/SuperAdmin';
import Schools from './views/schools/Schools';
import SchoolView from './views/schools/SchoolView';
import UserEdit from './views/users/UserEdit';
import Subscriptions from './views/subscriptions/Subscriptions';
import SubscriptionAdd from './views/subscriptions/SubscriptionAdd';
import Create from './views/superAdmin/Create';
import Spinner from './components/common/spinner/spinner';
import SchoolEdit from './views/schools/SchoolEdit';
import Student from "./views/student/Student";
import StudentsWorks from "./views/studentsWorks/StudentsWorks";
import MyTextbooks from "./views/myTextbooks/МyTextbooks";
import Teacher from "./views/teacher/Teacher";
import TeachersMagazines from "./views/teachersMagazines/TeachersMagazines";
import Settings from "./views/settings/Settings";
import PrivateUser from "./views/privateUser/PrivateUser";
import SchoolAdmin from "./views/schoolAdmin/SchoolAdmin";
import SchoolAdminSubs from "./views/SchoolAdminSubs/SchoolAdminSubs";
import SchoolAdminCreate from "./views/school-admin-create/SchoolAdminCreate";
import SchoolAdminSubsAdd from "./views/SchoolAdminSubsAdd/SchoolAdminSubsAdd";
import RequireAuth from "./components/app/PrivateRoute";
import SchoolUsers from "./views/school-users/SchoolUsers";

function App() {
    const dispatch = useDispatch();
    const [authUser] = useAuthUserMutation();
    const [getUserInfo] = useLazyGetUserViewQuery();
    const {t} = useTranslation();
    const isGlobalLoading = useSelector(state=> state.spinner.loading);
    const navigate = useNavigate()
    const url = new URL(window.location.href);
    const accessToken = url.hash?.split('&').find(param => param.startsWith('access_token'))?.split('=')[1];
    useEffect(() => {
        const cookies = new Cookies();
        if(accessToken) {
            dispatch(setCredentials(accessToken))
            dispatch(setIsAuth(true))
            dispatch(setActiveRole('private_user'))
            cookies.set('jwt_auth', null, { httpOnly: true });
        } else {
            const username = JSON.parse(localStorage.getItem('user'))?.username;
            const refresh_token = JSON.parse(localStorage.getItem('jwt_refresh'))
            if(username && refresh_token){
                (async()=>{
                    const storedUser = JSON.parse(localStorage.getItem('user'))
                    dispatch(setActiveRole(storedUser.activeRole))
                    dispatch(setName({firstname: storedUser.firstname, lastname: storedUser.lastname}))
                    dispatch(setIsAuth(true))
                    const result = await authUser({ username, grant_type: 'refresh_token', refresh_token })
                    if (result.error) {
                        console.error(result.error)
                        navigate('/')
                        // dispatch(setIsError(t(`general.models.serverErrors.auth.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
                    } else {
                        dispatch(setCredentials(result.data.access_token))
                        cookies.set('jwt_auth', result.data.access_token, { httpOnly: true });
                        localStorage.setItem('jwt_refresh',JSON.stringify(result.data.refresh_token))
                        const user = await getUserInfo({ username, role: result.data.roles[0] });
                        if (user.error) {
                            console.error(user.error);
                            dispatch(setIsError(t(`general.models.serverErrors.UView.${user.error.data?.error_code}`, `${user.error.data?.error_code}: ${user.error.data?.message}`)))
                        } else {
                            const isUserHasRole = user.data.user.roles.includes(storedUser.activeRole);
                            dispatch(setUser({...storedUser}));
                            localStorage.setItem('user',JSON.stringify({...storedUser, activeRole: isUserHasRole ? storedUser.activeRole : user.data.user.roles[0]}))
                            dispatch(setActiveRole(isUserHasRole ? storedUser.activeRole : user.data.user.roles[0]));
                        }
                    }

                })()
            } else {
                dispatch(logOut())
            }
        }
    }, []);
    return (
        <>
            <Spinner spinning={isGlobalLoading} fullScreen={true} />
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<MainPageLayout/>}/>
                    <Route path="library" element={<Library/>}/>
                    <Route path="library/:id" element={<Book/>}/>
                    <Route path="search" element={<Search/>}/>
                    <Route path="packages" element={<Packages/>}/>
                    <Route path="package" element={<PackagePage/>}/>
                    <Route path="advantages/school" element={<SchoolAdvantage/>} />
                    <Route path="advantages/teacher" element={<TeacherAdvantage/>} />
                    <Route path="advantages/student" element={<StudentAdvantage/>} />
                    <Route path="advantages/privateUser" element={<PrivateUserAdvantage/>} />
                    <Route path="faq" element={<Faq/>}/>
                    <Route path="eula" element={<Eula/>}/>
                    <Route path="privacy" element={<Privacy/>}/>
                    <Route path="cookies" element={<CookiesPage/>}/>
                    <Route path="support" element={<Support/>}/>
                    <Route path="settings" element={
                        <RequireAuth>
                            <Settings/>
                        </RequireAuth>
                    }/>
                    <Route path="change-password" element={
                        <RequireAuth>
                            <PassChange/>
                        </RequireAuth>
                    }/>
                    <Route path="confirmation-registration" element={<ConfirmationRegistration/>}/>
                    <Route path="confirmation-reset" element={<ConfirmationReset/>}/>
                    <Route path="users" element={
                        <RequireAuth roles={['superadmin', 'school_admin']}>
                            <Users/>
                        </RequireAuth>
                    }/>
                    <Route path="users/:username/:role" element={
                        <RequireAuth roles={['superadmin', 'school_admin']}>
                            <UserView/>
                        </RequireAuth>
                    }/>
                    <Route path="create/:type" element={
                        <RequireAuth roles={['superadmin']}>
                            <Create/>
                        </RequireAuth>
                    }/>
                    <Route path="users/edit/:username/:role" element={
                        <RequireAuth roles={['superadmin', 'school_admin']}>
                            <UserEdit/>
                        </RequireAuth>
                    }/>
                    <Route path="subscriptions" element={
                        <RequireAuth roles={['superadmin']}>
                            <Subscriptions/>
                        </RequireAuth>
                    }/>
                    <Route path="subscriptions/add" element={
                        <RequireAuth roles={['superadmin']}>
                            <SubscriptionAdd/>
                        </RequireAuth>
                    }/>
                    <Route path="super-admin" element={
                        <RequireAuth roles={['superadmin']}>
                            <SuperAdmin/>
                        </RequireAuth>
                    }/>
                    <Route path="schools" element={
                        <RequireAuth roles={['superadmin']}>
                            <Schools/>
                        </RequireAuth>
                    }/>
                    <Route path="schools/:id" element={
                        <RequireAuth roles={['superadmin']}>
                            <SchoolView/>
                        </RequireAuth>
                    }/>
                    <Route path="schools/edit/:id" element={
                        <RequireAuth roles={['superadmin']}>
                            <SchoolEdit/>
                        </RequireAuth>
                    }/>
                    <Route path="student" element={
                        <RequireAuth roles={['student']}>
                            <Student/>
                        </RequireAuth>
                    }/>
                    <Route path="student/works" element={
                        <RequireAuth roles={['student']}>
                            <StudentsWorks/>
                        </RequireAuth>
                    }/>
                    <Route path="private-user" element={
                        <RequireAuth roles={['private_user']}>
                            <PrivateUser/>
                        </RequireAuth>
                    }/>
                    <Route path="textbooks" element={
                        <RequireAuth roles={['private_user', 'teacher', 'student']}>
                            <MyTextbooks/>
                        </RequireAuth>
                }/>
                    <Route path="teacher" element={
                        <RequireAuth roles={['teacher']}>
                            <Teacher/>
                        </RequireAuth>
                    }/>
                    <Route path="magazines" element={
                        <RequireAuth roles={['teacher', 'school_admin']}>
                            <TeachersMagazines/>
                        </RequireAuth>
                    }/>
                    <Route path="school-admin" element={
                        <RequireAuth roles={['school_admin']}>
                            <SchoolAdmin/>
                        </RequireAuth>
                    }/>
                    <Route path="school-admin/subscriptions" element={
                        <RequireAuth roles={['school_admin']}>
                            <SchoolAdminSubs/>
                        </RequireAuth>
                    }/>
                    <Route path="school-admin/subscriptions/add/:id" element={
                        <RequireAuth roles={['school_admin']}>
                            <SchoolAdminSubsAdd/>
                        </RequireAuth>
                    }/>
                    <Route path="school-admin/users" element={
                        <RequireAuth roles={['school_admin']}>
                            <SchoolUsers/>
                        </RequireAuth>
                    }/>
                    <Route path="school-admin/create/user" element={
                        <RequireAuth roles={['school_admin']}>
                            <SchoolAdminCreate/>
                        </RequireAuth>
                    }/>
                    <Route path="*" element={<MainPageLayout/>}/>
                </Route>
            </Routes>

            <MModalError/>
            <MModalSuccess/>
        </>
    );
}

export default App;
