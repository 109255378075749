import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PackageCard({img, src, text, ...props}) {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col items-center m-2 tablet:w-[250px] ">
            <div className="border border-gray-300 w-full flex justify-center">
                <img src={img} alt="mektep" className="w-[202px]"></img>
            </div>
            <div className="tablet:h-[120px] flex items-center">
                <div className="w-full text-center py-6">{text}</div>
            </div>
            <Link className="btn-secondary-border mb-6" to={src}>{t('packagePage.review')}</Link>
        </div>
    );
}

export default PackageCard;

