
const HeaderInfo = ({ img, children, title, bgColor }) => {
    return (
        <div className={`flex bg-${bgColor} tablet:justify-start desktop:justify-around items-center relative w-[100vw] left-[50%] right-[50%] ml-[-50vw] h-min-[545px] py-[45px] px-3 tablet:px-[45px] gap-[11%] text-white`}>
            <div className={'max-w-[1302px] gap-[190px] flex'}>
                <img src={img} alt="user" className={'hidden desktop:block'}/>
                <div>
                    <h1 className={'text-[24px] tablet:text-[42px] font-semibold mb-[32px]'}>{title}</h1>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default HeaderInfo;
