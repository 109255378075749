import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Cookies(props) {

    const {t} = useTranslation();

    return (
        <>
            <div className="flex justify-start flex-col ml-8 tablet:max-w-[769px]">
                <h1 className="my-10 text-5xl">{t('cookiePage.title')}</h1>
                <p className="mb-6">{t('cookiePage.validFrom')}</p>
                <p className="mb-6">{t('cookiePage.seeAlso1')} <Link
                    className="link"
                    to="/eula"
                >{t('cookiePage.seeAlso2')}</Link>.</p>
                <p className="mb-6">{t('cookiePage.seeAlso1')} <Link
                    className="link"
                    to="/privacy"
                >{t('cookiePage.seeAlso3')}</Link>.</p>
                <p className="mb-6">
                    {t('cookiePage.description1')}
                </p>
                <p className="mb-6">
                    {t('cookiePage.description2')}
                </p>
                <p className="mb-6">
                    {t('cookiePage.description3')}
                </p>
                <p className="mb-6 font-bold">
                    {t('cookiePage.sessionCookiesTitle')}
                </p>
                <ul className="list-outside">
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.sessionCookiesTitle')} </span>
                            {t('cookiePage.sessionCookiesDescription')}
                        </div>
                    </li>
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.persistentCookiesTitle')} </span>
                            {t('cookiePage.persistentCookiesDescription')}
                        </div>
                    </li>
                </ul>
                <p className="mb-6 font-bold">
                    {t('cookiePage.firstPartyCookiesTitle')}
                </p>
                <ul className="list-outside">
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.firstPartyCookiesTitle')} </span>
                            {t('cookiePage.firstPartyCookiesDescription')}
                        </div>
                    </li>
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.thirdPartyCookiesTitle')} </span>
                            {t('cookiePage.thirdPartyCookiesDescription')}
                        </div>
                    </li>
                </ul>
                <p className="mb-6 font-bold">
                    {t('cookiePage.necessaryCookiesTitle')}
                </p>
                <ul className="list-outside">
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.necessaryCookiesTitle')} </span>
                            {t('cookiePage.necessaryCookiesDescription')}
                        </div>
                    </li>
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.preferenceCookiesTitle')} </span>
                            {t('cookiePage.preferenceCookiesDescription')}
                        </div>
                    </li>
                    <li className="ml-4 mb-6 flex flex-row">
                        <div className="mr-4 font-bold">•</div>
                        <div>
                            <span className="font-bold">{t('cookiePage.analyticalCookiesTitle')} </span>
                            {t('cookiePage.analyticalCookiesDescription')}
                        </div>
                    </li>
                </ul>
                <h3 className="text-2xl my-6 font-bold">
                    {t('cookiePage.agreePromptTitle')}
                </h3>
                <p className="mb-6">
                    {t('cookiePage.agreePromptDescription')}
                </p>
                <div className="flex flex-row mb-2">
                    <input
                        type="checkbox"
                        checked
                        className="mr-4 font-bold"
                    ></input>
                    <span className="font-bold">
            {t('cookiePage.necessaryCookiesCheckboxLabel')}
        </span>
                </div>
                <div className=" flex flex-row">
                    <input
                        type="checkbox"
                        checked
                        className="mr-4 font-bold"
                    />
                    <span className="font-bold">
            {t('cookiePage.analyticalCookiesCheckboxLabel')}
        </span>
                </div>
                <button className="btn-secondary-border my-6 tablet:w-3/4">{t('cookiePage.changeSettingsButtonText')}</button>
                <h3 className="text-2xl my-6 font-bold">
                    {t('cookiePage.usageTitle')}
                </h3>
                <h4 className="text-xl my-6 font-bold">
                    {t('cookiePage.necessaryCookiesTitle')}
                </h4>
                <p className="mb-6">
                    {t('cookiePage.necessaryCookiesDescription')}
                </p>
            </div>
            <div className="flex justify-start flex-col ml-8 tablet:max-w-[1200px]">
                <table className="mb-6">
                    <thead>
                        <tr className="font-bold w-full border border-white border-b-gray-200">
                            <td className="w-5/12">{t('cookiePage.cookieName')}</td>
                            <td className="w-2/12">{t('cookiePage.cookieExpiry')}</td>
                            <td className="w-5/12">{t('cookiePage.cookiePurpose')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                AspNetCore.Antiforgery
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.Antiforgery.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                .AspNetCore.Identity.Application
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.Antiforgery.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                Identity.External
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.External.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                Identity.ExternalC1
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-1/2">
                                {t('cookiePage.cookies.ExternalC1.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                Identity.ExternalC2
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.ExternalC2.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                .AspNetCore.Correlation
                            </td>
                            <td className="w-2/12">
                                15 {t('cookiePage.cookies.expiry.minutes')}*
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.Correlation.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                .AspNetCore.OpenIdConnect.Nonce
                            </td>
                            <td className="w-2/12">
                                15 {t('cookiePage.cookies.expiry.minutes')}*
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.Nonce.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                .AspNetCore.Mvc.CookieTempDataProvider
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.CookieTempDataProvider.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                DashboardCookie
                            </td>
                            <td className="w-2/12">
                                5 {t('cookiePage.cookies.expiry.minutes')}*
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.DashboardCookie.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                stopperElapsedTimeInMs
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.stopperElapsedTimeInMs.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                stopperElapsedTimeTimestamp
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.stopperElapsedTimeTimestamp.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                timerEndTimeInMs
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.timerEndTimeInMs.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                timerTimeLeft
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.timerTimeLeft.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                userCode
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.userCode.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                selectedTool
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.selectedTool.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                visible
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.visible.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                notify.success
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.success.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                notify.error
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.error.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                notify.footerError
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.footerError.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                notify.warning
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.warning.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                _grecaptcha
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.grecaptcha.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                ai_user
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.oneYear')}*
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.ai_user.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                ai_session
                            </td>
                            <td className="w-2/12">
                                30 {t('cookiePage.cookies.expiry.minutes')}**
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.ai_session.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                s_huaeli
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.permanent')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.s_huaeli.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                teacherDesktopTaskOrder
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.teacherDesktopTaskOrder.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                astra.langtag
                            </td>
                            <td className="w-2/12">
                                10 {t('cookiePage.cookies.expiry.years')}*
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.langtag.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                notificationsState
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.notificationsState.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                COOKIE_CONSENT
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.oneYear')}*
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.COOKIE_CONSENT.purpose')}
                            </td>
                        </tr>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-5/12">
                                checkoutorder
                            </td>
                            <td className="w-2/12">
                                1 {t('cookiePage.cookies.expiry.day')}**
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.cookies.checkoutorder.purpose')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="mb-6">
                    {t('cookiePage.expiry1')}
                </p>
                <p className="mb-6">
                    {t('cookiePage.expiry2')}
                </p>
                <h4 className="text-xl my-6 font-bold">
                    {t('cookiePage.analyticalDescription')}
                </h4>
                <table className="mb-6">
                    <thead>
                        <tr className="font-bold w-full border border-white border-b-gray-200">
                            <td className="w-5/12">{t('cookiePage.cookieNameColumn')}</td>
                            <td className="w-2/12">{t('cookiePage.cookieExpireColumn')}</td>
                            <td className="w-">{t('cookiePage.cookieAimColumn')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="w-full border border-white border-b-gray-200">
                            <td className="w-3">
                                referrerSite
                            </td>
                            <td className="w-2/12">
                                {t('cookiePage.cookies.expiry.session')}
                            </td>
                            <td className="w-5/12">
                                {t('cookiePage.purpose')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex justify-start flex-col ml-8 w-full tablet:max-w-[769px]">
                <h4 className="text-xl my-6 font-bold">
                    {t('cookiePage.thirdParty')}
                </h4>
                <p className="mb-6">
                    {t('cookiePage.thirdPartyDescr')}
                </p>
                <p className="mb-6">
                    {t('cookiePage.thirdPartyTerms')}
                </p>
                <p className="mb-6">
                    {t('cookiePage.googleTerms')}<br/>
                    <a
                        href="https://www.google.com/policies/technologies/cookies"
                        rel="noreferrer"
                        target="_blank"
                        className="link"
                    >
                        https://www.google.com/policies/technologies/cookies
                    </a>.
                </p>
                <p className="mb-6">
                    {t('cookiePage.facebookTerms')}<br/>
                    <a
                        href="https://www.facebook.com/policies/cookies/."
                        rel="noreferrer"
                        target="_blank"
                        className="link"
                    >
                        https://www.facebook.com/policies/cookies/
                    </a>.
                </p>
            </div>
        </>
    );
}

export default Cookies;
