import { api } from '../api';

const userEditApi = api.injectEndpoints({
    endpoints: (build) => ({
        editUser: build.mutation({
            query: ({ username, ...otherParams }) => ({
                url: `/user/edit?username=${username}`,
                method: 'PATCH',
                body: otherParams
            })
        })
    }),
});

export const { useEditUserMutation } = userEditApi;