
import {useCallback, useState} from 'react';

const useFormData = (initialFormData) => {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const onHandleChange = useCallback((limit, e, toLowerCase = false) => {
        const { name, value } = e.target;
        if(errors[name]){
            setErrors({...errors, [name]: null});
        }
        if(errors.other){
            setErrors({...errors, other: null});
        }
        if(value.length <= limit) {
            setFormData({
                ...formData, [name]: toLowerCase ? value.toLowerCase() : value
            });
        }
    },[errors, formData]);

    return {formData, errors, onHandleChange, setErrors, setFormData};
};

export default useFormData;