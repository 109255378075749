import {
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Chart,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {useTranslation} from "react-i18next";

const MBarChart = ({subs, width = '', height = ''}) => {
    Chart.register(
        BarElement,
        CategoryScale,
        LinearScale,
        Tooltip
    );
    const {t} = useTranslation();
    const data = {
        labels:[t('superadminPage.active'),t('superadminPage.inactive')],
        datasets: [{
            data: [subs.active, subs.inactive],
            backgroundColor: [
                '#3CC863',
                '#EC4B4B'
            ]
        }]
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: false
            },
            y: {
                ticks: {
                    display: false
                },
                grid: {
                    display: false
                }
            }
        }
    };
    return (
        <div className={`w-auto h-[234px] ${width || 'tablet:w-[614px]' } mb-28 tablet:mb-20`}>
                <Bar
                    data={data}
                    options={options}
                />
            <div className={'flex gap-6 pb-5 pt-4 font-medium flex-col tablet:flex-row'}>
                <div className={'flex items-center'}>
                    <span className={'w-[10px] h-[10px] rounded-lg bg-green1 mr-2'}/>
                    <p className={'mr-4'}>{t('superadminPage.active')}</p>
                    {data.datasets[0].data[0]}
                </div>
                <div className={'flex items-center'}>
                    <span className={'w-[10px] h-[10px] rounded-lg bg-red1 mr-2'}/>
                    <p className={'mr-4'}>{t('superadminPage.inactive')}</p>
                    {data.datasets[0].data[1]}
                </div>
            </div>
        </div>
    )
}

export default MBarChart;
