import React, { useState } from 'react';

import MInput from '../../../common/m-input/MInput';
import MCheckbox from '../../../common/m-checkbox/MCheckbox';
import MModal from '../../../common/m-modal/MModal';
import MError from '../../../common/m-error/MError';

import { useRegisterUserMutation } from '../../../../api/AuthApi/authService';

import { authRegex } from '../../../../models/auth';
import { useTranslation } from 'react-i18next';
import EyeClosed from '../../../../assets/img/Eye Closed.png'
import EyeOpened from '../../../../assets/img/Eye opened.png'
import {setIsError} from "../../../../store/reducers/notificationSlice";
import {useDispatch} from "react-redux";
import useFormData from "../../../../hooks/useFormData";

function RegistrationModal({
                               isRegistrationModal,
                               setIsRegistrationModal,
                               setIsClicked,
                               setIsConfirmRegistrationModal
                           }) {
    const [registerUser] = useRegisterUserMutation();
    const dispatch = useDispatch();

    const [isConfirmPassShown, setIsConfirmPassShown] = useState(false);

    const {t} = useTranslation();

    const {formData, errors, onHandleChange, setErrors, setFormData} = useFormData({
        firstname: '',
        lastname: '',
        email: '',
        checkboxState: false
    });

    const resetData = () => {
        setFormData({
            firstname: '',
            lastname: '',
            email: '',
            checkboxState: false
        });
        setErrors({});
    };

    const onHandleCancelButton = () => {
        resetData();
        setIsRegistrationModal(false);
    };
    const onHandleRegistrationButton = async (e) => {
        e.preventDefault();

        const errors = {};
        const { firstname, lastname, email, checkboxState } = formData;

        if (!firstname.trim()) {
            errors.firstname = 'general';
        }

        if (!lastname.trim()) {
            errors.lastname = 'general';
        }

        if (!email.trim()) {
            errors.email = 'general';
        } else if (!authRegex.email.test(email)) {
            errors.email = 'invalidEmail';
        }

        if (!checkboxState) {
            errors.checkboxState = 'checkboxState';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            setIsRegistrationModal(false);
            setIsClicked(false);
            resetData();
            const result = await registerUser({ firstname, lastname, email });
            if (result.error) {
                console.error(result.error);
                dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                setIsConfirmRegistrationModal(email);
            }
        }
    };

    const onCloseModal = () => {
        resetData();
    };

    return (
        <MModal
            active={isRegistrationModal}
            setActive={setIsRegistrationModal}
            onCloseModal={onCloseModal}
        >
            <div className="flex flex-col max-w-[460px]">
                <h5 className="text-xl font-bold mx-auto my-3">{t('general.header.registrModal.title')}</h5>

                <form
                    className="flex flex-col"
                    onSubmit={onHandleRegistrationButton}
                >
                    <MInput
                        placeholder={t('general.header.registrModal.firstname')}
                        setValue={(e) => onHandleChange(30, e)}
                        value={formData.firstname}
                        name="firstname"
                        error={errors.firstname}
                    />

                    {errors.firstname && <MError error={errors.firstname}/>}

                    <MInput
                        placeholder={t('general.header.registrModal.lastname')}
                        setValue={(e) => onHandleChange(30, e)}
                        value={formData.lastname}
                        name="lastname"
                        error={errors.lastname}
                    />

                    {errors.lastname && <MError error={errors.lastname}/>}

                    <MInput
                        placeholder={t('general.header.registrModal.email')}
                        setValue={(e) => onHandleChange(50, e)}
                        value={formData.email}
                        name="email"
                        error={errors.email}
                    />

                    {errors.email && <MError error={errors.email}/>}

                        <MCheckbox
                            label={t('general.header.registrModal.checkbox')}
                            linkText={t('general.header.registrModal.linkText')}
                            afterLinkText={t('general.header.registrModal.afterLinkText').startsWith('general') ? '' : t('general.header.registrModal.afterLinkText')}
                            value={formData.checkboxState}
                            setValue={() => setFormData(({
                                ...formData,
                                checkboxState: !formData.checkboxState
                            }))}
                            name="checkboxState"
                            onLinkClick={() => setIsRegistrationModal(false)}
                        />

                        <button
                            className={`btn-primary my-2`}
                            type='submit'
                            disabled={!formData.checkboxState}
                        >
                            {t('general.header.registrModal.registration')}
                        </button>

                        <button
                            className="btn-secondary"
                            onClick={onHandleCancelButton}
                            type='reset'
                        >
                            {t('general.header.registrModal.cancel')}
                        </button>
                </form>
            </div>
        </MModal>
    );
}

export default RegistrationModal;
