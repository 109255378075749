import { api } from '../api';

const adminApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSchools: build.mutation({
            query: (filters) => ({
                url: `/school/list${filters && filters}`,
                method: 'GET'
            })
        }),
        getSchool: build.mutation({
            query: (id) => ({
                url: `/school/view?school_id=${id}`,
                method: 'GET'
            })
        }),
        addSchool: build.mutation({
            query: (payload) => ({
                url: '/school/create',
                method: 'POST',
                body: { ...payload }
            })
        }),
        editSchool: build.mutation({
            query: ({ id, ...payload }) => ({
                url: `/school/edit?school_id=${id}`,
                method: 'PATCH',
                body: payload
            })
        }),
        deleteSchool: build.mutation({
            query: (id) => ({
                url: `/school/delete?school_id=${id}`,
                method: 'DELETE'
            })
        }),
        getCityList: build.query({
            query: (filters) => ({
                url: `/school/city-list`,
                method: 'GET'
            })
        }),
    })
})

export const {
    useGetSchoolsMutation,
    useGetSchoolMutation,
    useAddSchoolMutation,
    useEditSchoolMutation,
    useDeleteSchoolMutation,
    useGetCityListQuery
} = adminApi;
