import React from 'react';

import MModal from '../../../common/m-modal/MModal';
import { useTranslation } from 'react-i18next';

const ConfirmRegistrationModal = ({
     isConfirmRegistrationModal,
     setIsConfirmRegistrationModal
}) => {

    const {t} = useTranslation();

    return (
        <MModal
            active={!!isConfirmRegistrationModal}
            setActive={setIsConfirmRegistrationModal}
        >
            <div className="flex flex-col w-96">
                <h5 className="text-xl font-semibold mx-auto my-3">
                    {t('general.header.confirmRegistrModal.title')}
                </h5>

                <p className="font-medium text-sm text-center">
                    {t('general.header.confirmRegistrModal.emailSend')}
                    <span className="text-cyan-700"> {isConfirmRegistrationModal} </span>
                    {t('general.header.confirmRegistrModal.toConfirm')}
                </p>
            </div>
        </MModal>
    );
};
export default ConfirmRegistrationModal;