import React, {useEffect, useState} from "react";
import useDeviceType from "../../hooks/useDeviceType";
import {useTranslation} from "react-i18next";
import MTable from "../../components/common/m-table/MTable";
import {useDispatch, useSelector} from "react-redux";
import {useLazyGetSubsQuery} from "../../api/SubsApi/SubsService";
import Spinner from "../../components/common/spinner/spinner";
import InfoModal from "../../components/layout/navbar/modals/InfoModal";
import {setIsError} from "../../store/reducers/notificationSlice";
import {Link} from "react-router-dom";

const SchoolAdminSubs = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const {isMobile} = useDeviceType();
    const [subs, setSubs] = useState({});
    const [total, setTotal] = useState(10)
    const [currentSub, setCurrentSub] = useState(new Map());
    const {t} = useTranslation();
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [getSubs] = useLazyGetSubsQuery();
    const onPageClick = (page) => {
        setCurrentPage(page)
    }
    const school_id = useSelector((state) => state.user.school_id);
    const subColumns = [
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.package'),
            key: 'package_name',
            render: (sub) => (
                <Link
                    to={`/school-admin/subscriptions/add/${sub.subscription_id}`}
                    className={'text-blue3 font-medium text-sm underline cursor-pointer'}
                >
                    {sub.package_name}
                </Link>
            )
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.publisher'),
            key: 'publisher_name',
            showForModes: ['tablet', 'desktop']
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.valid_period'),
            key: 'start_date',
            showForModes: ['tablet', 'desktop'],
            render: (sub) => (
                <span>
                    {`${sub.start_date}-${sub.end_date}`}
                </span>
            )
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.qty'),
            key: 'count'
        },
        {
            label: 'Используют',
            key: 'active_count',
            showForModes: ['tablet'],
            render: (sub) => (
                <span className={'text-green2'}>
                    {sub.active_count}
                </span>
            )
        },
        {
            label: 'Не используют',
            key: 'inactive_count',
            showForModes: ['tablet'],
            render: (sub) => (
                <span className={'text-red4'}>
                    {sub.inactive_count}
                </span>
            )
        }
    ]
    useEffect(() => {
        if (school_id) getData();
    }, [school_id, currentPage]);
    const getData = async () => {
        setIsLoading(true)
            const mySubs = await getSubs(`?school_id=${school_id}&limit=10&offset=${(currentPage - 1) * 10}`)
        if(mySubs.error) {
            //TODO Обработка ошибок
            dispatch(setIsError('mySubsError'))
        } else {
            setSubs(mySubs?.data?.subscriptions)
            setTotal(mySubs?.data?.subscriptions.unique_count)
        }

        setIsLoading(false)
    }

    return (
        <Spinner spinning={isLoading}>
            <div className={'pt-[52px] mb-[108px] px-5'}>
                <h1 className={'text-[28px] font-medium mb-8'}>Подписки</h1>
                <MTable
                    columns={subColumns}
                    data={subs?.subscriptions || []}
                    total={total}
                    trimAmount={25}
                    currentPage={currentPage}
                    setCurrentPage={onPageClick}
                    uniqueKey="subscription_id"
                />
            </div>
            <InfoModal isInfoModal={isOpen} setIsInfoModal={setIsOpen} data={currentSub}/>
        </Spinner>
    )
}
export default SchoolAdminSubs;