import React, { useEffect, useState } from 'react';
import TabItem from "./MTabItem";
import "./MTabs.css";

const TabList = ({ children, activeTabIndex = 0, setTabIndex, tabIndex, onClick = [], isTabVisible = true }) => {
    const [activeTab, setActiveTab] = useState(activeTabIndex);

    const handleTabClick = (index) => {
        onClick[index] && onClick[index]()
        setActiveTab(index)
        if (setTabIndex) {
            setTabIndex(index)
        }
    }

    useEffect(() => {
        if(tabIndex) {
            setActiveTab(tabIndex)
        }
    }, [tabIndex]);

    useEffect(()=>{
        setActiveTab(activeTabIndex)
    },[activeTabIndex])
    const tabs = React.Children.toArray(children).filter((child) => React.isValidElement(child) && child.type === TabItem)
    return (
        <div className="tabs">
            {isTabVisible && <nav className="tab-nav">
                <ul className="tab-list" role="tablist" aria-orientation="horizontal">
                {tabs.map((tab, index) => (
                    <li key={`tab-${index}`}>
                    <button
                        key={`tab-btn-${index}`}
                        role="tab"
                        id={`tab-${tab.props.id}`}
                        aria-controls={`panel-${tab.props.id}`}
                        aria-selected={activeTab === index}
                        onClick={() => handleTabClick(index)}
                        className={`tab-btn ${
                        activeTab === index && "tab-btn--active"
                        }`}
                    >
                        {tab.props.label}
                    </button>
                    </li>
                ))}
                </ul>
            </nav>}
            {tabs[activeTab]}
        </div>
    )
}

export default TabList
