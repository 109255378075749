import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AdvantagesCard({img, title, text, src}) {
    const {t} = useTranslation()
    return (
        <div className="bg-card-bg rounded-lg mx-auto pb-8 h-[450px] tablet:h-[500px] desktop:h-[450px] relative w-[300px] tablet:w-[250px] desktop:w-[321px]">
            <div className="h-[186px] flex items-center">
                <img src={img} alt={title} className="mx-auto"/>
            </div>
            <h3 className="py-2 px-6 text-xl tablet:h-[72px] desktop:h-auto">
                {title}
            </h3>
            <p className="px-6 mb-5 h-[150px] tablet:h-[170px] desktop:h-[150px]">
                {text}
            </p>
            <Link to={src} className="mx-6 btn-secondary-border">
                {t('mainPage.advantages.details')}
            </Link>
        </div>
    );
}

export default AdvantagesCard;
