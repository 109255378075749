import React from 'react';
import {Link} from "react-router-dom";

function MCheckbox({label, value, setValue, name, keyValue, linkText, afterLinkText, onLinkClick }) {
    return (
        <div className="flex flex-row items-center m-2 gap-2" key={keyValue}>
            <input
                type="checkbox"
                name={name}
                className="relative peer appearance-none checked:accent-white h-[20px] w-[20px] border border-[#00000033] bg-white shrink-0"
                checked={value}
                onChange={setValue}
            />
            <div>
                <span>{label}</span>

                {linkText && (
                    <Link to="/eula" className="underline hover:no-underline hover:cursor-pointer" onClick={onLinkClick}>
                        {linkText}
                    </Link>
                )}

                {afterLinkText && afterLinkText}
            </div>


            <svg className="absolute ml-0.5 hidden peer-checked:block pointer-events-none" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 5.5L6 10L14.5 1.5" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
}

export default MCheckbox;
