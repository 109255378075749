import { useState, useEffect } from 'react';

const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState({
        isMobile: false,
        isTablet: false,
        isDesktop: false
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setDeviceType({
                isMobile: width <= 834,
                isTablet: width >= 834 && width <= 1440,
                isDesktop: width > 1440
            });
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return deviceType;
};

export default useDeviceType;