const Kundelik = ({className}) => {
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_4214_16771)">
                    <path
                        d="M12.8379 12.2291C12.7502 10.5683 12.5031 9.07037 10.8166 8.41504C9.85151 8.03935 7.47462 7.97127 7.56813 9.1652C7.63739 10.0613 8.46393 9.75123 9.42669 9.75123C10.3895 9.75123 11.3026 10.4467 10.999 11.9531C10.999 11.9531 8.41429 10.2923 6.61922 12.4139C4.82299 14.5355 5.67377 17.5106 6.36987 18.2778C7.46423 19.4851 9.55368 19.4717 10.8674 18.1805C12.1811 16.8893 12.9256 13.8899 12.8379 12.2291ZM10.6261 15.5689C10.4172 16.6668 9.51443 17.7647 8.4547 17.637C7.39497 17.5094 7.01287 16.3008 7.09944 15.2212C7.18602 14.1415 7.80131 12.9804 8.50665 12.7324C9.34011 12.4394 9.86767 12.6291 10.244 13.0254C10.7473 13.5555 10.835 14.471 10.6261 15.5689Z"
                        fill="#0080C6"/>
                    <path
                        d="M5.39661 11.9166C5.53514 11.4352 5.721 10.916 5.94495 10.5756C6.01652 10.4674 6.03845 10.3312 6.00959 10.2035C5.76486 9.11416 4.9591 7.95183 4.80672 7.73907C4.7894 7.71475 4.77093 7.69287 4.749 7.6722C4.57469 7.50563 3.7493 6.77614 3.39259 7.50563C3.10284 8.09895 3.91322 10.488 4.54237 11.9762C4.71437 12.3835 5.27425 12.3434 5.39661 11.9166Z"
                        fill="#0080C6"/>
                    <path
                        d="M9.29841 6.76412L9.25801 2.39568C9.25455 2.00054 9.09986 1.61756 8.81126 1.36467C8.49957 1.09232 8.05975 0.973174 7.60608 1.73063C6.88112 2.93915 7.32671 5.97748 7.47217 6.83342C7.4941 6.96473 7.60377 7.05956 7.7296 7.05713L9.04329 7.03889C9.18528 7.03646 9.29957 6.91366 9.29841 6.76412Z"
                        fill="#0080C6"/>
                    <path
                        d="M10.5103 7.11184C10.2956 7.05592 10.1639 6.82977 10.2136 6.60242C10.4087 5.71122 10.8831 3.60542 11.1602 2.82122C11.6069 1.55677 12.2661 0.590191 12.9495 0.888067C13.6329 1.18594 13.2404 2.50875 12.9264 3.36712C12.6124 4.22549 12.1264 6.1866 11.9186 7.04619C11.8632 7.27355 11.6439 7.41093 11.428 7.35379L10.5103 7.11184Z"
                        fill="#0080C6"/>
                    <path
                        d="M12.7063 7.97868L13.2673 8.4881C13.4601 8.66318 13.7556 8.62063 13.8965 8.39692C14.491 7.44615 15.8947 5.18716 16.0794 4.77864C16.3149 4.25827 16.5262 3.66252 16.1498 3.29169C15.7735 2.92087 15.1617 3.31601 14.7842 3.86191C14.4794 4.30326 13.13 6.55617 12.6232 7.40481C12.5124 7.59083 12.547 7.83521 12.7051 7.97868H12.7063Z"
                        fill="#0080C6"/>
                    <path
                        d="M14.0938 9.88624L14.1584 11.0899C14.1676 11.265 14.3673 11.3501 14.4886 11.2297C15.1477 10.5756 16.6646 9.06192 16.9763 8.69595C17.3768 8.22543 17.6827 7.55552 17.3768 7.25764C17.0709 6.95977 16.5768 7.18348 16.0585 7.654C15.6487 8.02725 14.649 9.05097 14.2507 9.46314C14.1434 9.57378 14.0868 9.72818 14.0949 9.88624H14.0938Z"
                        fill="#0080C6"/>
                </g>
                <defs>
                    <clipPath id="clip0_4214_16771">
                        <rect width="14.1667" height="18.3333" fill="white" transform="translate(3.33203 0.833252)"/>
                    </clipPath>
                </defs>
            </svg>
        </span>
    )
}
export default Kundelik;