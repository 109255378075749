const Facebook = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="11"
              height="23"
              viewBox="0 0 11 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M6.04783 1.08105C4.44348 1.08105 3.11304 2.49871 3.11304 4.20825V7.46052L1 7.50222V10.7545L3.11304 10.7128V21.2619H6.75217V10.7128H9.68696L10 7.46052H6.75217C6.75217 6.75169 6.75217 5.95947 6.75217 5.20895C6.75217 4.7086 7.10435 4.33333 7.57391 4.33333H10V1.08105H6.04783Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
          </svg>
        </span>
    );
};

export default Facebook;
