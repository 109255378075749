import React from 'react';
import NavButton from '../../../common/nav-button/NavButton';
import {useTranslation} from 'react-i18next';
import MIcon from '../../../common/m-icon/MIcon';
import {useDispatch} from "react-redux";
import {dropFilter} from '../../../../store/reducers/filterSlice';
import {SEARCH_VIEW_FILTER_NAME} from '../../../../views/search/Search';
import {LIBRARY_VIEW_FILTER_NAME} from '../../../../views/library/Library';
import {roles} from "../../../../models/auth";
import { USERS_VIEW_FILTER_NAME } from '../../../../views/users/Users';
import { SCHOOLS_VIEW_FILTER_NAME } from '../../../../views/schools/Schools';
import { SUBSCRIPTIONS_VIEW_FILTER_NAME } from '../../../../views/subscriptions/Subscriptions';

function NavigationPanel({toggle, isAuth, role, isOpen, ...props}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onClick = (clearFilter) => {
        if (toggle) {
            toggle()
        }
        if (clearFilter) {
            dispatch(dropFilter({filterName: clearFilter}))
        }
    }

    return (
        <>
            <NavButton to={isAuth ? roles[role] : '/'} onClick={toggle} className={`mr-2 btn-secondary${(isAuth && isOpen) || !isAuth ? '' : '-white'} flex flex-row`}>
                <MIcon
                    type={'home'}
                    className={`${(isAuth && isOpen) || !isAuth ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                />
                {t('general.header.main')}
            </NavButton>
            {
                isAuth && role === 'superadmin' && (
                    <>
                        <NavButton to="/subscriptions" onClick={() => onClick(SUBSCRIPTIONS_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'subs'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.subs')}
                        </NavButton>
                        <NavButton to="/users" onClick={() => onClick(USERS_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'users'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.users')}
                        </NavButton>
                        <NavButton to="/schools" onClick={() => onClick(SCHOOLS_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'schools'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.schools')}
                        </NavButton>
                    </>
                )
            }
            {
                isAuth && role === 'private_user' && (
                    <>
                        <NavButton to="/library" onClick={() => onClick(LIBRARY_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'library'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.library')}
                        </NavButton>
                        <NavButton to="/textbooks" onClick={toggle}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'mybooks'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.mybooks')}
                        </NavButton>
                        <NavButton to="/search" onClick={() => onClick(SEARCH_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'searchbooks'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.search')}
                        </NavButton>
                    </>
                )

            }
            {
                isAuth && (role === 'teacher' || role === 'student') && (
                    <>
                        <NavButton to="/library" onClick={() => onClick(LIBRARY_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'library'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.library')}
                        </NavButton>
                        <NavButton to={role === 'teacher'? 'magazines' : 'student/works'} onClick={toggle}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'magazines'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {role === 'teacher' ? t('general.header.magazines') : t('general.header.works')}
                        </NavButton>
                        <NavButton to="/textbooks" onClick={toggle}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'mybooks'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.mybooks')}
                        </NavButton>
                        <NavButton to="/search" onClick={() => onClick(SEARCH_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'searchbooks'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.search')}
                        </NavButton>
                    </>
                )
            }
            {
                isAuth && role === 'school_admin' && (
                    <>
                        <NavButton to="/library" onClick={() => onClick(LIBRARY_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'library'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.library')}
                        </NavButton>
                        <NavButton to="/school-admin/subscriptions" onClick={() => onClick(SUBSCRIPTIONS_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '': '-white'} flex flex-row`}>
                            <MIcon
                                type={'subs'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.subs')}
                        </NavButton>
                        <NavButton to={`/school-admin/users`}
                                   onClick={toggle}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}
                        >
                            <MIcon
                                type={'users'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.users')}
                        </NavButton>
                        <NavButton to={'magazines'} onClick={toggle}
                                   className={`mr-2 btn-secondary${isAuth && isOpen ? '' : '-white'} flex flex-row`}>
                            <MIcon
                                type={'magazines'}
                                className={`${isAuth && isOpen ? 'text-main-color' : 'text-white'} cursor-pointer mr-2`}
                            />
                            {t('general.header.magazines')}
                        </NavButton>
                    </>
                )
            }
            {
                !isAuth && (
                    <>
                        <NavButton to="/library" onClick={() => onClick(LIBRARY_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary flex flex-row`}>
                            <MIcon
                                type={'library'}
                                className={`text-main-color cursor-pointer mr-2`}
                            />
                            {t('general.header.library')}
                        </NavButton>
                        <NavButton to="/search" onClick={() => onClick(SEARCH_VIEW_FILTER_NAME)}
                                   className={`mr-2 btn-secondary flex flex-row`}>
                            <MIcon
                                type={'searchbooks'}
                                className={`text-main-color cursor-pointer mr-2`}
                            />
                            {t('general.header.search')}
                        </NavButton>
                    </>
                )
            }
        </>
    )
}

export default NavigationPanel;
