import { api } from '../api';

const userViewApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserView: build.query({
            query: (payload) => ({
                url: '/user/view',
                method: 'GET',
                params: payload
            })
        }),
        getUserViewByAdmin: build.query({
            query: (payload) => ({
                url: '/user/view-by-admin',
                method: 'GET',
                params: payload
            })
        })
    }),
});


export const { useLazyGetUserViewQuery, useLazyGetUserViewByAdminQuery } = userViewApi;