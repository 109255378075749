import React from 'react';
import {useTranslation} from "react-i18next";

function MError({ error, errorMessage, className }) {
    const {t} = useTranslation();
    return (
        <div className={`m-1 ${className}`}>
            <span
                 className={`text-red-500`}
            >
                {errorMessage || t(`general.models.errors.${error}`) || 'Ошибка'}
            </span>
        </div>
    );
}

export default MError;
