import React from 'react';
import { useTranslation } from 'react-i18next';
import MIcon from '../common/m-icon/MIcon';

function SearchInput({ setSearch, value, onChange, ...props }) {

    const { t } = useTranslation();

    const find = () => {
        setSearch();
    }

    return (
        <div className="bg-card-bg p-4 relative flex flex-col tablet:rounded-xl tablet:m-6">
            <h1 className="text-3xl my-6">{t('searchPage.search.title')}</h1>
            <div className="flex flex-row">
                <div className="w-full mr-2 rounded-lg">
                    <input
                        className="p-2 w-full rounded-lg"
                        placeholder={t('searchPage.search.placeholder')}
                        id="search"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </div>
                <button className="btn-primary flex flex-row h-[40px] items-center" onClick={find}>
                    <MIcon
                        type={'search'}
                        className={'text-white cursor-pointer'}
                    />
                    <span className="hidden tablet:block mx-2">{t('searchPage.search.find')}</span>
                </button>
            </div>
            <p className="text-[#8F979B] my-2">
                {t('searchPage.search.text')}
            </p>
        </div>
    );
}

export default SearchInput;
