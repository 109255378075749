import React from 'react';
import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";

function Language(props) {
    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang)
    }

    const activeRole = useSelector(state=>state.user.activeRole);

    const {t} = useTranslation();

    return (
        <div className="desktop:w-1/2 text-footer-text text-sm flex flex-col mb-4 order-4 mobile:order-3 mx-2">
            <p className="font-bold mb-2">
                {t('general.footer.language')}
            </p>

            <p
                className="mb-1 footer-link"
                onClick={()=>handleLanguageChange('kz')}
            >
                Қазақша
            </p>

            <p
                className="mb-1 footer-link"
                onClick={()=>handleLanguageChange('ru')}
            >
                Русский
            </p>

            {activeRole!=='superadmin' && (
                <p
                    className="mb-1 footer-link"
                    onClick={() => handleLanguageChange('en')}
                >
                    English
                </p>
            )}

        </div>
    );
}

export default Language;
