import Logo from './components/Logo';
import Home from './components/Home';
import Library from './components/Library';
import Searchbooks from './components/Searchbooks';
import Magazines from './components/Magazines';
import Messages from './components/Messages';
import UserCircle from './components/UserCircle';
import TikTok from './components/TikTok';
import Facebook from './components/Facebook';
import Instagram from './components/Instagram';
import Youtube from './components/Youtube';
import Close from './components/Close';
import Search from './components/Search';
import YoutubeFilled from "./components/Youtube-filled";
import FacebookFilled from "./components/Facebook-filled";
import TikTokFilled from "./components/TikTok-filled";
import ArrowRight from "./components/ArrowRight";
import PlusCircle from "./components/PlusCircle";
import XMarkCircle from "./components/XMarkCircle";
import Schools from "./components/Schools";
import Users from "./components/Users";
import Subs from "./components/Subs";
import DoubleArrowRight from "./components/DoubleArrowRight";
import MyBooks from "./components/myBooks";
import ArrowDown from "./components/ArrowDown";
import Calendar from "./components/Calendar";
import BigArrowLeft from "./components/BigArrowLeft";
import PlusCircleGreen from "./components/PlusCircleGreen";
import DeleteCircleBlue from "./components/DeleteCircleBlue";
import Kundelik from "./components/Kundelik";

export const icons = {
    logo: Logo,
    home: Home,
    library: Library,
    searchbooks: Searchbooks,
    magazines: Magazines,
    messages: Messages,
    userCircle: UserCircle,
    tiktok: TikTok,
    facebook: Facebook,
    instagram: Instagram,
    youtube: Youtube,
    close: Close,
    search: Search,
    youtubeFilled: YoutubeFilled,
    facebookFilled: FacebookFilled,
    tiktokFilled: TikTokFilled,
    arrowRight: ArrowRight,
    plusCircle: PlusCircle,
    plusCircleGreen: PlusCircleGreen,
    deleteCircleBlue: DeleteCircleBlue,
    xMarkCircle: XMarkCircle,
    schools: Schools,
    users: Users,
    subs: Subs,
    doubleArrowRight: DoubleArrowRight,
    mybooks: MyBooks,
    arrowDown: ArrowDown,
    calendar: Calendar,
    bigArrowLeft: BigArrowLeft,
    kundelik: Kundelik,
}
