import React, { useState } from 'react';
import {useAddSchoolMutation, useGetCityListQuery} from '../../api/SchoolsApi/schoolsList';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import { setIsError } from '../../store/reducers/notificationSlice';
import Spinner from '../../components/common/spinner/spinner';
import MSelect from "../../components/common/m-select/MSelect";
import TableSelectFilter from "../../components/common/m-table/TableSelectFilter";

function SchoolAdd(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [errors, setErrors] = useState({})
    const [ addSchool ] = useAddSchoolMutation();
    const [formData, setFormData] = useState({})
    const {data:cities} = useGetCityListQuery();
    const [isLoading, setIsLoading] = useState(false)

    const cityOptions =cities?.cities.map(city=>({name:city.name, value:city.city_id})) || []

    const requiredFields = {
        bin: t('general.models.errors.general'),
        name: t('general.models.errors.general'),
        city_id: t('general.models.errors.general'),
        address: t('general.models.errors.general'),
        contact: false,
    }

    const checkLimitMin = (limitMin, e) => {
        const { name, value } = e.target
        const isEmpty = requiredFields[name] && (!formData[name] || formData[name]?.length === 0)
        if (isEmpty) {
            setErrors({...errors, [name]: t('general.models.errors.general')})
        } else if (value.length < limitMin) {
            setErrors({...errors, [name]: `${t('errors.limitMin')} - ${limitMin}`})
        }
    }

    const checkBIN = (e) => {
        const { name, value } = e.target
        if (/^\d{12,12}$/.test(value)) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        } else {
            setErrors({...errors, [name]: t('errors.checkBIN')})
        }
    }

    const onHandleChange = (limitMax, e, regexTemplate) => {
        const { name, value } = e.target
        if (errors[name]) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        }

        if (value.length <= limitMax) {
            if ((regexTemplate && regexTemplate.test(value)) || !regexTemplate) {
                setFormData({
                    ...formData, [name]: value
                });
            }
        }
    }

    const checkBeforeAddSchool = () => {
        if (Object.keys(errors).length !== 0) {
            return false;
        }
        const invalidFields = Object.keys(requiredFields)?.filter((field) => requiredFields[field] && (!formData[field] || formData[field]?.length === 0))
        if (invalidFields?.length > 0) {
            // dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`)))
            // dispatch(setIsError('Проверьте обязательные поля'))
            const errosObject = invalidFields.reduce((acc, val) => ({ ...acc, [val]: requiredFields[val]}), {})
            setErrors({ ...errors, ...errosObject })
            return false
        }
        if (Object.keys(errors).length > 0) {
            return false
        }
        return true
    }

    const addSchoolHandler = async () => {
        if (checkBeforeAddSchool()) {
            setIsLoading(true)
            const result = await addSchool(formData)
            setIsLoading(false)
            if (result.error) {
                dispatch(setIsError(t(`general.models.serverErrors.schools.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                navigate('/schools')
            }
        }
    }

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4 mt-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={addSchoolHandler}
        >
            {t('schoolPage.schoolAdd.tabs.school.buttons.addSchool')}
        </button>
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full mb-8">
                    <MProfileGroup title={t('schoolPage.schoolAdd.tabs.school.generalData.title')}>
                        <MProfileInput
                            mode="edit"
                            placeholder={t('schoolPage.schoolAdd.tabs.school.generalData.bin')}
                            setValue={(e) => {onHandleChange(12, e, /^\d{0,12}$/)}}
                            validate={((e) => checkBIN(e))}
                            record={formData}
                            field="bin"
                            required={requiredFields.bin}
                            error={errors.bin}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('schoolPage.schoolAdd.tabs.school.generalData.name')}
                            setValue={(e) => {onHandleChange(100, e)}}
                            validate={((e) => checkLimitMin(3, e))}
                            record={formData}
                            field="name"
                            required={requiredFields.name}
                            error={errors.name}
                        />
                        <TableSelectFilter
                            name="city_id"
                            withLabel={false}
                            classNameSelect={'border-black/[0.3]'}
                            className={'tablet:w-[632px]'}
                            options={cityOptions}
                            setState={(e)=>{
                                setFormData(e)
                                setErrors(prev=>{
                                    delete prev['city_id']
                                    return prev
                                })
                            }}
                            state={formData}
                            allText={'Выберите город'}
                            error={errors.city_id}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('schoolPage.schoolAdd.tabs.school.generalData.address')}
                            setValue={(e) => {onHandleChange(100, e)}}
                            validate={((e) => checkLimitMin(3, e))}
                            record={formData}
                            field="address"
                            required={requiredFields.address}
                            error={errors.address}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('schoolPage.schoolAdd.tabs.school.generalData.contact')}
                            setValue={(e) => {onHandleChange(150, e)}}
                            record={formData}
                            field="contact"
                            required={requiredFields.contact}
                            error={errors.contact}
                        />
                        <div className="mt-2">{t('schoolPage.schoolAdd.tabs.school.requiredText')}</div>
                    </MProfileGroup>
                    {buttons}
                </div>
            </div>
        </Spinner>
    );
}

export default SchoolAdd;
