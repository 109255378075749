import {
    addDays,
    eachDayOfInterval,
    isAfter,
    isBefore,
    lastDayOfMonth,
    lastDayOfWeek,
    startOfMonth,
    startOfWeek,
    subDays
} from "date-fns";

export const isBetweenDates = (data, startDate, endDate, type) => {
    return type === 'endDate' ? isBefore(data, addDays(startDate, 1)) : isAfter(data, subDays(endDate, 1))
}

export const getStructuredDate = (currentDate) => {
    const structuredDate = [];
    const firstDay = subDays(startOfMonth(currentDate), 1);
    const lastDay = addDays(lastDayOfMonth(currentDate), 7);
    const startDate = startOfWeek(firstDay);
    const endDate = lastDayOfWeek(lastDay);
    const totalDate = eachDayOfInterval({start: addDays(startDate, 1), end: addDays(endDate, 1)});
    for (let i = 0; i < 6; i++) {
        const sliceStart = i * 7;
        const sliceEnd = sliceStart + 7;
        structuredDate.push(totalDate.slice(sliceStart, sliceEnd));
    }
    return structuredDate;
};