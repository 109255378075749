import {useGetUsersListMutation} from "../../api/UserApi/userList";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEqual} from 'lodash'
import {useTranslation} from "react-i18next";
import TableInputFilter from "../../components/common/m-table/TableInputFilter";
import TableSelectFilter from "../../components/common/m-table/TableSelectFilter";
import {
    subscriptionPresenceOptions,
    userRoleOptions,
    userRoleOptionsForSchoolAdmin,
} from "../../models/users";
import {dropFilter, saveFilter} from "../../store/reducers/filterSlice";
import {USERS_VIEW_FILTER_NAME} from "../users/Users";
import MTable from "../../components/common/m-table/MTable";
import {useParams} from "react-router-dom";
import MTag from "../../components/common/m-tag/MTag";
import {useDeleteSubUserMutation, useLazyGetSubsQuery, useSetSubUserMutation} from "../../api/SubsApi/SubsService";
import {setIsError} from "../../store/reducers/notificationSlice";
import {filterAndModifyUsers} from "../../utils/school-admin";
import MIcon from "../../components/common/m-icon/MIcon";
import MCheckbox from "../../components/common/m-checkbox/MCheckbox";
import InfoModal from "../../components/layout/navbar/modals/InfoModal";

export const SCHOOL_ADMIN_SUBSCRIPTIONS_ADD_NAME = 'SchoolAdminSubscriptionsAdd'
const USERS_PAGE_SIZE = 10
const DEFAULT_FILTER = { limit: USERS_PAGE_SIZE }
const SchoolAdminSubsAdd = () => {
    const { id } = useParams()
    const [getUsers] = useGetUsersListMutation();
    const [getSubs] = useLazyGetSubsQuery();
    const [addSubToUser] = useSetSubUserMutation();
    const [deleteUserSub] = useDeleteSubUserMutation()
    const dispatch = useDispatch();
    const [isInfoModal, setIsInfoModal] = useState(false);
    const [data, setData] = useState(new Map());
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [sub, setSub] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState([])
    const [filters, setFilters] = useState({});
    const school_bin = useSelector(state=>state.user.school_bin)
    const savedFilters = useSelector(state => state.filter.savedFilters[USERS_VIEW_FILTER_NAME]) || DEFAULT_FILTER
    const {t} = useTranslation();
    const [counters, setCounters] = useState({active: 0, inactive: 0})
    const onChangeChecked = (recordId) => {
        if (checked.includes(recordId)) {
            setChecked(checked.filter((item) => item !== recordId))
        } else {
            setChecked([ ...checked, recordId])
        }
    }

    const reformatParams = (data)  => {
        let filterStr = '';
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (value) {
                        filterStr = filterStr + `&${key}=${value}`
                }
            }
        }
        return filterStr;
    }

    const getData = async () => {
        const params = reformatParams({...savedFilters})
        setIsLoading(true)
            const s = await getSubs(`?school_bin=${school_bin}&subscription_id=${id}`)
            if(s.error){
                dispatch(setIsError('SERROR'))
            } else {
                const subscription = s.data.subscriptions.subscriptions[0]
                setSub(subscription)
                setCounters({active:subscription.active_count, inactive:subscription.inactive_count })
                const u = await getUsers(`?school_bin=${school_bin}${params}`);
                if(u.error){

                } else {
                    setTotal(u.data.users.count_of_users)
                    const users = filterAndModifyUsers(u.data.users.users)
                    setUsers(users.map(item=>{
                        return ({
                            fullname: item.lastname+' '+item.firstname,
                            username: item.username,
                            roles: item.roles,
                            is_subscribed: item.subscriptions.some(sub=>sub.subscription_id===s.data.subscriptions.subscriptions[0].subscription_id),
                            user_id: item.user_id
                        })
                    }))
                    if(filters.is_subscribed==='yes'){
                        setUsers(prev=>prev.filter(user=>user.is_subscribed))
                    } else if(filters.is_subscribed==='no'){
                        setUsers(prev=>prev.filter(user=>!user.is_subscribed))
                    }
            }
        }
            setIsLoading(false)
    }
    const onApplyFilters = async (sub) => {
        setCurrentPage(1)
        dispatch(saveFilter({
            filterName: USERS_VIEW_FILTER_NAME,
            filterValue: { ...filters, offset: 0 }
        }))
    }

    const onResetFilters = () => {
        setFilters(DEFAULT_FILTER)
        dispatch(dropFilter({ filterName: USERS_VIEW_FILTER_NAME }))
    }

    const isDefaultFilter = isEqual(filters, DEFAULT_FILTER)
    const isFiltersApplied = isEqual(filters, savedFilters)

    const onChangeFilter = (newState) => {
        setFilters(newState)
    }

    const onPageClick = (page) => {
        setCurrentPage(page)
        dispatch(saveFilter({
            filterName: USERS_VIEW_FILTER_NAME,
            filterValue: { ...savedFilters, offset: (page - 1) * USERS_PAGE_SIZE }
        }))
    }
    const addSub = async (id) => {
        setIsLoading(true)
        const result = await addSubToUser({
            subscription_id: sub.subscription_id,
            users: [
                {
                    user_id:id,
                    is_private:false
                }
            ]
        })
        if(result.error){
            dispatch(setIsError('addError'))
        } else {
            setCounters(prev=>({active:++prev.active, inactive: --prev.inactive }))
            setUsers(prev=>prev.map(user=>{
                if( user.user_id===id) {
                    return ({...user, is_subscribed: true})
                }
                return user
            }))
        }
        setIsLoading(false)
    }
    const addMultipleSubs = async () => {
        setIsLoading(true)
        const result = await addSubToUser({
            subscription_id: sub.subscription_id,
            users: checked.filter(item=>!users.find(user=>user.user_id===item).is_subscribed).map(item=>({
                user_id: item,
                is_private: false
            }))
        })
        if(result.error){
            dispatch(setIsError('addError'))
        } else {
            setUsers(prev=>prev.map(user=>{
                if(checked.some(item=>item===user.user_id)) {
                    if(!user.is_subscribed) {
                        setCounters(prev=>({active:++prev.active, inactive: --prev.inactive }))
                    }
                    return ({...user, is_subscribed: true})
                }
                return user
            }))
            setChecked([])
        }
        setIsLoading(false)
    }

    const deleteMultipleSubs = async () => {
        setIsLoading(true)
        const result = await deleteUserSub({
            subscription_id: sub.subscription_id,
            users: checked.filter(item=>users.find(user=>user.user_id===item).is_subscribed)
        })
        if(result.error){
            dispatch(setIsError('addError'))
        } else {
            setUsers(prev=>prev.map(user=>{
                if(checked.some(item=>item===user.user_id)) {
                    if(user.is_subscribed) {
                        setCounters(prev=>({active:--prev.active, inactive: ++prev.inactive }))
                    }
                    return ({...user, is_subscribed: false})
                }
                return user
            }))
            setChecked([])
        }
        setIsLoading(false)
    }

    const deleteSub = async (id) => {
        setIsLoading(true)
        const result = await deleteUserSub({
            subscription_id: sub.subscription_id,
            users: [id]
        })
        if(result.error){
            dispatch(setIsError('delError'))
        } else {
            setCounters(prev=>({active:--prev.active, inactive: ++prev.inactive }))
            setUsers(prev=>prev.map(user=>{
                if( user.user_id===id) {
                    return ({...user, is_subscribed: false})
                }
                return user
            }))
        }
        setIsLoading(false)
    }
    const columns = [
        {
            label: '',
            key: 'checkFlg',
            render: (record) => {
                const current = checked.includes(record.user_id)
                return <MCheckbox
                    value={current}
                    setValue={() => onChangeChecked(record.user_id)}
                />
            }
        },
        {
            label: t('usersPage.list.columns.fullname'),
            key: 'fullname',
            render: (record) => <span
                className="text-blue3 font-medium text-sm underline cursor-pointer"
                onClick={() => {
                    const currentUser = users.find(item => item.user_id === record.user_id)
                    setIsInfoModal(true)
                    setData(new Map()
                        .set("Логин", `${currentUser.username}`)
                        .set("Роль пользователя", <div className={'flex flex-col gap-3'}>
                            {currentUser.roles.map(role=>{
                                const style = userRoleOptions.find(item=>item.value===role).extraClassName
                                return (
                                    <MTag
                                        key={role}
                                        title={t(`usersPage.search.filters.role.${role}`)}
                                        extraClassName={style}
                                    />
                                )
                             }
                            )}

                        </div> )
                        .set("Наличие подписки", currentUser.is_subscribed ? 'Да' : 'Нет')
                        .set('title', currentUser.fullname)
                    )
                }
                }
            >
                {record.fullname}
            </span>
        },
        {
            label: t('usersPage.list.columns.username'),
            key: 'username',
            showForModes: ['desktop']
        },
        {
            label: t('usersPage.list.columns.roles'),
            key: 'roles',
            showForModes: ['tablet', 'desktop'],
            render: (record) => <div className="flex flex-col gap-2.5">
                {userRoleOptions.filter((option) => record.roles?.includes(option.value)).map((option) => (
                    <MTag
                        key={option.value}
                        title={t(`usersPage.search.filters.role.${option.value}`)}
                        extraClassName={option.extraClassName}
                    />)
                )}
            </div>
        },
        {
            label: 'Наличие',
            key: 'is_subscribed',
            render: (record) => <span>{record.is_subscribed ? 'Да' : 'Нет'}</span>

        },
        {
            label: 'Действия',
            key: 'is_subscribed',
            showForModes: ['tablet', 'desktop'],
            render: (record) => {
                if(record.is_subscribed) {
                    return <button
                        className={'btn-secondary-border py-1 flex items-center gap-3'}
                        onClick={()=>deleteSub(record.user_id)}
                    >
                        <MIcon
                            type="deleteCircleBlue"
                            className={'text-white'}
                        />
                        Удалить подписку
                    </button>
                } else {
                    return <button
                        className={'btn-secondary-border border-green3 text-green3 hover:border-green3 hover:text-green3 py-1 flex items-center gap-3'}
                        onClick={()=>addSub(record.user_id)}
                    >
                        <MIcon
                            type="plusCircleGreen"
                            className={'text-white'}
                        />
                        Добавить подписку
                    </button>
                }
            }
        }
    ]
    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-start gap-4">
        <button
            className="btn-secondary-border whitespace-nowrap"
            onClick={addMultipleSubs}
        >
            Добавить подписки
        </button>
        <button
            className="btn-secondary-border whitespace-nowrap"
            onClick={deleteMultipleSubs}
        >
            Удалить подписки
        </button>
    </div>

    useEffect(() => {
        getData()
        setFilters(savedFilters || DEFAULT_FILTER)
        return (()=>{
            setFilters(DEFAULT_FILTER)
            dispatch(dropFilter({ filterName: USERS_VIEW_FILTER_NAME }))
        })
    }, [savedFilters, school_bin]);
    return (
        <div className={'pt-[52px] mb-[108px] px-5'}>
            <h1 className={'text-[28px] font-medium mb-8'}>Подписки</h1>
            <div className={'flex w-full justify-between mb-8'}>
                <p className={'text-xl font-semibold'}> {sub?.package_name}, {sub?.start_date}-{sub?.end_date}</p>
                <div className={'flex gap-4'}>
                    <p>Активные:&nbsp;
                        <span className={'text-green2'}>
                            {counters?.active}
                        </span>
                    </p>
                    <p>Неактивные:&nbsp;
                        <span className={'text-red4'}>
                            {counters?.inactive}
                        </span>
                    </p>
                </div>
            </div>
            <div className="bg-card-bg rounded-xl m-2 p-4 pb-6 flex flex-none flex-col desktop:flex-row tablet:flex-row tablet:flex-wrap w-full">
                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5 mb-3">
                    <TableInputFilter
                        name="fullname"
                        placeholder={t('usersPage.search.filters.fullname.placeholder')}
                        tPath={'usersPage.search.filters'}
                        setState={onChangeFilter}
                        state={filters}
                        value={filters.fullname}
                        max={90}
                    />
                </div>
                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                    <TableSelectFilter
                        name="role"
                        options={userRoleOptionsForSchoolAdmin}
                        tPath={'usersPage.search.filters'}
                        setState={onChangeFilter}
                        state={filters}
                        allText={t('usersPage.search.filters.role.all')}
                    />
                </div>
                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                    <TableSelectFilter
                        name="is_subscribed"
                        options={subscriptionPresenceOptions}
                        tPath={'usersPage.search.filters'}
                        setState={onChangeFilter}
                        state={filters}
                        allText={t('usersPage.search.filters.is_subscribed.all')}
                    />
                </div>
                <div className="w-full ml-2.5 mt-6 flex flex-col items-center gap-4">
                    <div className="w-full flex flex-row items-center gap-4">
                        <button
                            className="flex flex-row h-10 px-6  items-center bg-blue2 border border-blue4 rounded-lg text-sm text-white font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                            onClick={onApplyFilters}
                            disabled={isFiltersApplied}
                        >
                            {t('usersPage.search.buttons.apply')}
                        </button>
                        <button
                            className="flex flex-row h-10 px-6  items-center border border-blue2 rounded-lg text-sm text-main-color font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                            onClick={onResetFilters}
                            disabled={isDefaultFilter}
                        >
                            {t('usersPage.search.buttons.reset')}
                        </button>
                    </div>
                    <div
                        className="text-orange1 text-sm mr-auto">{!isFiltersApplied && 'Нажмите кнопку для применения фильтра'}</div>
                </div>
            </div>
            <div className="w-full mx-2 pt-6">
                <MTable
                    columns={columns}
                    data={users}
                    total={total}
                    currentPage={currentPage}
                    setCurrentPage={onPageClick}
                    foundTitle={t('usersPage.search.found')}
                    buttons={buttons}
                    uniqueKey={'username'}
                    loading={isLoading}
                />
            </div>
            <InfoModal data={data} setIsInfoModal={setIsInfoModal} isInfoModal={isInfoModal} buttons={<>
                {users.find(user=>user.username===data.get('Логин'))?.is_subscribed ? (
                <button
                    className={'btn-secondary-border py-1 flex items-center max-w-fit gap-3'}
                    onClick={() => deleteSub(users.find(user=>user.username===data.get('Логин')).user_id)}
                >
                    <MIcon
                        type="deleteCircleBlue"
                        className="text-white"
                    />
                    Удалить подписку
                </button>
                ) : (
                <button
                    className={'btn-secondary-border border-green3 text-green3 max-w-fit hover:border-green3 hover:text-green3 py-1 flex items-center gap-3'}
                    onClick={() => addSub(users.find(user=>user.username===data.get('Логин')).user_id)}
                >
                    <MIcon
                        type="plusCircleGreen"
                        className={'text-white'}
                    />
                    Добавить подписку
                </button>
                )}
            </>}/>

        </div>
    )
}
export default SchoolAdminSubsAdd;