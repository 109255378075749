import React, { useEffect, useRef, useState } from 'react';
import LoginModal from '../modals/loginModal';
import RegistrationModal from '../modals/registrationModal';
import LoginTypeModal from '../modals/loginTypeModal';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordModal from '../modals/ForgotPasswordModal';
import ConfirmRegistrationModal from '../modals/ConfirmRegistationModal';
import { clearUser } from '../../../../store/reducers/userSlice';
import ConfirmResetPasswordModal from '../modals/ConfirmResetPasswordModal';

import { logOut, setIsLoginTypeModal } from '../../../../store/reducers/authSlice';
import { useTranslation } from 'react-i18next';
import ChooseRoleModal from '../modals/ChooseRoleModal';
import {Link, useNavigate} from 'react-router-dom';
import MIcon from '../../../common/m-icon/MIcon';
import {useLogoutMutation} from "../../../../api/AuthApi/authService";
import {setIsError} from "../../../../store/reducers/notificationSlice";

function AuthPanel({role, isOpen, setIsOpen}) {
    const dispatch = useDispatch();

    const [isLoginModal, setIsLoginModal] = useState(false);
    const [isRegistrationModal, setIsRegistrationModal] = useState(false);
    const [isForgotModal, setIsForgotModal] = useState(false);
    const [isConfirmRegistrationModal, setIsConfirmRegistrationModal] = useState('');
    const [isChooseRoleModal, setIsChooseRoleModal] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isConfirmPasswordResetModal, setIsConfirmPasswordResetModal] = useState('')

    const menuRef = useRef(null);
    const user = useSelector(state=>state.user)
    const isAuth = useSelector(state=>state.auth.isAuth);
    const isTypeModal = useSelector(state => state.auth.isLoginTypeModal)
    const token = useSelector(state=>state.auth.token);
    const [logout] = useLogoutMutation();

    const navigate = useNavigate();

    const handleClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setIsClicked(false);
        }
    }
    const setIsTypeModal = (value) => dispatch(setIsLoginModal(value))

    const {t} = useTranslation();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)
    }, []);
    return (
        <>
            { isAuth
                ? (
                    <div className={'flex relative'}>
                        <div className="flex flex-row items-center" >
                            <div className="flex items-center mr-2">
                                <MIcon
                                    type={'userCircle'}
                                    className={`${!isOpen ? 'text-white' : 'text-main-color'} cursor-pointer`}
                                />
                            </div>
                            <div className="flex flex-col">
                                <div className={`${!isOpen ? 'text-white' : 'text-main-color'}`}>{user.lastname} {user.firstname}</div>
                                <div className={`text-xs ${!isOpen ? 'text-white' : 'text-main-color'}`}>{t(`general.models.auth.roleType.${role}`)}</div>
                            </div>
                            <div className="flex items-center ml-2 hover:cursor-pointer" onMouseDown={(e) => { e.stopPropagation(); setIsClicked(prev=>!prev)}}>
                                {isClicked ? <MIcon type={'arrowDown'} className={`${isOpen ? 'text-blue3' : 'text-white'} rotate-180 `}/>  : <MIcon type={'arrowDown'} className={`${isOpen ? 'text-blue3' : 'text-white'}`}/> }
                            </div>
                        </div>
                        <div className={`${isClicked ? '' : 'hidden'} bg-${user.activeRole} p-3 right-0 rounded-lg absolute text-white ${isOpen ? 'top-[58px]' : 'top-[52px]' } z-40`} ref={menuRef}>
                            <p
                                className="my-1 hover:cursor-pointer"
                            >
                                <Link
                                    onClick={()=>{
                                        setIsOpen(false)
                                        setIsClicked(false)
                                    }}
                                    className={'whitespace-nowrap'}
                                    to="/settings">
                                    {t('general.header.settings')}
                                </Link>
                            </p>

                            <p
                                className="my-1 hover:cursor-pointer"
                            >
                                <Link
                                    onClick={()=>{
                                        setIsOpen(false)
                                        setIsClicked(false)
                                    }}
                                    className={'whitespace-nowrap'}
                                    to="/change-password">
                                    {t('general.header.changePassword')}
                                </Link>
                            </p>

                            {user.roles.length>1 && (
                                <p
                                    className="my-1 hover:cursor-pointer"
                                    onClick={()=>{
                                        setIsChooseRoleModal(prev=>!prev)
                                        setIsOpen(false)
                                        setIsClicked(false)
                                    }}
                                >
                                    {t('general.header.role')}
                                </p>
                            )}

                            <p
                                className="my-1 hover:cursor-pointer" onClick={async () => {
                                    setIsClicked(false)
                                    await logout({access_token: token})
                                    dispatch(logOut())
                                    dispatch(clearUser())
                                    navigate('/')

                            }}>
                                {t('general.header.exit')}
                            </p>
                        </div>
                        <ChooseRoleModal
                            isChooseRoleModal={isChooseRoleModal}
                            setIsChooseRoleModal={setIsChooseRoleModal}
                        />

                    </div>

                )
                : (
                    <>
                        <button
                            className="btn-secondary"
                            onClick={() => {setIsRegistrationModal(true)}}
                        >
                            {t('general.header.register')}
                        </button>

                        <button
                            className="btn-primary mr-2 flex gap-2 items-center h-[48px]"
                            onClick={() => dispatch(setIsLoginTypeModal(true))}
                        >
                            <MIcon type='userCircle'/>
                            {t('general.header.logIn')}
                        </button>

                        <LoginModal
                            isLoginModal={isLoginModal}
                            setIsLoginModal={setIsLoginModal}
                            isAuth={isAuth}
                            isClicked={isClicked}
                            setIsClicked={setIsClicked}
                            setIsForgotModal={setIsForgotModal}
                            setIsChooseRoleModal={setIsChooseRoleModal}
                        />

                        <RegistrationModal
                            isRegistrationModal={isRegistrationModal}
                            setIsRegistrationModal={setIsRegistrationModal}
                            setIsClicked={setIsClicked}
                            setIsConfirmRegistrationModal={setIsConfirmRegistrationModal}
                        />

                        <LoginTypeModal
                            isTypeModal={isTypeModal}
                            setIsLoginModal={setIsLoginModal}
                        />

                        <ForgotPasswordModal
                            isForgotModal={isForgotModal}
                            setIsForgotModal={setIsForgotModal}
                            setIsConfirmPasswordResetModal={setIsConfirmPasswordResetModal}
                        />

                        <ConfirmRegistrationModal
                            isConfirmRegistrationModal={isConfirmRegistrationModal}
                            setIsConfirmRegistrationModal={setIsConfirmRegistrationModal}
                        />

                        <ConfirmResetPasswordModal
                            isConfirmResetPasswordModal={isConfirmPasswordResetModal}
                            setIsConfirmResetPasswordModal={setIsConfirmPasswordResetModal}
                        />
                    </>

                )
            }
            <ChooseRoleModal
                isChooseRoleModal={isChooseRoleModal}
                setIsChooseRoleModal={setIsChooseRoleModal}
                isAuth={isAuth}
            />

        </>
    );
}

export default AuthPanel;
