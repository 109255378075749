export const authRegex = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!_%*?&]{8,}$/,
};

export const roles = {
    private_user: '/private-user',
    superadmin: '/super-admin',
    school_admin: '/school-admin',
    teacher: '/teacher',
    student: '/student'
}
