const XMarkCircle = ({className}) => {
    return (
        <span className={className}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="white"/>
                <path d="M16.8633 7.03516L7.03485 16.8636" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M16.8633 16.8633L7.03485 7.03485" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </span>
    );
};

export default XMarkCircle;
