export const initialFilters = {
    input_text: '',
    grade: '',
    subject: '',
    publisher: '',
    publication_language: '',
    package_name: '',
    limit: 24,
    offset: 0,
}

export const initialSearchFilters = {
    publication_language: '',
    input_text: '',
    grade: [],
    subject: [],
    limit: 24,
    offset: 0,
}

export const gradeOptions = [
    {
        name: {
            'RU': '1-й класс',
            'KZ': '1-сынып',
        },
        value: 1,
    },
    {
        name: {
            'RU': '2-й класс',
            'KZ': '2-сынып',
        },
        value: 2,
    },
    {
        name: {
            'RU': '3-й класс',
            'KZ': '3-сынып',
        },
        value: 3,
    },
    {
        name: {
            'RU': '4-й класс',
            'KZ': '4-сынып',
        },
        value: 4,
    },
    {
        name: {
            'RU': '5-й класс',
            'KZ': '5-сынып',
        },
        value: 5,
    },
    {
        name: {
            'RU': '6-й класс',
            'KZ': '6-сынып',
        },
        value: 6,
    },
    {
        name: {
            'RU': '7-й класс',
            'KZ': '7-сынып',
        },
        value: 7,
    },
    {
        name: {
            'RU': '8-й класс',
            'KZ': '8-сынып',
        },
        value: 8,
    },
    {
        name: {
            'RU': '9-й класс',
            'KZ': '9-сынып',
        },
        value: 9,
    },
    {
        name: {
            'RU': '10-й класс',
            'KZ': '10-сынып',
        },
        value: 10,
    },
    {
        name: {
            'RU': '11-й класс',
            'KZ': '11-сынып',
        },
        value: 11,
    },
]

export const languageOptions = [
    {
        name: {
            'RU': 'Қазақша',
            'KZ': 'Қазақша',
            'EN': 'Қазақша',
            'UG': 'Қазақша'
        },
        value: 'KZ'
    },
    {
        name: {
            'RU': 'Русский',
            'KZ': 'Русский',
            'EN': 'Русский',
            'UG': 'Русский'
        },
        value: 'RU'
    },
    {
        name: {
            'RU': 'Уйғур',
            'KZ': 'Уйғур',
            'EN': 'Уйғур',
            'UG': 'Уйғур'
        },
        value: 'UG'
    },
    {
        name: {
            'RU': 'English',
            'KZ': 'English',
            'EN': 'English',
            'UG': 'English'
        },
        value: 'EN'
    },
]

export const packageOptions = [

]


export const subjectsOptions = [
    {
        name: {
            'RU': 'Английский язык',
            'KZ': 'Ағылшын тілі',
            'EN': 'English',
            'UG': 'Инглиз тили'
        },
        value: 'English',
    },
    {
        name: {
            'RU': 'Начальная военная и технологическая подготовка',
            'KZ': 'Алғашқы әскери және технологиялық дайындық',
            'EN': 'Basic military and technological training',
            'UG': 'Башланғуч һәрбий вә технологиялик тәйярлиқ'
        },
        value: 'Basic military and technological training',
        dontShow: true
    },
    {
        name: {
            'RU': 'Родной язык',
            'KZ': 'Ана тілі',
            'EN': 'Native language',
            'UG': 'Ана тили'
        },
        value: 'Native language',
    },
    {
        name: {
            'RU': 'Литературное чтение',
            'KZ': 'Әдебиеттік оқу',
            'EN': 'Literature reading',
            'UG': 'Әдәбий оқуш'
        },
        value: 'Literature reading',
    },
    {
        name: {
            'RU': 'Изобразительное искусство',
            'KZ': 'Бейнелеу өнері',
            'EN': 'Art',
            'UG': 'Тәсвирий сәнъәт'
        },
        value: 'Art',
    },
    {
        name: {
            'RU': 'Биология',
            'KZ': 'Биология',
            'EN': 'Biology',
            'UG': 'Биология'
        },
        value: 'Biology',
    },
    {
        name: {
            'RU': 'География',
            'KZ': 'География',
            'EN': 'Geography',
            'UG': 'География'
        },
        value: 'Geography',
    },
    {
        name: {
            'RU': 'Графика и проектирование',
            'KZ': 'Графика және жобалау',
            'EN': 'Graphics and design',
            'UG': 'Графика вә лайиһәләш'
        },
        value: 'Graphics and design',
        dontShow: true
    },
    {
        name: {
            'RU': 'Физическая культура',
            'KZ': 'Дене шынықтыру',
            'EN': 'Physical education',
            'UG': 'Тән тәрбийә'
        },
        value: 'Physical education',
    },
    {
        name: {
            'RU': 'Познание мира',
            'KZ': 'Дүниетану',
            'EN': 'Getting to know the world',
            'UG': 'Дуния тонуш'
        },
        value: 'Getting to know the world',
    },
    {
        name: {
            'RU': 'Трудовое обучение',
            'KZ': 'Еңбекке баулу',
            'EN': 'Manual training',
            'UG': 'Әмгәккә үгитиш'
        },
        value: 'Manual training',
    },
    {
        name: {
            'RU': 'Информатика',
            'KZ': 'Информатика',
            'EN': 'Informatics',
            'UG': 'Информатика'
        },
        value: 'Informatics',
    },
    {
        name: {
            'RU': 'Казахская литература',
            'KZ': 'Қазақ әдебиеті',
            'EN': 'Kazakh literature',
            'UG': 'Қазақ әдәбияти'
        },
        value: 'Kazakh literature',
    },
    {
        name: {
            'RU': 'Казахский язык',
            'KZ': 'Қазақ тілі',
            'EN': 'Kazakh',
            'UG': 'Қазақ тили'
        },
        value: 'Kazakh',
    },
    {
        name: {
            'RU': 'Основы права',
            'KZ': 'Құқық негіздері',
            'EN': 'Fundamentals of Law',
            'UG': 'Һоқуқ асаслири'
        },
        value: 'Fundamentals of Law',
    },
    {
        name: {
            'RU': 'Математика',
            'KZ': 'Математика',
            'EN': 'Mathematics',
            'UG': 'Математика'
        },
        value: 'Mathematics',
    },
    {
        name: {
            'RU': 'Музыка',
            'KZ': 'Музыка',
            'EN': 'Music',
            'UG': 'Музыка'
        },
        value: 'Music',
    },
    {
        name: {
            'RU': 'Русский язык и Русская литература',
            'KZ': 'Орыс тілі мен әдебиеті',
            'EN': 'Russian language and literature',
            'UG': 'Рус тили вә Рус әдәбияти'
        },
        value: 'Russian language and literature',
    },
    {
        name: {
            'RU': 'История',
            'KZ': 'Тарихы',
            'EN': 'History',
            'UG': 'Тарих'
        },
        value: 'History',
    },
    {
        name: {
            'RU': 'Физика',
            'KZ': 'Физика',
            'EN': 'Physics',
            'UG': 'Физика'
        },
        value: 'Physics',
    },
    {
        name: {
            'RU': 'Химия',
            'KZ': 'Химия',
            'EN': 'Chemistry',
            'UG': 'Химия'
        },
        value: 'Chemistry',
    },
    {
        name: {
            'RU': 'Иностранный язык',
            'KZ': 'Шет тілі',
            'EN': 'Foreign language',
            'UG': 'Чәтәл тили'
        },
        value: 'Foreign language',
    },
    {
        name: {
            'RU': 'Уйгурский язык',
            'KZ': 'Ұйғыр',
            'EN': 'Uigur',
            'UG': 'Ұйғыр тілі'
        },
        value: 'Uigur',
        dontShow: true
    },
]
