const ArrowRight = ({className}) => {
    return (
        <span className={className}>
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
            <path d="M1 1.5L5 5.5L1 9.5" stroke={'currentColor'} strokeWidth="1.5"/>
          </svg>
        </span>
    );
};

export default ArrowRight;
