import React from 'react';
import { useTranslation } from 'react-i18next';

function Info(props) {

    const {t} = useTranslation();
    return (
        <div className="tablet:w-1/2 text-footer-text text-sm mb-4 mx-2 tablet:order-1">
            <p className="mb-2">{t('general.footer.copyright')}</p>
            <p className="mb-1">{t('general.footer.serviceProvidedBy')}</p>
            <p className="mb-1">{t('general.footer.address')}</p>
            <p className="mb-1">{t('general.footer.contact')} {t('general.footer.workingHours')}</p>
            <p>E-mail: dgmektep@inbox.ru</p>
        </div>
    );
}

export default Info;
