const MProfileList = ({ list }) => {
    return (
        <ol style={{ listStyleType: 'disc' }}>
            {list?.map((item) => (
                <li className="font-medium text-sm text-black ml-6">
                    {item}
                </li>
            ))}
        </ol>
    );
};

export default MProfileList;
