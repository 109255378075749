import { createSlice } from '@reduxjs/toolkit';

const filter = createSlice({
    name: 'filter',
    initialState: {
        savedFilters: {},
    },
    reducers: {
        saveFilter(state, action) {
            state.savedFilters[action.payload.filterName] = action.payload.filterValue
        },
        dropFilter(state, action) {
            state.savedFilters[action.payload.filterName] = null
        },
    }
})

export default filter.reducer
export const { saveFilter, dropFilter } = filter.actions;
