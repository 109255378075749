import React, { useEffect, useState } from 'react';
import {
    initialFilters,
    gradeOptions,
    languageOptions,
    subjectsOptions,
} from '../../models/library';
import BookItem from '../../components/library/BookItem';
import {
    useGetCollectionListQuery,
    useGetListMutation,
    useGetPublisherListQuery
} from '../../api/LibraryApi/LibraryService';
import FilterSelect from '../../components/common/FilterSelect';
import { useTranslation } from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import MIcon from '../../components/common/m-icon/MIcon';
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import {saveFilter} from "../../store/reducers/filterSlice";

export const LIBRARY_VIEW_FILTER_NAME = 'LibraryView'

function Library(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [books, setBooks] = useState([]);
    const [total, setTotal] = useState(0);
    const savedFilters = useSelector(state => state.filter.savedFilters[LIBRARY_VIEW_FILTER_NAME])
    //const [filters, setFilters] = useState(savedFilters || initialFilters);
    const filters = savedFilters || initialFilters
    const [firstTime, setFirstTime] = useState(!savedFilters)
    const dispatch = useDispatch();
    const isAuth = useSelector(state=> state.auth.isAuth)
    const role = useSelector(state=>state.user.activeRole)
    const [isLoading, setIsLoading] = useState(false);
    const { data, isLoading:isPublishersLoading } = useGetPublisherListQuery();
    const { data: packages, isLoading: isPackagesLoading } = useGetCollectionListQuery()
    const packageOptions = packages?.collections?.map(item=>({name:item.name, value: item.collection_id, id: item.collection_id}))
    const publisherOptions = data?.publishers?.map(item=>({name:item.name, value: item.publisher_id, id: item.publisher_id}))



    const {t} = useTranslation();

    const [ getList ] = useGetListMutation()

    const setFilters = (filterValue) => {
        dispatch(saveFilter({ filterName: LIBRARY_VIEW_FILTER_NAME, filterValue }))
    }

    const reformatParams = (data)  => {
        let filterStr = '';
        for (const [key, value] of Object.entries(data)) {
            if (value) {
                if (filterStr === '') {
                    filterStr = `?${key}=${value}`
                } else {
                    filterStr = filterStr + `&${key}=${value}`
                }
            }
        }
        return filterStr;
    }
    const onSearch = async (sub) => {
        const params = reformatParams({...filters, offset: 0, subject: sub ? sub : filters.subject})
        const { data, error } = await getList(params);
        if (data && data.books) {
            setBooks(data.books)
            setTotal(data.count)
        } else if(error){
            dispatch(setIsError(t(`general.models.serverErrors.library.${error.status}`, `${error.data?.error_code}: ${error.data?.message}`)))
        }
        setFilters({ ...filters, offset: 0})
        setIsLoading(false)
    }

    const showMore = async () => {
        const params = reformatParams({ ...filters, offset: filters.offset + 24});
        const { data, error } = await getList(params);
        if (data && data.books) {
            setBooks([...books, ...data.books])
        }
        if(error){
            dispatch(setIsError(t(`general.models.serverErrors.library.${error.status}`, `${error.data?.error_code}: ${error.data?.message}`)))
        }
        setFilters({ ...filters, offset: filters.offset + 24})
    }

    useEffect(() => {
        setIsLoading(true)
        let newSub = ''
        if (firstTime) {
            newSub = searchParams.get('subject') || ''
            setFilters({ ...filters, subject: newSub })
            firstTime && setFirstTime(false);
        }
        onSearch(newSub)
    }, [filters.grade, filters.publication_language, filters.package_name, filters.publisher, filters.subject, filters.collection_id, filters.publisher_id]);

    return (
        <div className="px-2">
            <h1 className="text-3xl m-2">{t('libraryPage.search.library')}</h1>
            <div className="desktop:flex">
                <div className="desktop:w-1/3">
                    <div className={` ${isAuth ? `bg-${role}` : 'bg-main-color'} rounded-xl mx-2 p-4 relative`}>
                        <input
                            className="w-full rounded-lg p-2"
                            placeholder={t('libraryPage.search.searchPlaceholder')}
                            id="search"
                            value={filters.input_text}
                            onChange={e => setFilters({...filters, input_text: e.target.value})}
                        />
                        <label htmlFor="search" className="absolute right-8 top-6 hover:cursor-pointer">
                            <button>
                                <MIcon
                                    type={'search'}
                                    className={'text-[#0000004D] cursor-pointer'}
                                />
                            </button>
                        </label>
                    </div>
                    <div className="bg-card-bg rounded-xl m-2 p-4 flex flex-col tablet:flex-row tablet:flex-wrap desktop:flex-col">
                        <div className="tablet:w-1/2 desktop:w-full">
                            <FilterSelect
                                name="grade"
                                label={t('libraryPage.search.class')}
                                options={gradeOptions}
                                language={filters.publication_language}
                                setState={setFilters}
                                state={filters}
                                className="tablet:mr-2 desktop:mr-0"
                            />
                        </div>
                        <div className="tablet:w-1/2 desktop:w-full" >
                            <FilterSelect
                                name="subject"
                                label={t('libraryPage.search.subject')}
                                options={subjectsOptions}
                                setState={setFilters}
                                state={filters}
                                className="tablet:mr-2 desktop:mr-0"
                            />
                        </div>
                        <div className="tablet:w-1/2 desktop:w-full">
                            <FilterSelect
                                name="publisher_id"
                                label={t('libraryPage.search.publisher')}
                                options={isPublishersLoading ? [] : publisherOptions}
                                language={filters.publication_language}
                                setState={setFilters}
                                state={filters}
                                className="tablet:mr-2 desktop:mr-0"
                                withPath={false}
                            />
                        </div>
                        <div className="tablet:w-1/2 desktop:w-full">
                            <FilterSelect
                                name="publication_language"
                                label={t('libraryPage.search.language')}
                                options={languageOptions}
                                language={filters.publication_language}
                                setState={setFilters}
                                state={filters}
                                className="tablet:mr-2 desktop:mr-0"
                            />
                        </div>
                        <div className="tablet:w-1/2 desktop:w-full">
                            <FilterSelect
                                name="collection_id"
                                label={t('libraryPage.search.package')}
                                options={isPackagesLoading ? [] : packageOptions}
                                language={filters.publication_language}
                                setState={setFilters}
                                state={filters}
                                className="tablet:mr-2 desktop:mr-0"
                                withPath={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="desktop:w-2/3">
                    <div className="px-2">
                        <button
                            className={`${filters.grade === '' ? `btn-primary-library ${isAuth ? `btn-primary-library-${role}` : 'btn-primary-library'}` : `${isAuth ? `btn-secondary-border-library-${role}` : 'btn-secondary-border-library'}`} mx-1 mb-1 w-[95px]`}
                            onClick={() => setFilters({...filters, grade: ''})}
                            disabled={isLoading}
                        >
                            {t('libraryPage.classes.all')}
                        </button>
                        {[...Array(11)].map((_, index) => (
                            <button
                                key={index}
                                className={`${
                                    filters.grade === index + 1 ? `${isAuth ? `btn-primary-library-${role}` : 'btn-primary-library'}` : `${isAuth ? `btn-secondary-border-library-${role}` : 'btn-secondary-border-library'}`
                                } mx-1 mb-1 w-[95px]`}
                                onClick={() => setFilters({ ...filters, grade: index + 1 })}
                                disabled={isLoading}
                            >
                                {t(`libraryPage.filters.grade.${index + 1}`)}
                            </button>
                        ))}
                    </div>
                    <div className="p-4">
                        <span className="font-bold">{t('libraryPage.books.found')}: {total} </span>
                    </div>
                    <div className="flex flex-wrap mb-6 justify-around desktop:justify-start">
                        {
                            books.map(book => {
                                return (
                                    <BookItem key={book.book_id} book={book}/>
                                )
                            })
                        }
                    </div>
                    {
                        books.length !== 0 && books.length % 24 === 0 && <div className="flex justify-center mb-6">
                            <button
                                className="btn-secondary-border"
                                onClick={() => showMore()}
                            >
                                {t('libraryPage.books.showMore')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Library;
