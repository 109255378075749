import HeaderInfo from "../../components/advantages/HeaderInfo";
import privateUser from '../../assets/img/SirclePrivateUser.png'
import FooterInfo from "../../components/advantages/FooterInfo";
import Content from '../../assets/img/Content.png'
import ContentMobile from '../../assets/img/ContentMobile.png'
import Education from '../../assets/img/Education.png'
import EducationMobile from '../../assets/img/EducationMobile.png'
import Stats from '../../assets/img/Stats.png'
import StatsMobile from '../../assets/img/StatsMobile.png'
import Searching from '../../assets/img/Searching.png'
import SearchingMobile from '../../assets/img/SearchingMobile.png'
import Searching2 from '../../assets/img/Searching2.png'
import Searching2Mobile from '../../assets/img/Searching2Mobile.png'
import LibraryLaptop from '../../assets/img/LibraryLaptop.png'
import LibraryMobile from '../../assets/img/LibraryMobile.png'
import OquWeb from '../../assets/img/OquWeb.png'
import OquWebMobile from '../../assets/img/OquWebMobile.png'
import Todo from '../../assets/img/Todo.png'
import TodoMobile from '../../assets/img/TodoMobile.png'
import Packages from "../../components/main-page/packages/Packages";
import {useTranslation} from "react-i18next";

const PrivateUserAdvantage = () => {
    const {t} = useTranslation();
    return (
        <div>
            <HeaderInfo
                img={privateUser}
                children={
                    <>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.header.text1')}</p>
                        <ul className={'list-disc ml-4 mb-4'}>
                            <li className={'mb-2'}>{t('privateUserAdvantagePage.header.text2')}</li>
                            <li className={'mb-2'}>{t('privateUserAdvantagePage.header.text3')}</li>
                            <li className={'mb-2'}>{t('privateUserAdvantagePage.header.text4')}</li>
                        </ul>
                        <p>{t('privateUserAdvantagePage.header.text5')}</p>
                    </>
                }
                title={t('privateUserAdvantagePage.header.title')}
                bgColor={'private_user'}
            />
            <div className={'grid sm:grid-cols-1 desktop:grid-cols-2 gap-y-6 tablet:gap-y-7 desktop:gap-y-8 desktop:gap-x-[123px] p-3 tablet:p-5 desktop:p-[45px] font-medium'}>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Content} alt={'Content'}/>
                    <img className={'mb-4 desktop:hidden'} src={ContentMobile} alt={'Content'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.content1')}</p>
                        <p>{t('privateUserAdvantagePage.main.content2')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={LibraryLaptop} alt={'Library'}/>
                    <img className={'mb-4 desktop:hidden'} src={LibraryMobile} alt={'Library'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.library1')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.library2')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.library3')}</p>
                        <p>{t('privateUserAdvantagePage.main.library4')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Stats} alt={'Library'}/>
                    <img className={'mb-4 desktop:hidden'} src={StatsMobile} alt={'Library'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.stats1')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.stats2')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.stats3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Searching} alt={'Searching'}/>
                    <img className={'mb-4 desktop:hidden'} src={SearchingMobile} alt={'Searching'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.searching1')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.searching2')}</p>
                        <p>{t('privateUserAdvantagePage.main.searching3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Education} alt={'Education'}/>
                    <img className={'mb-4 desktop:hidden'} src={EducationMobile} alt={'Education'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.education1')}</p>
                        <p>{t('privateUserAdvantagePage.main.education2')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Searching2} alt={'Searching'}/>
                    <img className={'mb-4 desktop:hidden'} src={Searching2Mobile} alt={'Searching'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.Searching1')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.Searching2')}</p>
                        <p>{t('privateUserAdvantagePage.main.Searching3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={OquWeb} alt={'Any device'}/>
                    <img className={'mb-4 desktop:hidden'} src={OquWebMobile} alt={'Any device'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.oquWeb1')}</p>
                        <p>{t('privateUserAdvantagePage.main.oquWeb2')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Todo} alt={'Todo'}/>
                    <img className={'mb-4 desktop:hidden'} src={TodoMobile} alt={'Todo'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('privateUserAdvantagePage.main.todo1')}</p>
                        <p className={'mb-4'}>{t('privateUserAdvantagePage.main.todo2')}</p>
                        <p>{t('privateUserAdvantagePage.main.todo3')}</p>
                    </div>
                </div>
            </div>
            <FooterInfo
                color={'private_user'}
                children={
                <div>
                    <p className={'mb-4'}>
                        {t('privateUserAdvantagePage.footer.text1')}
                    </p>
                    <p className={'mb-4'}>
                        {t('privateUserAdvantagePage.footer.text2')}
                    </p>
                    <p>
                        {t('privateUserAdvantagePage.footer.text3')}
                    </p>
                </div>
            }
            />
            <Packages isPriceHidden={true} className={'tablet:px-[45px] px-3'}/>
        </div>
    )
}
export default PrivateUserAdvantage;