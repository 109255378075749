import MError from "../m-error/MError";

const MProfileGroup = ({title, children, error}) => {
    return (
        <div className="flex flex-col mb-4">
            <div>
                <div className={`font-bold text-base text-black mb-2 w-min whitespace-nowrap border-b-2 border-transparent ${error && '!border-red-700'}`}>
                    {title}
                </div>
                <div className="flex flex-col gap-2">
                    {children}
                </div>
            </div>
            {error && error !== true && <MError errorMessage={error}/>}
        </div>
    );
};

export default MProfileGroup;
