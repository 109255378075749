const Close = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M5 5L19 19"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M19 5L5 19"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
          </svg>
        </span>
    );
};

export default Close;
