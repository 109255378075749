import React from 'react';
import { Link } from 'react-router-dom';

function SearchItem({index, title, subject, grade, publishingHouse, id, url, ...props }) {
    return (
        <li className="flex flex-row py-2 gap-2" key={id}>
            <div className="mr-11">{index}.</div>
            <div className="flex flex-col">
                <Link  to={`/library/${id}`} state={{url: `${url}/${id}/`}} className="font-bold hover:cursor-pointer hover:underline">
                    {title}
                </Link>
                <span className="text-[#00000066] text-sm">
                    {subject}. {grade} класс ({publishingHouse})
                </span>
            </div>
        </li>
    );
}

export default SearchItem;
