import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { isEqual } from 'lodash';
import {
    userRoleOptions,
    subscriptionPresenceOptions,
    userStatusOptions, userRoleOptionsForSchoolAdmin,
} from '../../models/users';
import { useGetUsersListMutation } from '../../api/UserApi/userList';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MTable from '../../components/common/m-table/MTable';
import MTag from '../../components/common/m-tag/MTag';
import TableSelectFilter from '../../components/common/m-table/TableSelectFilter';
import {dropFilter, saveFilter} from "../../store/reducers/filterSlice";
import TableInputFilter from '../../components/common/m-table/TableInputFilter';
import MIcon from '../../components/common/m-icon/MIcon'
import Spinner from '../../components/common/spinner/spinner';
import {useGetPackageListQuery} from "../../api/LibraryApi/LibraryService";

export const USERS_VIEW_FILTER_NAME = 'UsersView'
const USERS_PAGE_SIZE = 10
const DEFAULT_FILTER = { limit: USERS_PAGE_SIZE }

function Users(props) {
    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState({})
    const savedFilters = useSelector(state => state.filter.savedFilters[USERS_VIEW_FILTER_NAME]) || DEFAULT_FILTER
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const isDefaultFilter = isEqual(filters, DEFAULT_FILTER)
    const isFiltersApplied = isEqual(filters, savedFilters)
    const [ getUsersList ] = useGetUsersListMutation()
    const { data: packages, isLoading: isPackagesLoading } = useGetPackageListQuery();
    const packageOptions = packages?.packages?.map(item=>({name:item.name, value: item.package_id, id: item.package_id}))


    useEffect(() => {
        onSearch()
        setFilters(savedFilters || DEFAULT_FILTER)
    }, [savedFilters])

    const reformatParams = (data)  => {
        let filterStr = '';
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (value) {
                    if (filterStr === '') {
                        filterStr = `?${key}=${value}`
                    } else {
                        filterStr = filterStr + `&${key}=${value}`
                    }
                }
            }
        }
        return filterStr;
    }

    const onApplyFilters = async (sub) => {
        setCurrentPage(1)
        dispatch(saveFilter({
            filterName: USERS_VIEW_FILTER_NAME,
            filterValue: { ...filters, offset: 0 }
        }))
    }

    const onResetFilters = () => {
        setFilters(DEFAULT_FILTER)
        dispatch(dropFilter({ filterName: USERS_VIEW_FILTER_NAME }))
    }

    const onPageClick = (page) => {
        setCurrentPage(page)
        dispatch(saveFilter({
            filterName: USERS_VIEW_FILTER_NAME,
            filterValue: { ...savedFilters, offset: (page - 1) * USERS_PAGE_SIZE }
        }))
    }

    const onSearch = async (sub) => {
        const params = reformatParams({...savedFilters})
        setIsLoading(true)
        const { data, error } = await getUsersList(params)
        setIsLoading(false)
        if(error){
            dispatch(setIsError(t(`general.models.serverErrors.common.${error.status}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else if (data && data.users?.users) {// TODO переделать на API users
            //Фильтрация пользователей для школьного админа

            setUsers(data.users?.users)
            setTotal(data.users?.count_of_users)
        }
    }

    const onChangeFilter = (newState) => {
        setFilters(newState)
    }

    const columns = [
        {
            label: t('usersPage.list.columns.fullname'),
            key: 'fullname',
            render: (record) =>(
                <Link
                    to={`/users/${record.username}/${record.roles[0]}`}
                    className="text-blue3 font-medium text-sm underline"
                >
                    {record.fullname}
                </Link>
            )
        },
        {
            label: t('usersPage.list.columns.username'),
            key: 'username',
            showForModes: ['desktop']
        },
        {
            label: t('usersPage.list.columns.roles'),
            key: 'roles',
            showForModes: ['tablet', 'desktop'],
            render: (record) => <div className="flex flex-col gap-2.5">
                {userRoleOptions.filter((option) => record.roles?.includes(option.value)).map((option) => (
                    <MTag
                        key={option.value}
                        title={t(`usersPage.search.filters.role.${option.value}`)}
                        extraClassName={option.extraClassName}
                    />)
                )}
            </div>
        },
        {
            label: t('usersPage.list.columns.school_name'),
            key: 'school_name',
            showForModes: ['tablet', 'desktop']
        },
        {
            label: t('usersPage.list.columns.status_id'),
            key: 'status_id',
            render: (record) => {
                const color = record?.status_id === 1
                    ? 'text-green2'
                    : record?.status_id === 3
                        ? 'text-orange1'
                        : 'text-red2'
                return <div className={`flex flex-col gap-2.5 ${color}`}>
                    {t(`usersPage.search.filters.status_id.${record.status_id}`)}
                </div>
            }
        }
    ]

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-orange2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => navigate('/create/user')}
        >
            <MIcon
                type="plusCircle"
                className={'text-orange3'}
            />
            {t('usersPage.list.buttons.addUser')}
        </button>
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="px-2 pt-[50px] pb-[82px]">
                <h1 className="text-[28px] font-medium mb-[40px]">{t('usersPage.header')}</h1>
                <h2 className={'text-2xl font-semibold m-2'}>{t('usersPage.title')}</h2>

                <div className="flex flex-col gap-2">
                    <div
                        className="bg-card-bg rounded-xl m-2 p-4 pb-6 flex flex-none flex-col desktop:flex-row tablet:flex-row tablet:flex-wrap w-full">
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5 mb-3">
                            <TableInputFilter
                                name="fullname"
                                placeholder={t('usersPage.search.filters.fullname.placeholder')}
                                tPath={'usersPage.search.filters'}
                                setState={onChangeFilter}
                                state={filters}
                                value={filters.fullname}
                                max={90}
                            />
                        </div>
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                            <TableSelectFilter
                                name="role"
                                options={userRoleOptions}
                                tPath={'usersPage.search.filters'}
                                setState={onChangeFilter}
                                state={filters}
                                allText={t('usersPage.search.filters.role.all')}
                            />
                        </div>
                            <>
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                                    <TableSelectFilter
                                        name="is_subscribed"
                                        options={subscriptionPresenceOptions}
                                        tPath={'usersPage.search.filters'}
                                        setState={onChangeFilter}
                                        state={filters}
                                        allText={t('usersPage.search.filters.is_subscribed.all')}
                                    />
                                </div>
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                                    <TableSelectFilter
                                        name="package_id"
                                        options={packageOptions}
                                        setState={onChangeFilter}
                                        state={filters}
                                        label={'Выберите тип подписки'}
                                        allText={t('usersPage.search.filters.subscription.all')}
                                    />
                                </div>
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                                    <TableInputFilter
                                        name="school_bin"
                                        placeholder={t('usersPage.search.filters.school_bin.placeholder')}
                                        tPath={'usersPage.search.filters'}
                                        regexTemplate={/^\d{0,12}$/}
                                        setState={onChangeFilter}
                                        state={filters}
                                    />
                                </div>
                            </>
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                            <TableSelectFilter
                                name="status_id"
                                options={userStatusOptions}
                                tPath={'usersPage.search.filters'}
                                setState={onChangeFilter}
                                state={filters}
                                allText={t('usersPage.search.filters.status_id.all')}
                            />
                        </div>
                        <div className="w-full ml-2.5 mt-6 flex flex-col items-center gap-4">
                            <div className="w-full flex flex-row items-center gap-4">
                                <button
                                    className="flex flex-row h-10 px-6  items-center bg-blue2 border border-blue4 rounded-lg text-sm text-white font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                    onClick={onApplyFilters}
                                    disabled={isFiltersApplied}
                                >
                                    {t('usersPage.search.buttons.apply')}
                                </button>
                                <button
                                    className="flex flex-row h-10 px-6  items-center border border-blue2 rounded-lg text-sm text-main-color font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                    onClick={onResetFilters}
                                    disabled={isDefaultFilter}
                                >
                                    {t('usersPage.search.buttons.reset')}
                                </button>
                            </div>
                            <div
                                className="text-orange1 text-sm mr-auto">{!isFiltersApplied && 'Нажмите кнопку для применения фильтра'}</div>
                        </div>
                    </div>
                    <div className="w-full mx-2 pt-6">
                        <MTable
                            columns={columns}
                            data={users}
                            total={total}
                            currentPage={currentPage}
                            setCurrentPage={onPageClick}
                            foundTitle={t('usersPage.search.found')}
                            buttons={buttons}
                            uniqueKey={'username'}
                        />
                    </div>
                </div>
            </div>
        </Spinner>
    );
}

export default Users;
