import { useRef } from "react";

const MTooltip = ({ children, tooltip }) => {
    const tooltipRef = useRef(null);
    const container = useRef(null);

    return (
        <div
            ref={container}
            // onMouseEnter={({ clientX }) => {
            //     if (!tooltipRef.current || !container.current) return;
            //     const { left } = container.current.getBoundingClientRect();
            //     tooltipRef.current.style.left = clientX - left + "px";
            // }}
            className="group relative inline-block"
        >
            {children}
            {tooltip ? (
                <span
                    ref={tooltipRef}
                    className="invisible group-hover:visible opacity-0 left-[-100px] group-hover:opacity-100 transition bg-gray-600 text-white p-2 text-sm rounded absolute top-full mt-3 desktop:whitespace-nowrap"
                >
          {tooltip}
        </span>
            ) : null}
        </div>
    );
};

export default MTooltip;