import {Link} from "react-router-dom";

const PrivateUser = () => {
    return (
        <div className={'py-[50px] px-5 flex flex-col gap-10'}>
            <p className={'font-medium text-[28px]'}>Главная</p>
            <div>
                <p className={'font-bold text-xl mb-4'}>Мои учебники</p>
                <div>
                    <p className={'text-gray4'}>Список учебников пуст</p>
                    <p className={'text-gray4'}>Для добавления учебников в данный раздел перейдите в <Link to={'/library'} className={'underline text-main-color'}>Библиотеку</Link></p>
                </div>
            </div>
        </div>
    )
}

export default PrivateUser;