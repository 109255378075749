const YoutubeFilled = ({className}) => {
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                <path
                    d="M23.6063 2.29927C23.5031 1.24818 22.6775 0.459854 21.6456 0.354744C15.1958 -0.118248 8.74594 -0.118248 2.29613 0.354744C1.31576 0.407299 0.490186 1.24818 0.386989 2.29927C-0.128996 6.76642 -0.128996 11.2336 0.386989 15.7007C0.490186 16.7518 1.31576 17.5401 2.34773 17.6453C8.79754 18.1182 15.2474 18.1182 21.6972 17.6453C22.7291 17.5927 23.5031 16.7518 23.6579 15.7007C24.1223 11.2336 24.1223 6.76642 23.6063 2.29927ZM15.8149 8.92117L9.98431 12.3372C9.77791 12.4423 9.51992 12.2847 9.51992 12.0745V5.24234C9.51992 4.97956 9.77791 4.8219 9.98431 4.97956L15.8149 8.39562C16.0213 8.44818 16.0213 8.7635 15.8149 8.92117Z"
                    fill="currentColor"/>
            </svg>
        </span>
    )
}
export default YoutubeFilled;