import MModal from "../m-modal/MModal";
import Warning from "../../../assets/img/Warning.png";

const MModalWarning = ({
    setIsOpen,
    isOpen,
    onAccept,
    text
}) => (
        <MModal
            active={isOpen}
            setActive={() => setIsOpen(false)}
            onCloseModal={() => {}}
        >
            <div className={'w-[372px] flex flex-col items-center gap-[24px] justify-center'}>
                <img alt={'error'} src={Warning} className={'w-fit'}/>

                <p className={'w-[260px] text-center'}>
                    {text}
                </p>

                <div className={'flex gap-4'}>
                    <button
                        className={'btn-primary w-[154px]'}
                        onClick={()=>{
                            onAccept()
                            setIsOpen(false)
                        }}
                    >
                        Да
                    </button>

                    <button
                        className={'btn-secondary-border w-[154px]'}
                        onClick={() => setIsOpen(false)}
                    >
                        Нет
                    </button>
                </div>
            </div>
        </MModal>
    )

export default MModalWarning;