import MModal from "../m-modal/MModal";
import Success from "../../../assets/img/Success.png";
import {useDispatch, useSelector} from "react-redux";
import {setIsSuccess} from "../../../store/reducers/notificationSlice";
import {useNavigate} from "react-router-dom";

const MModalSuccess = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.notification.isSuccess)
    const text = useSelector(state => state.notification.successText)
    const navigation = useSelector(state=> state.notification.successNavigation)
    const navigate = useNavigate()
    return (
        <MModal
            active={isOpen}
            setActive={() => dispatch(setIsSuccess({successText: '', successNavigation: navigation}))}
            onCloseModal={() => {
                navigate(navigation)
            }}
        >
            <div className={'w-[372px] flex flex-col items-center gap-[24px] justify-center'}>
                <img alt={'error'} src={Success} className={'w-fit'}/>
                <p className={'w-[260px] text-center'}>
                    {text}
                </p>
                <div>
                    <button
                        className={'btn-primary'}
                        onClick={() => {
                            dispatch(setIsSuccess({successText: '', successNavigation: navigation}))
                            navigate(navigation)
                        }}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </MModal>
    )
}

export default MModalSuccess;