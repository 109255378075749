import React, { useEffect, useState } from 'react';
import TabItem from '../../components/common/m-tab/MTabItem';
import TabList from '../../components/common/m-tab/MTabList';
import { useTranslation } from 'react-i18next';
import UserAdd from '../users/UserAdd';
import SchoolAdd from '../schools/SchoolAdd';
import { useParams } from 'react-router-dom';

function Create({ role = null }) {
    const [ tabIndex, setTabIndex ] = useState(0)
    const { type } = useParams()
    const {t} = useTranslation()

    useEffect(() => {
        if ( type === 'school' ) {
            setTabIndex(1)
        }
    }, []);

    return (
        <div className="p-2">
            <h1 className="text-3xl m-2">{tabIndex === 0 ? t('usersPage.header') : t('schoolPage.header')}</h1>
            <div className="text-2xl m-2 font-semibold mt-4">{tabIndex === 0 ? t('usersPage.userAdd.title') : t('schoolPage.schoolAdd.title')}</div>
            <TabList activeTabIndex={tabIndex} setTabIndex={setTabIndex} tabIndex={tabIndex} >
                <TabItem label={t('usersPage.userAdd.tabs.user.label')}>
                    <UserAdd/>
                </TabItem>
                <TabItem label={t('schoolPage.schoolAdd.tabs.school.label')}>
                    <SchoolAdd/>
                </TabItem>
            </TabList>
        </div>
    );
}

export default Create;
