import React, {useEffect, useState} from "react";
import MBarChart from "../../components/common/BarChart/BarChart";
import MStatsTable from "../../components/common/m-stats-table/MStatsTable";
import {useGetUsersListMutation} from "../../api/UserApi/userList";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLazyGetSubsQuery} from "../../api/SubsApi/SubsService";
import MTable from "../../components/common/m-table/MTable";
import {userRoleOptions} from "../../models/users";
import MTag from "../../components/common/m-tag/MTag";
import MIcon from "../../components/common/m-icon/MIcon";
import Spinner from "../../components/common/spinner/spinner";
import InfoModal from "../../components/layout/navbar/modals/InfoModal";
import useDeviceType from "../../hooks/useDeviceType";
import {Link} from "react-router-dom";
import {filterAndModifyUsers} from "../../utils/school-admin";
import {setIsError} from "../../store/reducers/notificationSlice";

const SchoolAdmin = () => {
    const dispatch = useDispatch();
    const [getUsers] = useGetUsersListMutation();
    const [getSubs] = useLazyGetSubsQuery();
    const [stats, setStats] = useState({});
    const [users, setUsers] = useState([])
    const [subs, setSubs] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [currentData, setCurrentData] = useState(new Map())
    const [currentPage, setCurrentPage] = useState(1)
    const {isMobile} = useDeviceType();
    const onPageClick = (page) => {
        setCurrentPage(page)
    }
    const school_bin = useSelector((state) => state.user.school_bin);

    const {t} = useTranslation();

    const getData = async () => {
        setIsLoading(true)

        const u = await getUsers(`?school_bin=${school_bin}&limit=10`);
        if (u.error) {
            //TODO Обработка ошибок
            dispatch(setIsError('uError'))
        } else {
            setStats({
                ...stats,
                student: u.data?.users.student,
                teacher: u.data?.users.teacher,
                school_admin: u.data?.users.school_admin,
                totalUsers: u.data?.users.count_of_users,
            })
            setUsers(filterAndModifyUsers(u.data?.users.users))
            const mySubs = await getSubs(`?school_bin=${school_bin}&limit=10`)
            if (mySubs.error) {
                dispatch(setIsError('mySubsError'))
            } else {
                setSubs(mySubs.data?.subscriptions)
            }
        }
        setIsLoading(false)

    }
    useEffect(() => {
        getData();
    }, []);

    const statsData = [
        {
            text: t('superadminPage.stats.pupils'),
            count: stats.student
        },
        {
            text: t('superadminPage.stats.teachers'),
            count: stats.teacher
        },
        {
            text: t('superadminPage.stats.school_admins'),
            count: stats.school_admin
        }
    ]

    const subColumns = [
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.package'),
            key: 'package_name',
            render: (record) => (
                isMobile ? (
                    <span
                        className="text-blue3 font-medium text-sm underline cursor-pointer"
                        onClick={() => {
                            const currentSub = subs.subscriptions.find(item => item.subscription_id === record.subscription_id)
                            setIsOpen(true)
                            setCurrentData(new Map()
                                .set("Срок действия", `${currentSub.start_date}-${currentSub.end_date}`)
                                .set("Кол-во", currentSub.count)
                                .set("Используют", currentSub.active_count)
                                .set("Не используют", currentSub.inactive_count)
                                .set('title', currentSub.package_name)
                            )
                        }
                        }
                    >
                {record.package_name}
            </span>
                ) : record.package_name
            )
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.valid_period'),
            key: 'start_date',
            showForModes: ['tablet', 'desktop'],
            render: (sub) => (
                <span>
                    {`${sub.start_date}-${sub.end_date}`}
                </span>
            )
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.qty'),
            key: 'count'
        },
        {
            label: 'Используют',
            key: 'active_count',
            showForModes: ['tablet'],
            render: (sub) => (
                <span className={'text-green2'}>
                    {sub.active_count}
                </span>
            )
        },
        {
            label: 'Не используют',
            key: 'inactive_count',
            showForModes: ['tablet'],
            render: (sub) => (
                <span className={'text-red4'}>
                    {sub.inactive_count}
                </span>
            )
        }
    ]
    const userColumns = [
        {
            label: t('usersPage.list.columns.fullname'),
            key: 'fullname',
            render: (record) => (
                isMobile ? (
                    <span
                        className="text-blue3 font-medium text-sm underline cursor-pointer"
                        onClick={() => {
                            const currentUser = users.find(item => item.username === record.username)
                            setIsOpen(true)
                            setCurrentData(new Map()
                                .set(t('usersPage.list.columns.fullname'), currentUser.fullname)
                                .set(t('usersPage.list.columns.status'), t(`usersPage.search.filters.status_id.${currentUser.status_id}`))
                                .set(t('usersPage.list.columns.roles'),  currentUser.roles.map(role=> t(`general.models.auth.roleType.${role}`))))
                        }
                        }
                    >
                {record.fullname}
            </span>
                ) : record.fullname
            )
        },
        {
            label: t('usersPage.list.columns.roles'),
            key: 'roles',
            showForModes: ['tablet', 'desktop'],
            render: (record) => <div className="flex flex-col gap-2.5">
                {userRoleOptions.filter((option) => record.roles?.includes(option.value)).map((option) => (
                    <MTag
                        key={option.value}
                        title={t(`usersPage.search.filters.role.${option.value}`)}
                        extraClassName={option.extraClassName}
                    />)
                )}
            </div>
        },
        {
            label: "Статус",
            key: 'status_id',
            render: (user) => {
                const {status_id} = user
                return (
                    <span className={status_id === 1 ? 'text-green2' : status_id === 2 ? 'text-red4' : 'text-gray4'}>
                        {t(`usersPage.search.filters.status_id.${user.status_id}`)}
                    </span>
                )
            }
        }
    ]
    return (
        <Spinner spinning={isLoading}>
        <div className={'pt-[52px] mb-[108px] px-5'}>
            <h1 className={'text-[28px] font-medium mb-8' }>Главная</h1>
            <div className="grid desktop:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-[68px]">
                    <MBarChart subs={{active: subs.total_active_count, inactive: subs.total_inactive_count}} width={'tablet:w-full desktop:w-[642px]'}/>
                <MStatsTable data={statsData} title="Статистика пользователей" total={stats.totalUsers}
                             withShowMore={false}/>
                    <div>
                        <p className="font-bold my-8 text-xl">Подписки</p>
                        <MTable
                            columns={subColumns}
                            data={subs.subscriptions || []}
                            trimAmount={25}
                            currentPage={currentPage}
                            setCurrentPage={onPageClick}
                            withTotal={false}
                            buttons={<></>}
                            uniqueKey="subscription_id"
                        />
                    </div>
                    <div>
                        <p className="font-bold my-8 text-xl">Пользователи</p>
                        <MTable
                            columns={userColumns}
                            data={users}
                            trimAmount={25}
                            currentPage={currentPage}
                            withTotal={false}
                            setCurrentPage={onPageClick}
                            buttons={
                                <div className="flex justify-between w-full">
                                    <Link to={'/school-admin/create/user'} className="btn-primary-library-school_admin items-center flex">
                                            Добавить пользователя
                                    </Link>

                                    <Link to='/school-admin/users'>
                                        <button className="btn-secondary font-normal flex items-center gap-2">
                                            {t('superadminPage.more')}
                                            <MIcon type="arrowRight" className="text-disabled-text"/>
                                        </button>
                                    </Link>
                                </div>
                            }
                            uniqueKey="username"
                        />
                    </div>
                </div>
        </div>
            <InfoModal isInfoModal={isOpen} setIsInfoModal={setIsOpen} data={currentData}/>
        </Spinner>
    )
}

export default SchoolAdmin;