import MModal from "../m-modal/MModal";
import Error from '../../../assets/img/Error.png'
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../../store/reducers/notificationSlice";
import {useTranslation} from "react-i18next";

const MModalError = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.notification.isError)
    const text = useSelector(state => state.notification.errorText)
    const {t} = useTranslation();
    return (
        <MModal
            active={isOpen}
            setActive={() => dispatch(setIsError(''))}
            onCloseModal={() => {
            }}
        >
            <div className={'w-[372px] flex flex-col items-center gap-[24px] justify-center'}>
                <img alt={'error'} src={Error} className={'w-fit'}/>
                <p className={'w-[260px] text-center'}>
                    {text}
                </p>
                <div>
                    <button
                        className={'btn-primary'}
                        onClick={() => dispatch(setIsError(''))}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </MModal>
    )
}

export default MModalError;