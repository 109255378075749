import React, { useEffect, useLayoutEffect, useState } from 'react';
import NavigationPanel from './navigation-panel/NavigationPanel';
import AuthPanel from './auth-panel/AuthPanel';
import './Navbar.module.css';
import BurgerButton from './BurgerButton';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MIcon from '../../common/m-icon/MIcon';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const role = useSelector(state => state.user.activeRole);
    const isAuth = useSelector(state=>state.auth.isAuth);
    const [bgColor, setBgColor] = useState('bg-white');
    useEffect(() => {
        const handleResize = (event) => {
            if (event.target.innerWidth > 1440) {
                setIsOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useLayoutEffect(() => {
      switch (role) {
          case 'school_admin':
              setBgColor('bg-school_admin');
              break;
          case 'teacher':
              setBgColor('bg-teacher');
              break;
          case 'student':
              setBgColor('bg-student');
              break;
          case 'private_user':
              setBgColor('bg-private_user');
              break;
          case 'superadmin':
              setBgColor('bg-superadmin');
              break;
          default:
              setBgColor('bg-white');
      }
    }, [role]);

    const toggleNavbar = async () => {
        setIsOpen(!isOpen);
    }

    return (
        <nav className={`flex justify-between items-center flex-wrap px-[10px] w-full border-b border-b-[#00000033] ${isOpen ? 'fixed bg-white z-50' : bgColor}`}>
            {isOpen
                ? (
                    <>
                        <Link to="/">
                            <div className="h-[56px] flex items-center pl-[10px] desktop:pl-6">
                                <MIcon
                                    type={'logo'}
                                    className={`${isAuth ? 'text-white' : 'text-main-color' } cursor-pointer`}
                                />
                            </div>
                        </Link>
                        <div className="hidden desktop:flex flex-row">
                            <NavigationPanel role={role} />
                        </div>

                        <div className="flex justify-end desktop:justify-end">
                            <div className="flex mr-2 desktop:mr-0 ">
                                <AuthPanel isAuth={isAuth} role={role} isOpen={isOpen} setIsOpen={setIsOpen}/>
                            </div>
                            <BurgerButton toggleNavbar={toggleNavbar} isOpen={isOpen} setIsOpen={setIsOpen}/>
                        </div>
                    </>
                )
                : (
                    <>
                        <Link to="/">
                            <div className="h-[56px] flex items-center pl-[10px] desktop:pl-6">
                                <MIcon
                                    type={'logo'}
                                    className={`${isAuth ? 'text-white' : 'text-main-color' } cursor-pointer`}
                                />
                            </div>
                        </Link>
                        <div className="hidden desktop:flex flex-row">
                            <NavigationPanel isAuth={isAuth} role={role}/>
                        </div>

                        <div className="flex justify-end desktop:justify-between">
                            <div className="hidden tablet:flex mr-2 desktop:mr-0 items-center">
                                <AuthPanel isAuth={isAuth} role={role} isOpen={isOpen} setIsOpen={setIsOpen}/>
                            </div>
                            <BurgerButton toggleNavbar={toggleNavbar} isOpen={isOpen} setIsOpen={setIsOpen}/>
                        </div>
                    </>
                )
            }

            {isOpen && (
                <div className="flex flex-col basis-full h-screen border-t border-t-[#00000033]">
                    <NavigationPanel toggle={toggleNavbar} isOpen={isOpen} role={role} isAuth={isAuth}/>
                </div>
            )}
        </nav>
    )
}

export default Navbar;
