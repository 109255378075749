import React from 'react';
import FaqCard from './FaqCard';
import { useTranslation } from 'react-i18next';

function Faq(props) {
    const { t } = useTranslation();

    return (
        <div className="px-4">
            <h1 className="text-3xl my-2">{t('faqPage.title')}</h1>
            <FaqCard
                src="https://opiq02.blob.core.windows.net/static/manuals/mektep_adiministrator_video_guide_ru.mp4"
                title={t('faqPage.adminTitle')}
                fileName={t('faqPage.adminFileName')}
            />
            <FaqCard
                src="https://oqulyqtar.atamura.kz/public/videos/ru/forchild.mp4"
                title={t('faqPage.studentTitle')}
                fileName={t('faqPage.studentFileName')}
            />
            <FaqCard
                src="https://oqulyqtar.atamura.kz/public/videos/ru/forcouch.mp4"
                title={t('faqPage.teacherTitle')}
                fileName={t('faqPage.teacherFileName')}
            />
        </div>
    );
}

export default Faq;
