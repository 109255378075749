import {format, isSameDay, isSameMonth, isWeekend} from "date-fns";

const Day = ({
                 date,
                 currentDate,
                 selectedStartDate
             }) => {
    const isToday = (day) => isSameDay(day, (selectedStartDate));
    const weekendStyle = isWeekend(date) ? 'text-red2' : '';
    const sameDayStyle = isToday(date) ? 'text-blue3' : '';
    const sameMonthStyle = isSameMonth(date, currentDate) ? '' : '!text-gray4';
    return (
        <span
            className={sameMonthStyle + " " + weekendStyle + " " + sameDayStyle }
        >
            {format(date, 'd')}
        </span>
    );
};
export default Day;