const Youtube = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="26"
              height="22"
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M17.942 1.9868L17.9419 1.98681L17.9434 1.99969C18.3445 5.44205 18.3455 8.88659 17.9851 12.3346C17.9002 12.8678 17.5291 13.1878 17.1391 13.2075L17.1308 13.2079L17.1225 13.2085C12.0566 13.5768 6.99077 13.5771 1.92482 13.2094C1.44819 13.1572 1.09706 12.8043 1.05271 12.3566L1.05283 12.3565L1.05133 12.3437C0.649558 8.89548 0.649558 5.44788 1.05133 1.99969L1.05144 1.9997L1.05271 1.9868C1.10155 1.49367 1.48713 1.15543 1.85757 1.13575L1.86487 1.13536L1.87215 1.13483C6.9381 0.766541 12.0039 0.766254 17.0699 1.13397C17.5465 1.1861 17.8976 1.53907 17.942 1.9868ZM12.8968 7.75836L12.9364 7.73536L12.9729 7.70772C13.2432 7.50304 13.3927 7.18827 13.3927 6.87265C13.3927 6.58066 13.2422 6.18111 12.8368 6.01395L8.30809 3.38364C7.99233 3.17232 7.59917 3.1645 7.28433 3.34616C6.9685 3.52839 6.7866 3.86588 6.7866 4.22263V9.58454C6.7866 9.99124 7.03385 10.2856 7.28433 10.4301C7.54117 10.5783 7.90212 10.632 8.24225 10.4603L8.2619 10.4504L8.28092 10.4393L12.8968 7.75836Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
          </svg>
        </span>
    );
};

export default Youtube;
