import HeaderInfo from "../../components/advantages/HeaderInfo";
import FooterInfo from "../../components/advantages/FooterInfo";
import Content from '../../assets/img/Content.png'
import ContentMobile from '../../assets/img/ContentMobile.png'
import LibraryLaptop from '../../assets/img/LibraryLaptop.png'
import LibraryMobile from '../../assets/img/LibraryMobile.png'
import Clock from '../../assets/img/Clock.png'
import ClockMobile from '../../assets/img/ClockMobile.png'
import Backpack from '../../assets/img/Backpack.png'
import BackpackMobile from '../../assets/img/BackpackMobile.png'
import Leaves from '../../assets/img/Leaves.png'
import LeavesMobile from '../../assets/img/LeavesMobile.png'
import Tablet from '../../assets/img/Tablet.png'
import TabletMobile from '../../assets/img/TabletMobile.png'
import OquWeb from '../../assets/img/OquWeb.png'
import OquWebMobile from '../../assets/img/OquWebMobile.png'
import Security from '../../assets/img/Security.png'
import SecurityMobile from '../../assets/img/SecurityMobile.png'
import School from '../../assets/img/SchoolCircle.png'
import {useTranslation} from "react-i18next";

const SchoolAdvantage = () => {
    const {t} = useTranslation();
    return (
        <div>
            <HeaderInfo
                img={School}
                children={
                    <>
                        <p className={'mb-2'}>{t('schoolAdvantagePage.header.text1')}</p>
                        <p className={'mb-2'}>{t('schoolAdvantagePage.header.text2')}</p>
                        <p className={'mb-2'}>{t('schoolAdvantagePage.header.text3')}</p>
                        <p className={'mb-2'}>{t('schoolAdvantagePage.header.text4')}</p>
                    </>
                }
                title={t('schoolAdvantagePage.header.title')}
                bgColor={'main-color'}
            />
            <div className={'grid sm:grid-cols-1 desktop:grid-cols-2 gap-y-6 tablet:gap-y-7 desktop:gap-y-8 desktop:gap-x-[123px] p-3 tablet:p-5 desktop:p-[45px] font-medium'}>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Content} alt={'Content'}/>
                    <img className={'mb-4 desktop:hidden'} src={ContentMobile} alt={'Content'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.content1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.content2')}</p>
                        <p>{t('schoolAdvantagePage.main.content3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={LibraryLaptop} alt={'Library'}/>
                    <img className={'mb-4 desktop:hidden'} src={LibraryMobile} alt={'Library'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.library1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.library2')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.library3')}</p>
                        <p>{t('schoolAdvantagePage.main.library4')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Clock} alt={'Clock'}/>
                    <img className={'mb-4 desktop:hidden'} src={ClockMobile} alt={'Clock'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.clock1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.clock2')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.clock3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Backpack} alt={'Backpack'}/>
                    <img className={'mb-4 desktop:hidden h-[252px] w-[285px]'} src={BackpackMobile} alt={'Backpack'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.backpack1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.backpack2')}</p>
                        <p>{t('schoolAdvantagePage.main.backpack3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Leaves} alt={'Environment'}/>
                    <img className={'mb-4 desktop:hidden'} src={LeavesMobile} alt={'Environment'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.leaves1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.leaves2')}</p>
                        <p>{t('schoolAdvantagePage.main.leaves3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Tablet} alt={'Integration'}/>
                    <img className={'mb-4 desktop:hidden'} src={TabletMobile} alt={'Integration'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.tablet1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.tablet2')}</p>
                        <p>{t('schoolAdvantagePage.main.tablet3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Security} alt={'Security'}/>
                    <img className={'mb-4 desktop:hidden'} src={SecurityMobile} alt={'Security'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.security1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.security2')}</p>
                        <p>{t('schoolAdvantagePage.main.security3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={OquWeb} alt={'Any device'}/>
                    <img className={'mb-4 desktop:hidden'} src={OquWebMobile} alt={'Any device'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('schoolAdvantagePage.main.oquWeb1')}</p>
                        <p className={'mb-4'}>{t('schoolAdvantagePage.main.oquWeb2')}</p>
                        <p>{t('schoolAdvantagePage.main.oquWeb3')}</p>
                    </div>
                </div>
            </div>
            <FooterInfo
                color={'main-color'}
                children={<div>
                    <p className={'mb-4'}>
                    {t('schoolAdvantagePage.footer.text1')}
                    </p>
                    <p className={'mb-4 font-bold'}>
                        {t('schoolAdvantagePage.footer.text2')}
                    </p>
                    <ul className={'list-disc ml-4 mb-6'}>
                        <li className={'mb-2'}>{t('schoolAdvantagePage.footer.text3')}</li>
                        <li className={'mb-2'}>{t('schoolAdvantagePage.footer.text4')}</li>
                        <li>{t('schoolAdvantagePage.footer.text5')}</li>
                    </ul>
                </div>
                }
            />
        </div>
    )
}
export default SchoolAdvantage;