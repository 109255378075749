const UserCircle = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle
                cx="16"
                cy="12"
                r="4"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <circle
                cx="16"
                cy="15.9998"
                r="13.3333"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M23.959 26.6667C23.7468 22.8113 22.5664 20 16.0001 20C9.43373 20 8.25333 22.8113 8.04114 26.6667"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
          </svg>
        </span>
    );
};

export default UserCircle;
