import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { isEqual } from 'lodash';
import {
    userRoleOptions,
    subscriptionPresenceOptions,
    userStatusOptions, userRoleOptionsForSchoolAdmin,
} from '../../models/users';
import { useGetUsersListMutation } from '../../api/UserApi/userList';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MTable from '../../components/common/m-table/MTable';
import MTag from '../../components/common/m-tag/MTag';
import TableSelectFilter from '../../components/common/m-table/TableSelectFilter';
import {dropFilter, saveFilter} from "../../store/reducers/filterSlice";
import TableInputFilter from '../../components/common/m-table/TableInputFilter';
import MIcon from '../../components/common/m-icon/MIcon'
import Spinner from '../../components/common/spinner/spinner';
import {filterAndModifyUsers} from "../../utils/school-admin";
import {useGetPublisherListQuery} from "../../api/LibraryApi/LibraryService";
import MCheckbox from "../../components/common/m-checkbox/MCheckbox";
import {useAdminDeleteUserMutation} from "../../api/AuthApi/authService";

export const USERS_VIEW_FILTER_NAME = 'SchoolAdminUsersView'
const USERS_PAGE_SIZE = 10
const DEFAULT_FILTER = { limit: USERS_PAGE_SIZE }

function SchoolUsers(props) {
    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [checked, setChecked] = useState([])
    const [filters, setFilters] = useState({})
    const savedFilters = useSelector(state => state.filter.savedFilters[USERS_VIEW_FILTER_NAME]) || DEFAULT_FILTER
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const isDefaultFilter = isEqual(filters, DEFAULT_FILTER)
    const isFiltersApplied = isEqual(filters, savedFilters)
    const school_bin = useSelector(state=>state.user.school_bin)
    const [ getUsersList ] = useGetUsersListMutation()
    const {data:publishers} = useGetPublisherListQuery();
    const [deleteUser] = useAdminDeleteUserMutation();
    const token = useSelector(state=>state.auth.token)
    const handleDelete = async (username) => {
        setIsLoading(true)
        const result = await deleteUser({access_token:token, username})
        if(result.error){
            dispatch(setIsError('Delete failed'))
        } else {
            setUsers(prev => prev.filter(item => item.username !== username));
            setChecked(prev=>prev.filter(item => item !== username));
        }
        setIsLoading(false)
    }
    const publisherOptions = publishers?.publishers?.map(item=>({name:item.name, value: item.publisher_id, id: item.publisher_id}))


    useEffect(() => {
        onSearch()
        setFilters(savedFilters || DEFAULT_FILTER)
    }, [savedFilters, school_bin])

    const reformatParams = (data)  => {
        let filterStr = '';
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (value) {
                    if (filterStr === '') {
                        filterStr = `?${key}=${value}`
                    } else {
                        filterStr = filterStr + `&${key}=${value}`
                    }
                }
            }
        }
        return filterStr;
    }

    const onApplyFilters = async (sub) => {
        setCurrentPage(1)
        dispatch(saveFilter({
            filterName: USERS_VIEW_FILTER_NAME,
            filterValue: { ...filters, offset: 0 }
        }))
    }

    const onResetFilters = () => {
        setFilters(DEFAULT_FILTER)
        dispatch(dropFilter({ filterName: USERS_VIEW_FILTER_NAME }))
    }

    const onPageClick = (page) => {
        setCurrentPage(page)
        dispatch(saveFilter({
            filterName: USERS_VIEW_FILTER_NAME,
            filterValue: { ...savedFilters, offset: (page - 1) * USERS_PAGE_SIZE }
        }))
    }

    const onSearch = async (sub) => {
        const params = reformatParams({...savedFilters})
        setIsLoading(true)
        const { data, error } = await getUsersList(params + `${params.length ? '&' : '?'}school_bin=${school_bin}`);
        setIsLoading(false)
        if(error){
            dispatch(setIsError(t(`general.models.serverErrors.common.${error.status}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else if (data && data.users?.users) {// TODO переделать на API users
            //Фильтрация пользователей для школьного админа
            setUsers(filterAndModifyUsers(data.users?.users))
            setTotal(data.users?.count_of_users)
        }
    }

    const onChangeFilter = (newState) => {
        setFilters(newState)
    }

    const onChangeChecked = (recordId) => {
        if (checked.includes(recordId)) {
            setChecked(checked.filter((item) => item !== recordId))
        } else {
            setChecked([ ...checked, recordId])
        }
    }

    const columns = [
        {
            label: '',
            key: 'checkFlg',
            render: (record) => {
                const current = checked.includes(record.username)
                return <MCheckbox
                    value={current}
                    setValue={() => onChangeChecked(record.username)}
                />
            }
        },
        {
            label: t('usersPage.list.columns.fullname'),
            key: 'fullname',
            render: (record) =>  <Link
                to={`/users/${record.username}/${record.roles[0]}`}
                className="text-blue3 font-medium text-sm underline"
            >
                {record.fullname}
            </Link>
        },
        {
            label: t('usersPage.list.columns.username'),
            key: 'username',
            showForModes: ['desktop']
        },
        {
            label: t('usersPage.list.columns.roles'),
            key: 'roles',
            showForModes: ['tablet', 'desktop'],
            render: (record) => <div className="flex flex-col gap-2.5">
                {userRoleOptions.filter((option) => record.roles?.includes(option.value)).map((option) => (
                    <MTag
                        key={option.value}
                        title={t(`usersPage.search.filters.role.${option.value}`)}
                        extraClassName={option.extraClassName}
                    />)
                )}
            </div>
        },
        {
            label: t('usersPage.list.columns.school_name'),
            key: 'school_name',
            showForModes: ['tablet', 'desktop']
        },
        {
            label: t('usersPage.list.columns.status_id'),
            key: 'status_id',
            render: (record) => {
                const color = record?.status_id === 1
                    ? 'text-green2'
                    : record?.status_id === 3
                        ? 'text-orange1'
                        : 'text-red2'
                return <div className={`flex flex-col gap-2.5 ${color}`}>
                    {t(`usersPage.search.filters.status_id.${record.status_id}`)}
                </div>
            }
        }
    ]

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-orange2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => navigate('/school-admin/create/user')}
        >
            <MIcon
                type="plusCircle"
                className={'text-orange3'}
            />
            {t('usersPage.list.buttons.addUser')}
        </button>
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="px-2 pt-[50px] pb-[82px]">
                <h1 className="text-[28px] font-medium mb-[40px]">{t('usersPage.header')}</h1>
                <div className={'flex justify-between'}>
                    <h2 className={'text-2xl font-semibold m-2'}>{t('usersPage.title')}</h2>
                    {buttons}
                </div>

                <div className="flex flex-col gap-2">
                    <div
                        className="bg-card-bg rounded-xl m-2 p-4 pb-6 flex flex-none flex-col desktop:flex-row tablet:flex-row tablet:flex-wrap w-full">
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5 mb-3">
                            <TableInputFilter
                                name="fullname"
                                placeholder={t('usersPage.search.filters.fullname.placeholder')}
                                tPath={'usersPage.search.filters'}
                                setState={onChangeFilter}
                                state={filters}
                                value={filters}
                                max={90}
                            />
                        </div>
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                            <TableSelectFilter
                                name="role"
                                options={userRoleOptionsForSchoolAdmin}
                                tPath={'usersPage.search.filters'}
                                setState={onChangeFilter}
                                state={filters}
                                allText={t('usersPage.search.filters.role.all')}
                            />
                        </div>
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                            <TableSelectFilter
                                name="publisher_id"
                                options={publisherOptions || []}
                                setState={onChangeFilter}
                                state={filters}
                                allText={'Все пакеты'}
                                label={'Выберите пакет подписки'}
                            />
                        </div>
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                            <TableSelectFilter
                                name="status_id"
                                options={userStatusOptions}
                                tPath={'usersPage.search.filters'}
                                setState={onChangeFilter}
                                state={filters}
                                allText={t('usersPage.search.filters.status_id.all')}
                            />
                        </div>
                        <div className="w-full ml-2.5 mt-6 flex flex-col items-center gap-4">
                            <div className="w-full flex flex-row items-center gap-4">
                                <button
                                    className="flex flex-row h-10 px-6  items-center bg-blue2 border border-blue4 rounded-lg text-sm text-white font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                    onClick={onApplyFilters}
                                    disabled={isFiltersApplied}
                                >
                                    {t('usersPage.search.buttons.apply')}
                                </button>
                                <button
                                    className="flex flex-row h-10 px-6  items-center border border-blue2 rounded-lg text-sm text-main-color font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                    onClick={onResetFilters}
                                    disabled={isDefaultFilter}
                                >
                                    {t('usersPage.search.buttons.reset')}
                                </button>
                            </div>
                            <div
                                className="text-orange1 text-sm mr-auto">{!isFiltersApplied && 'Нажмите кнопку для применения фильтра'}</div>
                        </div>
                    </div>
                    <div className="w-full mx-2 pt-6">
                        <MTable
                            columns={columns}
                            data={users}
                            total={total}
                            currentPage={currentPage}
                            setCurrentPage={onPageClick}
                            foundTitle={t('usersPage.search.found')}
                            buttons={<div>
                                {checked.length ? (
                                    <button
                                        className="btn-secondary-border whitespace-nowrap"
                                        onClick={async () => {
                                            for(const item of checked) {
                                                await handleDelete(item)
                                            }
                                        }}
                                    >
                                        Удалить выбранные
                                    </button>
                                ) : null}

                            </div>}
                            uniqueKey={'username'}
                        />
                    </div>
                </div>
            </div>
        </Spinner>
    );
}

export default SchoolUsers;
