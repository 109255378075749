import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import {userRoleOptions, userRoleOptionsForSchoolAdmin} from '../../models/users';
import MCheckboxGroup from '../../components/common/m-checkbox-group/MCheckboxGroup';
import { useEditUserMutation } from '../../api/UserApi/userEdit';
import {useLazyGetUserViewByAdminQuery} from '../../api/UserApi/userView';
import Spinner from '../../components/common/spinner/spinner';

function UserEdit(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { username, role } = useParams()
    const {t} = useTranslation()
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({})
    const [getUserData] = useLazyGetUserViewByAdminQuery()
    const [editUserData] = useEditUserMutation()
    const [isLoading, setIsLoading] = useState(false)
    const activeRole = useSelector(state=>state.user.activeRole)

    const needSchool = formData.roles?.some(item => ['student', 'teacher', 'school_admin'].includes(item))
    const requiredFields = {
        firstname: t('general.models.errors.general'),
        lastname: t('general.models.errors.general'),
        patronymic: false,
        school_bin: needSchool ? t('general.models.errors.general') : false,
        roles: t('general.models.errors.general')
    }

    useEffect(() => {
        if (!needSchool && errors.school_bin) {
            const { school_bin: keyForClear, ...other } = errors
            setErrors(other)
        }
    }, [needSchool])

    useEffect(() => {
        if (username) {
            getUserInfo()
        }
    }, [username])
  
    const getUserInfo = async () => {
        setIsLoading(true)
        const userData = await getUserData({ username })
        setIsLoading(false)
        const { data, error } = userData
        if (error) {
            // console.error(userData.error);
            dispatch(setIsError(t(`general.models.serverErrors.UView.${error.data?.error_code}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else {
            setFormData(data.user)
        }
    }
    
    const onHandleChange = (limitMax, e, regexTemplate) => {
        const { name, value } = e.target
        if (errors[name]) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        }

        if (value.length <= limitMax) {
            if ((regexTemplate && regexTemplate.test(value)) || !regexTemplate) {
                setFormData({
                    ...formData, [name]: value
                });
            }
        }
    }

    const checkLimitMin = (limitMin, e) => {
        const { name, value } = e.target
        const isEmpty = requiredFields[name] && (!formData[name] || formData[name]?.length === 0)
        if (isEmpty) {
            setErrors({...errors, [name]: t('general.models.errors.general')})
        } else if (value.length < limitMin) {
            setErrors({...errors, [name]: `${t('errors.limitMin')} - ${limitMin}`})
        }
    }

    const checkBIN = (e) => {
        const { name, value } = e.target
        if (/^\d{12,12}$/.test(value)) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        } else {
            setErrors({...errors, [name]: t('errors.checkBIN')})
        }
    }

    const checkBeforeEditUser = () => {
        const invalidFields = Object.keys(requiredFields)?.filter((field) => requiredFields[field] && (!formData[field] || formData[field]?.length === 0))
        if (invalidFields?.length > 0) {
            // dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`)))
            // dispatch(setIsError('Проверьте обязательные поля'))
            const errosObject = invalidFields.reduce((acc, val) => ({ ...acc, [val]: requiredFields[val]}), {})
            setErrors({ ...errors, ...errosObject })
            return false
        }
        if (Object.keys(errors).length > 0) {
            return false
        }
        return true
    }

    const editUserHandler = async () => {
        if (checkBeforeEditUser()) {
            setIsLoading(true)
            const result = await editUserData({ ...formData, username })
            setIsLoading(false)
            if (result.error) {
                dispatch(setIsError(t(`general.models.serverErrors.userEdit.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                navigate(`/users/${username}/${role}`)
            }
        }
    }

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4 mt-4">
        <button
            className="btn-edit"
            onClick={editUserHandler}
        >
            {t('usersPage.userEdit.buttons.saveUser')}
        </button>
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <h1 className="text-3xl m-2">{t('usersPage.header')}</h1>
                <div className="text-2xl m-2 font-semibold mt-4">{t('usersPage.userEdit.title')}</div>
                <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full mb-8">
                    <MProfileGroup title={t('usersPage.userAdd.tabs.user.generalData.title')}>
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.lastname')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            validate={((e) => checkLimitMin(2, e))}
                            record={formData}
                            field="lastname"
                            required={requiredFields.lastname}
                            error={errors.lastname}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.firstname')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            validate={((e) => checkLimitMin(2, e))}
                            record={formData}
                            field="firstname"
                            required={requiredFields.firstname}
                            error={errors.firstname}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.patronymic')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            record={formData}
                            field="patronymic"
                            required={requiredFields.patronymic}
                            error={errors.patronymic}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.school_bin')}
                            setValue={(e) => {onHandleChange(12, e, /^\d{0,12}$/)}}
                            validate={((e) => checkBIN(e))}
                            record={formData}
                            field="school_bin"
                            required={requiredFields.school_bin}
                            disabled={!needSchool}
                            error={errors.school_bin}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.email')}
                            setValue={(e) => {onHandleChange(50, e)}}
                            validate={((e) => checkLimitMin(6, e))}
                            record={formData}
                            field="email"
                            required={requiredFields.email}
                            error={errors.email}
                            disabled={true}
                        />
                        <div className="mt-2">{t('usersPage.userAdd.tabs.user.requiredText')}</div>
                    </MProfileGroup>
                    <MProfileGroup title={t('usersPage.userView.rolesData.title')} error={errors.roles}>
                        <MCheckboxGroup
                            value={formData.roles}
                            setValue={(value) => {
                                setFormData({
                                    ...formData,
                                    roles: value,
                                    school_bin: value?.some(item => ['student', 'teacher', 'school_admin'].includes(item))
                                        ? formData.school_bin
                                        : null
                                })
                                const { roles: keyForClear, ...other } = errors
                                setErrors(other)
                            }}
                            options={(activeRole==='school_admin' ? userRoleOptionsForSchoolAdmin : userRoleOptions)?.map((item) => (
                                {
                                    label: t(`usersPage.search.filters.role.${item.value}`),
                                    value: item.value
                                }
                            ))}
                        />
                    </MProfileGroup>
                    {buttons}
                </div>
            </div>
        </Spinner>
    );
}

export default UserEdit;
