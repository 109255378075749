import MModal from "../../../common/m-modal/MModal";
import React from "react";

const InfoModal = ({
                       isInfoModal,
                       setIsInfoModal,
                       data = new Map(),
                        buttons = null
}) => {
    return (
        <MModal
            active={isInfoModal}
            setActive={setIsInfoModal}
        >
            <div className="flex flex-col w-96 gap-2 px-6 pb-6">
                <h5 className="text-xl font-bold mb-1 tablet:mb-4">
                    {data.get('title')}
                </h5>


                {Array.from(data).filter(item=>item[0]!=='title').map(([key, value]) => (
                    <div key={key}>
                        <p className={'text-gray4 font-medium'}>
                            {key}
                        </p>
                        {Array.isArray(value) ? (
                            value.map(item=> (
                                <p className={'font-medium'}>
                                    {item}
                                </p>
                            ))
                        ) : (
                            <p className={'font-medium'}>
                                {value}
                            </p>
                        )}
                    </div>
                ))}
                {buttons && buttons}
            </div>
        </MModal>
    )
}

export default InfoModal;