import React from 'react';
import { useDispatch } from 'react-redux';
import MIcon from '../m-icon/MIcon';
import {createPortal} from "react-dom";

function MModal({ active, setActive, children, onCloseModal = () => {}, ...props }) {
    const dispatch = useDispatch();

    const onClose = () => {
        onCloseModal();
        setActive(false)
    }

    const modalRoot = document.getElementById('modal-root');

    return active ? createPortal(
        <div
            className={`${!active && 'hidden'} w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] p-2 tablet:p-8  fixed top-0 left-0 flex justify-center items-center z-50`}
        >

            <div
                className="p-4 m-6 tablet:p-8 rounded-xl bg-white relative"
                onClick={e => e.stopPropagation()}
            >
                <button
                    className="absolute top-3 right-3 hover:cursor-pointer text-main-color"
                    onClick={onClose}
                >
                    <MIcon
                        type={'close'}
                        className="text-main-color"
                    />
                </button>
                {children}
            </div>
        </div>, modalRoot
    ) : null;
}

export default MModal;
