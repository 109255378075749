import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {logOut, setCredentials} from '../store/reducers/authSlice';
import { clearUser } from '../store/reducers/userSlice';
import Cookies from 'universal-cookie';
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const refresh_token = JSON.parse(localStorage.getItem('jwt_refresh'));
    const cookies = new Cookies();

    if (result?.error?.status === 401) {
            const username = JSON.parse(localStorage.getItem('user'))?.username;
            const token = api.getState().auth.token
            try {
                const refreshResult = await baseQuery({
                    url: '/public/auth',
                    method: 'POST',
                    body: {username, grant_type: 'refresh_token', refresh_token}
                }, api, extraOptions);

                const newToken = refreshResult.data.access_token;
                const newHeaders = {
                    ...extraOptions?.headers,
                    Authorization: `Bearer ${newToken}`,
                };
                api.dispatch(setCredentials(newToken));
                cookies.set('jwt_auth', newToken, {httpOnly: true});
                localStorage.setItem('jwt_refresh', JSON.stringify(refreshResult.data.refresh_token));
                return await baseQuery(args, api, {...extraOptions, headers: newHeaders});

            } catch (error) {
                if(token) {
                    await baseQuery({
                        url: '/oauth/logout',
                        method: 'POST',
                        body: {access_token: token}
                    }, api, extraOptions);
                }
                api.dispatch(logOut());
                api.dispatch(clearUser());
                localStorage.clear();
                window.history.pushState(null, null, '/');
            }
        }

    if (result?.error?.data?.error_code === 10103) {
        const token = api.getState().auth.token
        if(token) {
            await baseQuery({
                url: '/oauth/logout',
                method: 'POST',
                body: {access_token: token}
            }, api, extraOptions);
        }
        api.dispatch(logOut());
        api.dispatch(clearUser());
        localStorage.clear();
        window.history.pushState(null, null, '/');
    }
    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({})
});
