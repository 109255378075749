import React from 'react';
import CardsCarousel from '../../common/cards-carousel/CardsCarousel';
import { useTranslation } from 'react-i18next';

function Advantages(props) {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col desktop:flex-row items-center desktop:items-start my-20 relative">
            <div className="desktop:w-1/4 flex flex-col justify-center">
                <h5 className="text-main-color text-2xl mb-6 font-medium">
                    {t('mainPage.advantages.advantagesTitle')}
                </h5>
                <p className="mb-6">
                    {t('mainPage.advantages.advantagesDescription')}
                </p>
            </div>
            <div className="w-[310px] tablet:w-[780px] desktop:w-[1024px] mx-auto">
                <CardsCarousel/>
            </div>
        </div>
    );
}

export default Advantages;
