import React from 'react';

function MTextarea({placeholder, value, setValue, type = 'text', error, name, ...props}) {
    return (
                <textarea
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    onChange={setValue}
                    className={`p-3 min-h-[50px] h-[200px] border border-gray-400 w-full rounded-xl m-1 ${error && 'border-2 border-red-700'} ${props.className}`}
                />
    );
}

export default MTextarea;
