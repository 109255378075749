import { createSlice } from '@reduxjs/toolkit';

const notification = createSlice({
    name: 'notification',
    initialState: {
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        successNavigation: '/'
    },
    reducers: {
        setIsError(state, action) {
            state.isError = !state.isError
            state.errorText = action.payload
        },
        setIsSuccess(state, action) {
            state.isSuccess = !state.isSuccess
            state.successText = action.payload.successText
            state.successNavigation = action.payload.successNavigation
        },
    }
})

export default notification.reducer
export const { setIsError, setIsSuccess } = notification.actions;
