import React from 'react';
import { useTranslation } from 'react-i18next';

function BurgerButton({isOpen, setIsOpen, toggleNavbar, ...props}) {
    const {t} = useTranslation();
    return (
        <button
            className="desktop:hidden flex flex-row text-white tablet:text-main-color bg-main-color tablet:bg-white border tablet:border-main-color p-3 rounded-lg  ain-color hover:border hover:border-main-color"
            onClick={toggleNavbar}
        >
            {isOpen
                ? (
                    <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="tablet:hidden">
                            <path d="M6.34277 17.3137L17.6565 6.00001" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.34277 6.34314L17.6565 17.6568" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden tablet:block">
                            <path d="M6.34277 17.3137L17.6565 6.00001" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.34277 6.34314L17.6565 17.6568" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="hidden tablet:block">
                                    {t('general.header.close')}
                                </span>
                    </>
                )
                : (
                    <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="tablet:hidden">
                            <path d="M4 7L20 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 12L20 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 17L20 17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden tablet:block">
                            <path d="M4 7L20 7" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 12L20 12" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 17L20 17" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {t('general.header.menu')}
                    </>
                )
            }
        </button>
    );
}

export default BurgerButton;
