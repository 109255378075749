import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
    name: 'user',
    initialState: {
        firstname: '',
        lastname: '',
        patronymic: '',
        fullname: '',
        roles: [],
        username: '',
        email: '',
        status_id: '',
        contact_phone: '',
        school_name: '',
        parents: [],
        activeRole:'',
        subscriptions: []
    },
    reducers: {
        setName(state,action) {
          state.firstname = action.payload.firstname
          state.lastname = action.payload.lastname
        },
        setActiveRole(state, action) {
            state.activeRole = action.payload
        },
        setUser(state,action) {
            const { firstname, lastname, roles, username, email, status_id, contact_phone, school_name, parents, activeRole, patronymic, fullname, school_bin, school_city, school_id, subscriptions, school_city_name } = action.payload;
            state.firstname = firstname;
            state.lastname = lastname;
            state.roles = roles;
            state.username = username;
            state.patronymic = patronymic
            state.email = email;
            state.status_id = status_id;
            state.contact_phone = contact_phone;
            state.school_name = school_name;
            state.parents = parents;
            state.activeRole = activeRole
            state.fullname = fullname;
            state.school_bin = school_bin
            state.school_city = school_city;
            state.school_id = school_id
            state.subscriptions = subscriptions
            state.school_city_name = school_city_name
        },
        clearUser(state){
            state.firstname = '';
            state.lastname = '';
            state.roles = [];
            state.username = '';
            state.patronymic = '';
            state.fullname = '';
            state.email = '';
            state.status_id = '';
            state.activeRole = ''
            state.contact_phone = '';
            state.school_name = '';
            state.parents = [];
            state.activeRole = '';
            state.school_bin = '';
            state.school_city = '';
            state.school_id = '';
            state.subscriptions = []
            state.school_city_name = ''
        }
    }
})

export default user.reducer

export const {setName, setActiveRole, setUser, clearUser } = user.actions;
