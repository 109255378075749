import React, {forwardRef} from 'react';
import MError from "../m-error/MError";
import MIcon from "../m-icon/MIcon";

const MProfileInput = forwardRef(({ label, record, field, setValue, validate, placeholder, required, disabled = false, error, mode = 'view', iconName, readOnly=false, type='text', ...props }, ref) => {
    const onBlur = (e) => {
        if (validate) {
            validate(e);
        }
    }

    if (mode === 'edit') {
        return (
            <div className='flex flex-col'>
                <div className={'relative tablet:w-[632px] desktop:w-[632px]'}>
                    <input
                        id={props.id}
                        ref={ref}
                        className={`h-[42px] px-4 font-medium text-sm text-black border border-black/[0.3] rounded-lg w-full ${error && '!border-2 !border-red-700'} ${disabled && '!text-black/[0.3]'} ${props.className}`}
                        placeholder={`${placeholder}${required ? ' *' : ''}`}
                        value={record?.[field] || ''}
                        name={field}
                        onChange={setValue}
                        onBlur={onBlur}
                        disabled={disabled}
                        readOnly={readOnly}
                        type={type}
                    />
                    {iconName && (
                        <span onClick={props.onIconClick}>
                            <MIcon type={iconName} className={`absolute right-3 top-[50%] translate-y-[-50%] ${props.classNameIcon}`}/>
                        </span>
                    ) }
                </div>
                {error && error !== true && <MError errorMessage={error}/>}
            </div>
        );
    }

    return (
        <div className='flex gap-2'>
            <div className="font-medium text-sm text-black/[0.5]">{label}</div>
            <div className="font-medium text-sm text-black">{record?.[field]}</div>
        </div>
    );
});

export default MProfileInput;