import MIcon from "../../components/common/m-icon/MIcon";
import {useGetCityListQuery, useGetSchoolsMutation} from '../../api/SchoolsApi/schoolsList';
import {useGetUsersListMutation} from '../../api/UserApi/userList';
import React, {useEffect, useState} from 'react';
import {statisticsData} from '../../models/superadmin';
import {Link, useNavigate} from 'react-router-dom';
import MTable from '../../components/common/m-table/MTable';
import {useTranslation} from 'react-i18next';
import useDeviceType from "../../hooks/useDeviceType";
import {useDispatch, useSelector} from "react-redux";
import {useLazyGetSubsQuery} from "../../api/SubsApi/SubsService";
import MBarChart from "../../components/common/BarChart/BarChart";
import MStatsTable from "../../components/common/m-stats-table/MStatsTable";
import {setIsError} from "../../store/reducers/notificationSlice";
import Spinner from "../../components/common/spinner/spinner";

const SuperAdmin = () => {
    const dispatch = useDispatch();
    const [stats, setStats] = useState(statisticsData);
    const [isLoading, setIsLoading] = useState(false)
    const [schools, setSchools] = useState([])
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [subs, setSubs] = useState({
        active: 0,
        inactive: 0
    })
    const {data:cities} = useGetCityListQuery();
    const {t} = useTranslation()
    const myUsername = useSelector(state => state.user.username)
    const [getSchools] = useGetSchoolsMutation();
    const [getUsers] = useGetUsersListMutation();
    const [getSubs] = useLazyGetSubsQuery();
    const navigate = useNavigate()

    const {isMobile} = useDeviceType();

    const getData = async () => {
        setIsLoading(true)
        const u = await getUsers('');
        if (u.error) {
            //TODO Обработка ошибок
            dispatch(setIsError('UError'))
        } else {
            const s = await getSchools('?limit=10');
            if (s.error) {
                dispatch(setIsError('SError'))
            } else {
                setSchools(s.data?.schools.schools)
                const mySubs = await getSubs(`?username=${myUsername}`)
                if (mySubs.error) {
                    dispatch(setIsError('subsError'))
                } else {
                    setStats({
                        ...stats,
                        count_of_schools: s.data?.schools.count_of_schools,
                        student: u.data?.users.student,
                        teacher: u.data?.users.teacher,
                        private_user: u.data?.users.private_user,
                        school_admin: u.data?.users.school_admin,
                        expert: u.data?.users.expert
                    })
                    setTotal(s.data?.schools?.count_of_schools)
                    setSubs({
                        active: +mySubs.data.subscriptions.total_active_count,
                        inactive: +mySubs.data.subscriptions.total_inactive_count
                    })
                }
            }
        }
        setIsLoading(false)
    }

    const onPageClick = (page) => {
        setCurrentPage(page)
    }

    const columns = isMobile ? [
        {
            label: t('superadminPage.schools.bin'),
            key: 'bin'
        },
        {
            label: t('superadminPage.schools.name'),
            key: 'name'
        }
    ] : [
        {
            label: t('superadminPage.schools.bin'),
            key: 'bin'
        },
        {
            label: t('superadminPage.schools.name'),
            key: 'name'
        },
        {
            label: t('superadminPage.schools.city'),
            key: 'city_id',
            render: (record)=> <span>
                {cities?.cities.find(item=>item.city_id===record.city_id).name}
            </span>
        },
        {
            label: t('superadminPage.schools.admin'),
            key: 'admin'
        }
    ]

    const statsData = [
        {
            text: t('superadminPage.stats.schools'),
            count: stats.count_of_schools
        },
        {
            text: t('superadminPage.stats.pupils'),
            count: stats.student
        },
        {
            text: t('superadminPage.stats.teachers'),
            count: stats.teacher
        },
        {
            text: t('superadminPage.stats.private_users'),
            count: stats.private_user
        },
        {
            text: t('superadminPage.stats.school_admins'),
            count: stats.school_admin
        }
    ]

    useEffect(() => {
        getData();
    }, []);

    return (
        <Spinner spinning={isLoading}>
            <div className={'pt-[52px] mb-[108px] px-5'}>
                <h1 className={'text-[28px] font-medium mb-8'}>{t('superadminPage.title')}</h1>
                <div>
                    <MBarChart subs={subs}/>
                </div>

                <div className={'flex gap-5 flex-col desktop:flex-row'}>
                    <MStatsTable data={statsData} title={t('superadminPage.stats.title')}/>
                    <div className={'bg-gray2 flex-1 px-6 pt-9 pb-4 rounded-lg flex flex-col'}>
                        <p className={'text-xl font-bold mb-6'}>{t('superadminPage.schools.title')}</p>
                        <MTable
                            columns={columns}
                            data={schools}
                            total={total}
                            trimAmount={25}
                            currentPage={currentPage}
                            setCurrentPage={onPageClick}
                            foundTitle={t('schoolPage.search.found')}
                            buttons={<div></div>}
                            uniqueKey={'school_id'}
                        />
                        <div className={'flex items-center justify-between font-medium'}>
                            <button className={'btn-primary bg-blue2 border-none '}
                                    onClick={() => navigate('/create/school')}>{t('superadminPage.schools.add')}</button>
                            <Link to={'/schools'} className={'btn-secondary font-normal flex items-center gap-2'}
                                  disabled>
                                {t('superadminPage.more')}
                                <MIcon type={'arrowRight'} className={'text-disabled-text'}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Spinner>
    )
}
export default SuperAdmin;
