import React from 'react';
import PackageItemBooks from './PackageItemBooks';
import {useTranslation} from "react-i18next";

function PackageItem({publisher, ...props}) {
    const {t} = useTranslation();
    return (
        <div>
            <h3 className="text-2xl mt-4">{t('currentPackagePage.howToUse.title')}</h3>
            <p className="mt-4">{t('currentPackagePage.howToUse.1')}</p>
            <p className="mt-4">{t('currentPackagePage.howToUse.2')}</p>
            <p className="mt-4">{t('currentPackagePage.howToUse.3')}</p>
            <p className="mt-4">{t('currentPackagePage.howToUse.4')}</p>
            <h3 className="text-2xl mt-4">{t('currentPackagePage.howToUse.whatBooks')}</h3>
            <p className="mt-4">{t('currentPackagePage.howToUse.totalBooks')}</p>
            <PackageItemBooks/>
        </div>
    );
}

export default PackageItem;
