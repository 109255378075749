import React, { useEffect, useState } from 'react';
import {useGetCityListQuery, useGetSchoolsMutation} from '../../api/SchoolsApi/schoolsList';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import TableInputFilter from '../../components/common/m-table/TableInputFilter';
import TableSelectFilter from '../../components/common/m-table/TableSelectFilter';
import MTable from '../../components/common/m-table/MTable';
import { schoolsCityOptions } from '../../models/superadmin';
import MIcon from '../../components/common/m-icon/MIcon';
import { Link, useNavigate } from 'react-router-dom';
import { setIsError } from '../../store/reducers/notificationSlice';
import { dropFilter, saveFilter } from '../../store/reducers/filterSlice';
import Spinner from '../../components/common/spinner/spinner';
import InfoModal from "../../components/layout/navbar/modals/InfoModal";
import useDeviceType from "../../hooks/useDeviceType";

export const SCHOOLS_VIEW_FILTER_NAME = 'SchoolsView'
const SCHOOLS_PAGE_SIZE = 10
const DEFAULT_FILTER = { limit: SCHOOLS_PAGE_SIZE }

function Schools(props) {
    const [schools, setSchools] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [currentSchool, setCurrentSchool] = useState(new Map())
    const savedFilters = useSelector(state => state.filter.savedFilters[SCHOOLS_VIEW_FILTER_NAME]) || DEFAULT_FILTER
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const isDefaultFilter = isEqual(filters, DEFAULT_FILTER)
    const isFiltersApplied = isEqual(filters, savedFilters)
    const {data:cities} = useGetCityListQuery();
    const cityOptions =cities?.cities.map(city=>({name:city.name, value:city.city_id})) || []

    const {isMobile} = useDeviceType();

    const [getSchools] = useGetSchoolsMutation();

    useEffect(() => {
        onSearch()
        setFilters(savedFilters || DEFAULT_FILTER)
    }, [savedFilters])

    const reformatParams = (data)  => {
        let filterStr = '';
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (value) {
                    if (filterStr === '') {
                        filterStr = `?${key}=${value}`
                    } else {
                        filterStr = filterStr + `&${key}=${value}`
                    }
                }
            }
        }
        return filterStr;
    }

    const onApplyFilters = async (sub) => {
        setCurrentPage(1)
        dispatch(saveFilter({
            filterName: SCHOOLS_VIEW_FILTER_NAME,
            filterValue: { ...filters, offset: 0 }
        }))
    }

    const onResetFilters = () => {
        setFilters(DEFAULT_FILTER)
        dispatch(dropFilter({ filterName: SCHOOLS_VIEW_FILTER_NAME }))
    }

    const onPageClick = (page) => {
        setCurrentPage(page)
        dispatch(saveFilter({
            filterName: SCHOOLS_VIEW_FILTER_NAME,
            filterValue: { ...savedFilters, offset: (page - 1) * SCHOOLS_PAGE_SIZE }
        }))
    }

    const onChangeFilter = (newState) => {
        setFilters(newState)
    }

    const onSearch = async (sub) => {
        const params = reformatParams(savedFilters)
        setIsLoading(true)
        const { data, error } = await getSchools(params)
        setIsLoading(false)
        if(error){
            dispatch(setIsError(t(`general.models.serverErrors.common.${error.status}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else if (data && data.schools?.schools) {
            setSchools(data.schools?.schools)
            setTotal(data.schools?.count_of_schools)
        }
    }

    const trimString = (str) => {
        if (str.length > 25) {
            return str.slice(0, 25) + '...'
        } else {
            return str
        }
    }
    const columns = [
        {
            label: 'БИН',
            key: 'bin',
            render: (record) => (
                isMobile ? (
                    <span
                        className="text-blue3 font-medium text-sm underline cursor-pointer"
                        onClick={() => {
                            const currentSchool = schools.find(item => item.school_id === record.school_id)
                            setIsOpen(true)
                            setCurrentSchool(new Map()
                                .set("Город", currentSchool.city)
                                .set("Название школы", currentSchool.name)
                                .set("Адрес", currentSchool.address)
                                .set("Кол-во польз.", currentSchool.users_count)
                                .set('title', currentSchool.bin)
                            )
                        }
                        }
                    >
                {record.bin}
            </span>
                ) : record.bin
            )
        },
        {
            label: 'Название',
            key: 'name',
            showForModes: ['desktop', 'tablet'],
            render: (school) => <Link
                to={`/schools/${school.school_id}`}
                className="text-blue3 font-medium text-sm underline"
            >
                {trimString(school.name)}
            </Link>
        },
        {
            label: 'Город',
            key: 'city_id',
            render: (record) => (
                <span>{cities.cities.find(item=>item.city_id===record.city_id).name}</span>
            )
        },
        {
            label: 'Адрес',
            key: 'address',
            showForModes: ['tablet', 'desktop']
        },
        {
            label: 'Кол-во пользователей',
            key: 'users_count',
            showForModes: ['tablet', 'desktop']
        },

    ]

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-orange2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => navigate('/create/school')}
        >
            <MIcon
                type="plusCircle"
                className={'text-orange3'}
            />
            {t('schoolPage.list.buttons.addSchool')}
        </button>
        {/*<button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center h-10 gap-4 px-4 items-center bg-blue3 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => {}}
        >
            <MIcon
                type="xMarkCircle"
                className={'text-blue3'}
            />
            {t('schoolPage.list.buttons.deleteSchools')}
        </button>*/}
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <h1 className="text-3xl m-2">{t('schoolPage.header')}</h1>
                <h2 className="text-2xl mx-2 my-4">{t('schoolPage.search.title')}</h2>
                <div className="flex flex-col gap-2">
                    <div className="bg-card-bg rounded-xl m-2 p-4 pb-6 flex flex-none flex-col desktop:flex-row tablet:flex-row tablet:flex-wrap w-full">
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5 mb-3">
                            <TableInputFilter
                                name="bin"
                                placeholder={t('schoolPage.search.filters.bin.placeholder')}
                                tPath={'schoolPage.search.filters'}
                                setState={onChangeFilter}
                                regexTemplate={/^\d{0,12}$/}
                                state={filters}
                            />
                        </div>
                        <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5" >
                            <TableSelectFilter
                                name="city_id"
                                options={cityOptions}
                                setState={onChangeFilter}
                                state={filters}
                                allText={'Все города'}
                                label={'Выберите город'}
                            />
                        </div>
                        <div className="w-full ml-2.5 mt-6 flex flex-col items-center gap-4">
                            <div className="w-full flex flex-row items-center gap-4">
                                <button
                                    className="flex flex-row h-10 px-6  items-center bg-blue2 border border-blue4 rounded-lg text-sm text-white font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                    onClick={onApplyFilters}
                                    disabled={isFiltersApplied}
                                >
                                    {t('schoolPage.search.buttons.apply')}
                                </button>
                                <button
                                    className="flex flex-row h-10 px-6  items-center border border-blue2 rounded-lg text-sm text-main-color font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                    onClick={onResetFilters}
                                    disabled={isDefaultFilter}
                                >
                                    {t('schoolPage.search.buttons.reset')}
                                </button>
                            </div>
                            <div className="text-orange1 text-sm mr-auto">{!isFiltersApplied && 'Нажмите кнопку для применения фильтра'}</div>
                        </div>
                    </div>
                    <div className="w-full m-2 py-4">
                        <MTable
                            columns={columns}
                            data={schools}
                            total={total}
                            currentPage={currentPage}
                            trimAmount={25}
                            setCurrentPage={onPageClick}
                            foundTitle={t('schoolPage.search.found')}
                            buttons={buttons}
                            uniqueKey={'school_id'}
                        />
                    </div>
                </div>
            </div>
            <InfoModal isInfoModal={isOpen} setIsInfoModal={setIsOpen} data={currentSchool}/>
        </Spinner>
    );
}

export default Schools;
