import React from 'react';
import mektep from '../../assets/img/mektep.png'
import atamura from '../../assets/img/atamura.png'
import kokzhiek from '../../assets/img/kokzhiek.png'
import PackageCard from '../../components/package/PackageCard';
import { useTranslation } from 'react-i18next';

function Packages(props) {
    const { t } = useTranslation();

    return (
        <div className="px-2 flex flex-col desktop:flex-row mt-6">
            <div className="desktop:w-1/4">
                <h1 className="text-3xl m-2">{t('packagePage.title')}</h1>
                <div className="mb-6 mx-2">
                    {t('packagePage.note')}
                </div>
            </div>
            <div className="flex flex-col tablet:flex-row tablet:justify-around pb-10 justify-center items-center desktop:w-3/4">
                <PackageCard img={mektep} src="/package" text={t('packagePage.package1')}/>
                <PackageCard img={atamura} src="/package" text={t('packagePage.package2')}/>
            </div>
        </div>
    );
}

export default Packages;
