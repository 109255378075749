import React, {useEffect, useState} from "react";
import UserAdded from "./UserAdded";
import {useNavigate} from "react-router-dom";

const UserList = ({students, order}) => {
    const [count, setCount] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [addedUsers, setAddedUsers] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [shadowCount, setShadowCount] = useState(0);
    const navigate = useNavigate();
    const returnButton = () => {
        navigate('/school-admin/users')
    }
    useEffect(() => {
        setIsUsersLoading(true)
        if (currentIndex < students.length) {
            setAddedUsers(prev => [
                ...prev,
                <UserAdded
                    key={currentIndex}
                    index={currentIndex}
                    firstname={students[currentIndex][order.indexOf('firstname')]}
                    lastname={students[currentIndex][order.indexOf('lastname')]}
                    username={students[currentIndex][order.indexOf('email')]}
                    setShadowCount={setShadowCount}
                    setCount={setCount}
                    setIsError={setIsError}
                />
            ]);
            setCurrentIndex(prev => prev + 1);
        }
    }, [count, students, currentIndex, order]);

    useEffect(() => {
        if(shadowCount===students.length) {
            setIsUsersLoading(false)
        }
    }, [shadowCount, students.length]);

    return (
        <>
            <h3 className={'font-bold text-[24px] mb-6 max-w-[267px] tablet:max-w-full'}>Пользователи добавлены!</h3>

            {!!count && (
                <div className={'div-rule mb-6 transition-all'}>
                     На указанные e-mail пользователей отправлены письма о подтверждении регистрации.
                </div>
            )}

            {isError && (
                <div className={'div-rule-error mb-6'}>
                    Имеются ошибки. Описание ошибки указано в строке соответствующего пользователя.
                </div>
            )}

            <h4 className={'font-bold mb-6'}>Добавлены {count}/{students.length} пользователя</h4>

            <div className={'max-h-[300px] overflow-y-scroll pr-2 tablet:pr-12 mb-8'}>
                {addedUsers}
            </div>

            <div className={'flex justify-end'}>
                <button
                    className={`btn-primary-library-school_admin px-6 py-3`}
                    onClick={returnButton}
                    disabled={isUsersLoading}
                >
                    Готово
                </button>
            </div>
        </>
    );
};

export default UserList