import React from 'react';
import {NavLink} from 'react-router-dom';

function NavButton({children, to, ...props}) {
    return (
        <NavLink to={to} {...props}>
            {children}
        </NavLink>
    );
}

export default NavButton;
