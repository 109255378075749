import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisterUserAdminMutation } from '../../api/AuthApi/authService';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import {userRoleOptionsForSchoolAdmin} from '../../models/users';
import MCheckboxGroup from '../../components/common/m-checkbox-group/MCheckboxGroup';
import Spinner from '../../components/common/spinner/spinner';
import {authRegex} from "../../models/auth";
import MIcon from "../../components/common/m-icon/MIcon";
import ImportStudentsModal from "./modals/ImportStudentsModal";

function UserAddSchoolAdmin(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({})
    const [registerUserAdmin] = useRegisterUserAdminMutation()
    const [isLoading, setIsLoading] = useState(false)
    const school_bin = useSelector(state=>state.user.school_bin)
    const [isOpen, setIsOpen] = useState(false)

    const requiredFields = {
        firstname: t('general.models.errors.general'),
        lastname: t('general.models.errors.general'),
        patronymic: false,
        email: t('general.models.errors.general'),
        role: t('errors.checkRole')
    }

    const onHandleChange = (limitMax, e, regexTemplate) => {
        const { name, value } = e.target
        if (errors[name]) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        }

        if (value.length <= limitMax) {
            if ((regexTemplate && regexTemplate.test(value)) || !regexTemplate) {
                setFormData({
                    ...formData, [name]: value
                });
            }
        }
    }

    const checkLimitMin = (limitMin, e) => {
        const { name, value } = e.target
        const isEmpty = requiredFields[name] && (!formData[name] || formData[name]?.length === 0)
        if (isEmpty) {
            setErrors({...errors, [name]: t('general.models.errors.general')})
        } else if (value.length < limitMin) {
            setErrors({...errors, [name]: `${t('errors.limitMin')} - ${limitMin}`})
        }
    }

    const checkEmail = (e) => {
        const { name, value } = e.target
        if (authRegex.email.test(value)) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        } else {
            setErrors({...errors, [name]: t('general.models.errors.invalidEmail')})
        }
    }

    const checkBeforeAddUser = () => {
        const invalidFields = Object.keys(requiredFields)?.filter((field) => requiredFields[field] && (!formData[field] || formData[field]?.length === 0))
        if (invalidFields?.length > 0) {
            // dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`)))
            // dispatch(setIsError('Проверьте обязательные поля'))
            const errosObject = invalidFields.reduce((acc, val) => ({ ...acc, [val]: requiredFields[val]}), {})
            setErrors({ ...errors, ...errosObject })
            return false
        }
        if (Object.keys(errors).length > 0) {
            return false
        }
        return true
    }

    const addUserHandler = async () => {
        if (checkBeforeAddUser()) {
            setIsLoading(true)
            const result = await registerUserAdmin({...formData, school_bin: school_bin})
            setIsLoading(false)
            if (result.error) {
                dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                navigate('/users')
            }
        }
    }

    const buttons = <div
        className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4 mt-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={addUserHandler}
        >
            {t('usersPage.userAdd.tabs.user.buttons.addUser')}
        </button>
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-orange2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => setIsOpen(true)}
        >
            <MIcon
                type="plusCircle"
                className={'text-orange3'}
            />
            Импорт учеников
        </button>
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full mb-8">
                    <MProfileGroup title={t('usersPage.userAdd.tabs.user.generalData.title')}>
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.lastname')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            validate={((e) => checkLimitMin(2, e))}
                            record={formData}
                            field="lastname"
                            required={requiredFields.lastname}
                            error={errors.lastname}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.firstname')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            validate={((e) => checkLimitMin(2, e))}
                            record={formData}
                            field="firstname"
                            required={requiredFields.firstname}
                            error={errors.firstname}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.patronymic')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            record={formData}
                            field="patronymic"
                            required={requiredFields.patronymic}
                            error={errors.patronymic}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.email')}
                            setValue={(e) => {onHandleChange(50, e)}}
                            validate={(e) => checkEmail(e)}
                            record={formData}
                            field="email"
                            required={requiredFields.email}
                            error={errors.email}
                        />
                        <div className="mt-2">{t('usersPage.userAdd.tabs.user.requiredText')}</div>
                    </MProfileGroup>
                    <MProfileGroup title={t('usersPage.userView.rolesData.title')} error={errors.role}>
                        <MCheckboxGroup
                            value={formData.role}
                            setValue={(value) => {
                                setFormData({
                                    ...formData,
                                    role: value,
                                    school_bin: value?.some(item => ['student', 'teacher', 'school_admin'].includes(item))
                                        ? formData.school_bin
                                        : null
                                })
                                const { role: keyForClear, ...other } = errors
                                setErrors(other)
                            }}
                            options={userRoleOptionsForSchoolAdmin?.map((item) => (
                                {
                                    label: t(`usersPage.search.filters.role.${item.value}`),
                                    value: item.value
                                }
                            ))}
                        />
                    </MProfileGroup>
                    {buttons}
                </div>
            </div>
            <ImportStudentsModal isOpen={isOpen} setIsOpen={setIsOpen}/>
        </Spinner>
    );
}

export default UserAddSchoolAdmin;
