import React, { useEffect, useState } from 'react';
import SearchInput from '../../components/search/SearchInput';
import MCollapse from '../../components/common/m-collapse/MCollapse';
import MCheckbox from '../../components/common/m-checkbox/MCheckbox';
import SearchItem from '../../components/search/SearchItem';
import { useTranslation } from 'react-i18next';
import { useGetListMutation } from '../../api/LibraryApi/LibraryService';
import {
    gradeOptions,
    initialSearchFilters,
    subjectsOptions,
} from '../../models/library';
import {setIsError} from "../../store/reducers/notificationSlice";
import {saveFilter} from "../../store/reducers/filterSlice";
import {useDispatch, useSelector} from "react-redux";

export const SEARCH_VIEW_FILTER_NAME = 'SearchView'

function Search(props) {
    const [books, setBooks] = useState([]);
    const savedFilters = useSelector(state => state.filter.savedFilters[SEARCH_VIEW_FILTER_NAME])
    const [filters, setFilters] = useState(savedFilters || initialSearchFilters);
    const [grades, setGrades] = useState(gradeOptions);
    const [subjects, setSubjects] = useState(subjectsOptions);
    const [firstTime, setFirstTime] = useState(!savedFilters)
    const dispatch = useDispatch()

    useEffect(() => {
        if (savedFilters) {
            onSearch()
        } else {
            setFilters(initialSearchFilters)
            setFirstTime(true)
            setBooks([])
        }
    }, [savedFilters])

    const { t } = useTranslation();

    const [ getList ] = useGetListMutation()

    const reformatParams = (data)  => {
        let filterStr = '?';
        for (const [key, value] of Object.entries(data)) {
            if (value) {
                if (key === 'grade' || key === 'subject') {
                    data[key].forEach(val => {
                        filterStr = filterStr + `&${key}=${val}`
                    })
                } else {
                    if (filterStr === '?') {
                        filterStr = filterStr + `${key}=${value}`
                    } else {
                        filterStr = filterStr + `&${key}=${value}`
                    }
                }
            }
        }
        return filterStr;
    }

    const saveFiltersForSearh = async (subject) => {
        const filtersWithSelectedSubject = subject ? {...filters, subject: [ subject] } : filters
        if (subject) {
            setFilters({...filters, subject: [ subject ]})
        }
        firstTime && setFirstTime(false);
        dispatch(saveFilter({ filterName: SEARCH_VIEW_FILTER_NAME, filterValue: filtersWithSelectedSubject }))
    }

    const onSearch = async (subject) => {
        const params = reformatParams(savedFilters)
        const { data, error } = await getList(params);
        if (data && data.books) {
            setBooks(data.books)
        }
        if(error){
            dispatch(setIsError(t(`general.models.serverErrors.library.${error.status}`, `${error.data?.error_code}: ${error.data?.message}`)))
        }
    }

    const setFilter = (value) => {
        setFilters({...filters, input_text: value});
    }

    return (
        <div className="tablet:pr-6">
            <SearchInput
                setSearch={saveFiltersForSearh}
                onChange={setFilter}
                value={filters.input_text}
            />
            {
                !firstTime
                    ? (
                        <>
                            <div className="w-full p-6 tablet:hidden">
                                <div className="border border-[#0000001A] rounded-lg p-4">
                                    <MCollapse title={t('searchPage.result.Class')}>
                                        {grades.map(grade => {
                                            const gradeChecked = filters.grade?.includes(grade.value)
                                            return (
                                                <MCheckbox
                                                    label={t(`libraryPage.filters.grade.${grade.value}`)}
                                                    keyValue={grade.value}
                                                    value={gradeChecked}
                                                    setValue={() => {
                                                        setFilters({...filters, grade: !gradeChecked ? [...filters.grade, grade.value] : filters.grade.filter(item => item !== grade.value)});
                                                    }}
                                                />
                                            )
                                        })
                                        }
                                    </MCollapse>
                                    <MCollapse title={t('searchPage.result.subject')}>
                                        {
                                            subjects.sort(
                                                (a, b) => {
                                                    let nameA = t(`libraryPage.filters.subject.${a.value}`)
                                                    let nameB = t(`libraryPage.filters.subject.${b.value}`)
                                                    return nameA.localeCompare(nameB);
                                                }).map(subject => {
                                                    const subjectChecked = filters.subject?.includes(subject.value)
                                                    return <MCheckbox
                                                        label={t(`libraryPage.filters.subject.${subject.value}`)}
                                                        keyValue={subject.value}
                                                        value={subjectChecked}
                                                        setValue={() => {
                                                            setFilters({...filters, subject: !subjectChecked ? [...filters.subject, subject.value] : filters.subject.filter(item => item !== subject.value)});
                                                        }}
                                                    />
                                                })
                                        }
                                    </MCollapse>
                                </div>
                                <div className="my-4">
                                    <h3 className="text-2xl mb-4">{t('searchPage.result.found')} {books.length}</h3>
                                    <ol>
                                        {books.map((item, index) => {
                                            return (
                                                <SearchItem
                                                    key={item.name}
                                                    index={index + 1}
                                                    title={item.name}
                                                    publishingHouse={item.publisher}
                                                    grade={item.grade}
                                                    subject={item.subject}
                                                    id={item.book_id}
                                                    url={item.url}
                                                />
                                            )
                                        })}
                                    </ol>
                                </div>
                            </div>
                            <div className="w-full p-6 hidden tablet:flex tablet:flex-col desktop:flex-row">
                                <div className="border border-[#0000001A] rounded-lg p-4 flex flex-col tablet:w-full desktop:w-1/3 desktop:order-2">
                                    <div>
                                        <h3 className="font-bold py-2 ">{t('searchPage.result.Class')}</h3>
                                        <div className="flex flex-wrap">
                                            {grades.map(grade => {
                                                const gradeChecked = filters.grade?.includes(grade.value)
                                                return (
                                                    <MCheckbox
                                                        label={t(`libraryPage.filters.grade.${grade.value}`)}
                                                        keyValue={grade.value}
                                                        value={gradeChecked}
                                                        setValue={() => {
                                                            setFilters({...filters, grade: !gradeChecked ? [...filters.grade, grade.value] : filters.grade.filter(item => item !== grade.value)});
                                                        }}
                                                    />
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="font-bold py-2">{t('searchPage.result.subject')}</h3>
                                        <div className="flex flex-wrap">
                                            { subjects.sort(
                                                (a, b) => {
                                                    let nameA = t(`libraryPage.filters.subject.${a.value}`)
                                                    let nameB = t(`libraryPage.filters.subject.${b.value}`)
                                                    return nameA.localeCompare(nameB);
                                                }).map(subject => {
                                                    const subjectChecked = filters.subject?.includes(subject.value)
                                                    return <MCheckbox
                                                        label={t(`libraryPage.filters.subject.${subject.value}`)}
                                                        keyValue={subject.value}
                                                        value={subjectChecked}
                                                        setValue={() => {
                                                            setFilters({...filters, subject: !subjectChecked ? [...filters.subject, subject.value] : filters.subject.filter(item => item !== subject.value)});
                                                        }}
                                                    />
                                                }
                                               )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 desktop:w-2/3 desktop:order-1">
                                    <h3 className="text-2xl mb-4">{t('searchPage.result.found')}: {books.length}</h3>
                                    <ol>
                                        {books.map((item, index) => {
                                            return (
                                                <SearchItem
                                                    key={item.value}
                                                    index={index + 1}
                                                    title={item.name}
                                                    publishingHouse={item.publisher}
                                                    grade={item.grade}
                                                    subject={item.subject}
                                                    id={item.book_id}
                                                    url={item.url}
                                                />
                                            )
                                        })}
                                    </ol>
                                </div>
                            </div>
                        </>
                    )
                    : (
                        <div className="m-6">
                            <h2 className="text-2xl my-4">{t('searchPage.popularRequests.title')}</h2>
                            <div className="flex flex-row flex-wrap">
                                {subjectsOptions.sort(
                                    (a, b) => {
                                        let nameA = t(`libraryPage.filters.subject.${a.value}`)
                                        let nameB = t(`libraryPage.filters.subject.${b.value}`)
                                        return nameA.localeCompare(nameB);
                                    }).map(item => {
                                    if(!item.dontShow) {
                                        return (<div
                                                key={item.value}
                                                className="btn-secondary-border m-1"
                                                onClick={() => {
                                                    saveFiltersForSearh(item.value)
                                                }}
                                            >
                                                {t(`libraryPage.filters.subject.${item.value}`)}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )
            }

        </div>
    );
}

export default Search;
