import React from 'react';

import { useTranslation } from 'react-i18next';
import MIcon from '../../../common/m-icon/MIcon';

function SocialMedia(props) {
    const {t} = useTranslation();
    return (
        <div className="desktop:w-1/2 text-footer-text text-sm flex flex-col mb-4 order-3 desktop:order-4 mx-2">
            <p className="font-bold mb-2">{t('general.footer.socialMedia')}</p>
            <div className="flex flex-row">
                <a href="https://www.facebook.com/profile.php?id=61564933192780&mibextid=LQQJ4d" className="social-media-btn">
                    <MIcon
                        type={'facebook'}
                        className={'text-white cursor-pointer'}
                    />
                </a>
                <a href="https://www.instagram.com/oqulyqtar2024/" className="social-media-btn">
                    <MIcon
                        type={'instagram'}
                        className={'text-white cursor-pointer'}
                    />
                </a>
                <a href="https://youtube.com/@oqulyqtar?si=m799mYnBx6nmiebH" className="social-media-btn flex items-center">
                    <MIcon
                        type={'youtube'}
                        className={'text-white cursor-pointer'}
                    />
                </a>
                <a href="https://www.tiktok.com/@oqulyqtar?_t=8p3L8pGJuEw&_r=1" className="social-media-btn">
                    <MIcon
                        type={'tiktok'}
                        className={'text-white cursor-pointer'}
                    />
                </a>
            </div>
        </div>
    );
}

export default SocialMedia;
