import {useTranslation} from "react-i18next";
import MPagination from "../m-pagination/MPagination";
import Spinner from "../spinner/spinner";

const DEFAULT_PAGE_SIZE = 10

function MTable({columns, data, pageSize = DEFAULT_PAGE_SIZE, total = 0, currentPage, setCurrentPage, foundTitle, buttons, trimAmount, loading = false, uniqueKey, withTotal= true, title, rowsClassName }) {
    const {t} = useTranslation();
    const found = foundTitle || t('general.table.found')

    const getScreenModeClasses = (column) => {
        if (column.showForModes) {
            const showModesClasses = column.showForModes?.map((mode) => `${mode}:table-cell`)?.join(' ')
            return `hidden ${showModesClasses}`
        }
        return ''
    }

    return (
        <Spinner spinning={loading}>
            <div className="p-2 w-full border-collapse">
                {withTotal && <div className="text-base font-bold mb-4">{`${found}: ${total}`}</div>}
                {title && <h3 className={'font-bold text-[18px]'}>{title}</h3>}
                <table className="w-full my-2 font-medium">
                    <thead className={`text-gray4 text-sm border-b border-b-gray3  ${pageSize===Infinity && 'table w-full table-fixed'}`}>
                        <tr className={'space-x-4'}>
                            {columns?.map((col) => {
                                return <td key={col.key+col.label} className={`py-2 pr-3 space-x-4 ${getScreenModeClasses(col)}`}>
                                    {col.label}
                                </td>
                            })}
                        </tr>
                    </thead>
                    <tbody className={`${pageSize===Infinity && 'h-[200px] overflow-y-auto block  table-fixed w-full'}`}>
                        {data?.map((record, index) => (
                            <tr className={`border-b border-b-gray3 space-x-4 w-full ${rowsClassName} ${pageSize===Infinity && 'table table-fixed'}`} key={record[uniqueKey] || index}>
                                {columns?.map((col) => {
                                    return <td key={col.label+col.key} className={`py-3 px-2 ${getScreenModeClasses(col)} overflow-hidden whitespace-nowrap text-ellipsis max-w-[200px] truncate`}>
                                            {/*{col.render ? col.render(record) : trimString(record[col.key])}*/}
                                             {col.render ? col.render(record) : record[col.key]}
                                    </td>
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-col tablet:flex-row desktop:flex-row items-center w-full justify-between gap-8 mt-8">
                    {buttons}
                    {total > 0 && (
                        <MPagination
                            totalCount={total}
                            pageSize={pageSize}
                            setCurrent={setCurrentPage}
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </Spinner>
    );
}

export default MTable;
