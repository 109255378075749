import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MModal from '../../../common/m-modal/MModal';

import {setUser} from '../../../../store/reducers/userSlice';
import { userRoleOptions } from '../../../../models/users';
import {useNavigate} from "react-router-dom";
import {useLazyGetUserViewQuery} from "../../../../api/UserApi/userView";
import {setIsAuth} from "../../../../store/reducers/authSlice";

const  ChooseRoleModal = ({
    isChooseRoleModal,
    setIsChooseRoleModal,
    isAuth = true
}) => {

    const {t} = useTranslation();
    const roles = useSelector(state=>state.user.roles)
    const username = useSelector(state=>state.user.username)
    const [getUserInfo] = useLazyGetUserViewQuery()
    const dispatch=useDispatch()
    const navigate = useNavigate();
    const handleRoleSelect = async (option) => {
        const user = await getUserInfo({ username, role: option.value });
        if(user.data.user){
            dispatch(setUser({...user.data.user, activeRole: option.value}));
            localStorage.setItem('user',JSON.stringify({...user.data.user, activeRole: option.value}))
            if(!isAuth) {
                dispatch(setIsAuth(true))
            }
        }
        setIsChooseRoleModal(false)
        navigate('/')
    }
    return (
        <MModal
            active={isChooseRoleModal}
            setActive={setIsChooseRoleModal}
        >
            <div className="flex flex-col w-96">
                <h5 className="text-xl font-semibold mx-auto my-3 mb-8">
                    {t('general.header.chooseRoleModal.title')}
                </h5>
                {userRoleOptions.filter((option) => roles?.includes(option.value))?.map(option => (
                    <p key={option.value}
                       className="font-medium text-base text-center border-solid border-2 mb-4 rounded-lg p-2 cursor-pointer"
                       onClick={()=>handleRoleSelect(option)}
                    >
                        {t(`general.header.chooseRoleModal.roles.${option.value}`)}
                    </p>
                ))}
                <p
                    className="font-medium text-sm text-center text-main-color mt-6 cursor-pointer"
                    onClick={()=>setIsChooseRoleModal(false)}
                >
                    {t('general.header.chooseRoleModal.cancel')}
                </p>
            </div>
        </MModal>
    );
};
export default ChooseRoleModal;