import React, { useState } from 'react';
import searchIcon from '../../assets/icons/searchWhite.png';
import MSelect from '../common/m-select/MSelect';
import close from '../../assets/icons/close.png'
import greenFlag from '../../assets/icons/green_flag.png'
import geography7mob from '../../assets/img/geography7mob.png'
import geography7desk from '../../assets/img/geography7desk.png'

function TeacherMain(props) {
    const find = () => {};
    const [workType, setWorkType] = useState('sent');
    const mockOptions = [
        {
            name: 'все',
            value: 'all'
        },
        {
            name: '1',
            value: '1'
        },
        {
            name: '2',
            value: '2'
        },
        {
            name: '3',
            value: '3'
        },
    ]

    return (
        <div className="flex flex-col">
            <div className="flex flex-col desktop:flex-row">
                <div className="flex flex-col my-4 w-full desktop:order-2 desktop:w-1/2">
                    <div className="flex flex-row mb-1">
                        <div className="w-full mr-1 rounded-lg border border-gray-400">
                            <input className="p-2 w-full rounded-lg active:border active:border-main-color h-full" placeholder="Введите слово для поиска" id="search"/>
                        </div>
                        <button className="btn-primary flex flex-row" onClick={find}>
                            <img src={searchIcon} alt="search"/>
                            <span className="hidden tablet:block mr-4 ml-2">Найти</span>
                        </button>
                    </div>
                    <div className="flex flex-row">
                        <span className="font-bold mr-2">Искать</span>
                        <input type="radio" className="mx-2"/>в параграфах
                        <input type="radio" className="mx-2"/>в заданиях
                    </div>
                </div>
                <h1 className="text-3xl my-2 desktop:order-1 desktop:w-1/2">Главная</h1>
            </div>
            <div className="desktop:flex desktop:flex-row">
                <div className="desktop:w-1/2">
                    <MSelect label="Работы" options={mockOptions} border={true} className="tablet:hidden my-4"/>
                    <div className={`hidden tablet:flex tablet:flex-row`}>
                        <div
                            className={`p-4 hover:cursor-pointer ${workType === 'sent' ? 'border border-gray-400 border-b-white rounded-t-xl' : 'text-main-color border border-white border-b-gray-400'}`}
                            onClick={() => setWorkType('sent')}
                        >Отправленные
                        </div>
                        <div
                            className={`p-4 hover:cursor-pointer ${workType === 'saved' ? 'border border-gray-400 border-b-white rounded-t-xl' : 'text-main-color border border-white border-b-gray-400'}`}
                            onClick={() => setWorkType('saved')}
                        >Сохраненные
                        </div>
                        <div  className={`border border-white border-b-gray-400 w-full`}></div>
                    </div>
                    <table className="my-4 w-full">
                        <thead>
                        <tr className="text-gray-400 border border-white border-b-gray-400">
                            <td className="w-1/3 py-1">Заголовок</td>
                            <td className="w-1/3 py-1">Срок</td>
                            <td className="w-1/3 py-1">Действие</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="w-1/3 py-1 text-start">Алгебра 7 кл. Контрольная работа
                                17 учеников</td>
                            <td className="w-1/3 py-1 items-start">30.03.24</td>
                            <td className="w-1/3 py-1"><img src={close} alt='close'/></td>
                        </tr>
                        <tr>
                            <td className="w-1/3 py-1">Алгебра 7 кл. Контрольная работа
                                17 учеников</td>
                            <td className="w-1/3 py-1">30.03.24</td>
                            <td className="w-1/3 py-1"><img src={close} alt='close'/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="desktop:w-1/2 desktop:pl-2">
                    <div className="my-4 flex flex-col">
                        <h3 className="font-bold my-2">Журналы</h3>
                        <div className="flex flex-row my-4">
                            <div className="gap-1 w-1/4">
                                <img src={geography7mob} alt="geo7" className="h-[101px] w-[77px] tablet:hidden"/>
                                <img src={geography7desk} alt="geo7" className="h-[178px] w-[136px] hidden tablet:block"/>
                            </div>
                            <div className="gap-1 flex flex-col pl-4 w-3/4">
                                <h6 className="font-bold">Название журнала</h6>
                                <span>В журнале 2 учебных комплекта</span>
                                <div className="flex flex-row">
                                    <img src={greenFlag} alt="flag" className="gap-1 h-[30px] mr-2"/>
                                    <span className="gap-1">2.4 Умножение и деление степеней с натуральным показателем (2)</span>
                                </div>
                                <div>
                                    <button className="btn-secondary-border my-1 mr-2">Задания</button>
                                    <button className="btn-secondary-border my-1 mr-2">Решения</button>
                                    <button className="btn-secondary-border my-1 mr-2">Работы</button>
                                    <button className="btn-secondary-border my-1 mr-2">Составить работу</button>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row my-4">
                            <div className="gap-1 w-1/4">
                                <img src={geography7mob} alt="geo7" className="h-[101px] w-[77px] tablet:hidden"/>
                                <img src={geography7desk} alt="geo7" className="h-[178px] w-[136px] hidden tablet:block"/>
                            </div>
                            <div className="gap-1 flex flex-col pl-4 w-3/4">
                                <h6 className="font-bold">Название журнала</h6>
                                <span>В журнале 2 учебных комплекта</span>
                                <div className="flex flex-row">
                                    <img src={greenFlag} alt="flag" className="gap-1 h-[30px] mr-2"/>
                                    <span className="gap-1">2.4 Умножение и деление степеней с натуральным показателем (2)</span>
                                </div>
                                <div>
                                    <button className="btn-secondary-border my-1 mr-2">Задания</button>
                                    <button className="btn-secondary-border my-1 mr-2">Решения</button>
                                    <button className="btn-secondary-border my-1 mr-2">Работы</button>
                                    <button className="btn-secondary-border my-1 mr-2">Составить работу</button>
                                </div>
                            </div>
                        </div>
                        <div className="border border-white border-b-gray-200 w-full my-4"></div>
                        <div>
                            <button className="btn-primary">Все журналы</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherMain;
