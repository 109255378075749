import React from 'react';
import { useTranslation } from 'react-i18next';
import MError from "../m-error/MError";

function TableSelectFilter({ name, options, label, setState, state,  all, tPath, allText, withLabel = true, error = null, ...props }) {
    const {t} = useTranslation();
    return (
        <div className={`flex flex-col box-border ${props.className}`}>
            {withLabel && (
                <label className="text-xs font-normal text-black/[0.5] my-2">{label || t(`${tPath}.${name}.label`)}</label>
            )}

            <select
                className={`h-10 rounded-lg px-3 text-sm border border-black/[0.1] ${error && '!border-2 !border-red-700'} ${props.classNameSelect}`}
                value={state[name] || ''}
                onChange={e => {
                    setState({ ...state, [name]: e.target.value})
                }}
            >
                <option value={''} key={'all'}>{all || allText}</option>
                {options?.map(option => {
                    return (
                        <option value={option.value} key={option.value}>{ tPath ? t(`${tPath}.${name}.${option.value}`) : option.name}</option>
                    )
                })}
            </select>
            {error && error !== true && <MError errorMessage={error}/>}
        </div>
    );
}

export default TableSelectFilter;
