const FacebookFilled = ({className}) => {
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="24" viewBox="0 0 11 24" fill="none">
                <path
                    d="M6.16957 0C4.2087 0 2.58261 1.68595 2.58261 3.71901V7.58678L0 7.63636V11.5041L2.58261 11.4545V24H7.03043V11.4545H10.6174L11 7.58678H7.03043C7.03043 6.7438 7.03043 5.80165 7.03043 4.90909C7.03043 4.31405 7.46087 3.86777 8.03478 3.86777H11V0H6.16957Z"
                    fill="currentColor"/>
            </svg>
        </span>
    )
}
export default FacebookFilled;