import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import TabList from '../../components/common/m-tab/MTabList';
import TabItem from '../../components/common/m-tab/MTabItem';
import Spinner from '../../components/common/spinner/spinner';
import {usePostSubsMutation} from "../../api/SubsApi/SubsService";
import useFormData from "../../hooks/useFormData";
import TableSelectFilter from "../../components/common/m-table/TableSelectFilter";
import PeriodSelectCalendar from "../../components/super-admin/PeriodSelectCalendar/PeriodSelectCalendar";
import {authRegex} from "../../models/auth";
import IMask from 'imask';
import {formatDate, validateDates} from "../../utils/dates";
import {useGetPackageListQuery, useGetPublisherListQuery} from "../../api/LibraryApi/LibraryService";

function SubscriptionAdd(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const inputRef = useRef();
    const { data: publishers, isLoading: isPublishersLoading } = useGetPublisherListQuery();
    const { data: packages, isLoading: isPackagesLoading } = useGetPackageListQuery();
    const packageOptions = packages?.packages?.map(item=>({name:item.name, value: item.package_id, id: item.package_id}))
    const publishersOptions = publishers?.publishers?.map(item=>({name:item.name, value: item.publisher_id, id: item.publisher_id}))

    const handleDateChange = (e) => {
        setFormData(prev => ({ ...prev, totalDate: e.target.value}));
        setErrors(prev=>({...prev, totalDate: null }))
    };

    const [isLoading, setIsLoading] = useState(false)

    const {formData, errors, onHandleChange, setErrors, setFormData} = useFormData({
        bin:'',
        package_id: '',
        start_date: '',
        end_date:'',
        count: '',
        username: '',
        totalDate: '',
        publisher_id: '',
    })
    
    const [postSubs] = usePostSubsMutation()

    const addSubsHandler = async (type) => {

        const errors = {};

        const [start_date, end_date] = formData.totalDate.split('-')

        switch(type) {
            case 'private_user': {
                if (!formData.username.trim()) {
                    errors.username = t(`general.models.errors.general`);
                } else if (!authRegex.email.test(formData.username)) {
                    errors.username = t(`general.models.errors.invalidEmail`);
                }
                break;
            }
            case 'school': {
                if (formData.bin.trim().length<12) {
                    errors.bin = t(`general.models.errors.general`);
                }

                if (!formData.count.trim()) {
                    errors.count = t(`general.models.errors.general`);
                } else if (formData.count <= 0) {
                    errors.count = 'Неправильно указано количество подписок'
                }
                break;
            }

            default: {
                break;
            }
        }
        if(!formData.package_id) {
            errors.package_id = t(`general.models.errors.general`);
        }
        if (formData.totalDate.trim().length<23) {
            errors.totalDate = t(`general.models.errors.general`);
        } else if(!validateDates(formatDate(start_date), formatDate(end_date))) {
            errors.totalDate = 'Неправильно указана дата';
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            let clearedForm = Object.fromEntries(Object.entries(formData).filter(([key, value]) => {
                if(value && key!=='totalDate') return value
                else return false
            }));
            setIsLoading(true)

            const result = await postSubs({
                ...clearedForm,
                type,
                start_date: formatDate(start_date),
                end_date: formatDate(end_date)
            })
            setIsLoading(false)
            if (result.error) {
                dispatch(setIsError(t(`general.models.serverErrors.subsAdd.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                navigate(`/subscriptions`);
            }
        }
    }
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    const handleOpenCalendar = (e) => {
        e.stopPropagation();
        setFormData(prev=>({...prev, start_date:'', end_date:''}))
        setIsCalendarOpen(true)
    }

    useEffect(() => {
        const input = document.getElementById('input_id')
            const maskOptions = {
                mask: 'DD.MM.YYYY - DD.MM.YYYY',
                blocks: {
                    DD: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                    },
                    YYYY: {
                        mask: IMask.MaskedRange,
                        from: new Date().getFullYear(),
                        to: 5000,
                    },
                },
                lazy: true,
            };
        if(input) {
            const mask = IMask(input, maskOptions);
            return () => mask.destroy();
        }
    });
    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <h1 className="text-3xl m-2">{t('subscriptionsPage.header')}</h1>
                <div className="text-2xl m-2 font-semibold mt-4">{t('subscriptionsPage.addSubscription.title')}</div>
                <TabList activeTabIndex={0}>
                    <TabItem label={
                        <span onClick={(e) => {
                            setFormData({
                                bin: '',
                                package_id: null,
                                start_date: '',
                                end_date: '',
                                count: '',
                                totalDate: '',
                                username: '',
                                publisher_id: '',
                            })
                            setErrors({})
                        }}
                        >
                            {t('subscriptionsPage.addSubscription.tabs.schoolTab.label')}
                        </span>
                    }
                    >
                        <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full mb-8">
                            <MProfileGroup
                                title={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.title')}
                            >
                                <MProfileInput
                                    mode="edit"
                                    placeholder={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.bin')}
                                    field="bin"
                                    setValue={(e) => {onHandleChange(12, e)}}
                                    record={formData}
                                    error={errors.bin}
                                    type={'number'}
                                />
                                <TableSelectFilter
                                    name="publisher_id"
                                    withLabel={false}
                                    classNameSelect={'border-black/[0.3]'}
                                    className={'tablet:w-[632px]'}
                                    options={isPublishersLoading ? [] : publishersOptions}
                                    setState={(e)=>{
                                        setFormData(e)
                                        setErrors(prev=>({...prev, publisher_id: null}))
                                    }}
                                    state={formData}
                                    allText={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.publisher')}
                                    error={errors.publisher_id}
                                />
                                <TableSelectFilter
                                    name="package_id"
                                    withLabel={false}
                                    classNameSelect={'border-black/[0.3]'}
                                    className={'tablet:w-[632px]'}
                                    options={isPackagesLoading ? [] : packageOptions}
                                    setState={(e)=>{
                                        setFormData(e)
                                        setErrors(prev=>({...prev, package_id: null}))
                                    }}
                                    state={formData}
                                    allText={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.package')}
                                    error={errors.package_id}
                                />
                                <div className={'relative'}>
                                    <MProfileInput
                                        id='input_id'
                                        mode="edit"
                                        placeholder={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.valid_period')}
                                        record={formData}
                                        field="totalDate"
                                        error={errors.totalDate}
                                        iconName={'calendar'}
                                        setValue={(e)=>handleDateChange(e)}
                                        inputRef={inputRef}
                                        onIconClick={handleOpenCalendar}
                                    />
                                    {isCalendarOpen && (
                                        <PeriodSelectCalendar
                                            toggleCaledar={setIsCalendarOpen}
                                            selectedDate={new Date()}
                                            setFormData={(e)=>{
                                                setFormData(e)
                                                setErrors(prev=>({...prev, totalDate: null}))
                                            }}
                                            period={{startDate: formData.start_date, endDate: formData.end_date}}
                                        />
                                    )}
                                </div>
                                <MProfileInput
                                    mode="edit"
                                    placeholder={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.qty')}
                                    setValue={(e) => {onHandleChange(5, e)}}
                                    record={formData}
                                    field="count"
                                    error={errors.count}
                                    type={'number'}
                                />
                                <div className="mt-2">{t('subscriptionsPage.addSubscription.tabs.schoolTab.requiredText')}</div>
                            </MProfileGroup>
                            <div className={'flex'}>
                                <button
                                    className="h-10 px-4 bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
                                    onClick={()=>addSubsHandler('school')}
                                >
                                    {t('subscriptionsPage.addSubscription.tabs.schoolTab.buttons.addSubscription')}
                                </button>
                            </div>
                        </div>
                    </TabItem>
                    <TabItem label={
                        <span
                            onClick={() => {
                                setFormData({
                                    bin: '',
                                    package_id: null,
                                    start_date: '',
                                    end_date: '',
                                    count: '',
                                    totalDate: '',
                                    username: '',
                                    publisher_id: '',
                                })
                                setErrors({})
                            }}
                        >
                            {t('subscriptionsPage.addSubscription.tabs.privateUserTab.label')}
                        </span>
                    }
                    >
                        <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full mb-8">
                            <MProfileGroup
                                title={t('subscriptionsPage.addSubscription.tabs.privateUserTab.generalData.title')}>
                                <MProfileInput
                                    mode="edit"
                                    placeholder={t('subscriptionsPage.addSubscription.tabs.privateUserTab.generalData.username')}
                                    setValue={(e) => {
                                        onHandleChange(50, e, true)
                                    }}
                                    record={formData}
                                    field="username"
                                    error={errors.username}
                                />
                                <TableSelectFilter
                                    name="publisher_id"
                                    withLabel={false}
                                    classNameSelect={'border-black/[0.3]'}
                                    className={'tablet:w-[632px]'}
                                    options={isPublishersLoading ? [] : publishersOptions}
                                    setState={(e)=>{
                                        setFormData(e)
                                        setErrors(prev=>({...prev, publisher_id: null}))
                                    }}
                                    state={formData}
                                    allText={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.publisher')}
                                    error={errors.publisher_id}
                                />
                                <TableSelectFilter
                                    name="package_id"
                                    withLabel={false}
                                    classNameSelect={'border-black/[0.3]'}
                                    className={'tablet:w-[632px]'}
                                    options={isPackagesLoading ? [] : packageOptions}
                                    setState={(e)=>{
                                        setFormData(e)
                                        setErrors(prev=>({...prev, package_id: null}))
                                    }}
                                    state={formData}
                                    allText={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.package')}
                                    error={errors.package_id}
                                />
                                <div className={'relative'}>
                                    <MProfileInput
                                        id='input_id'
                                        mode="edit"
                                        placeholder={t('subscriptionsPage.addSubscription.tabs.schoolTab.generalData.valid_period')}
                                        record={formData}
                                        field="totalDate"
                                        error={errors.totalDate}
                                        iconName={'calendar'}
                                        setValue={(e)=>handleDateChange(e)}
                                        inputRef={inputRef}
                                        onIconClick={handleOpenCalendar}
                                    />
                                    {isCalendarOpen && (
                                        <PeriodSelectCalendar
                                            toggleCaledar={setIsCalendarOpen}
                                            selectedDate={new Date()}
                                            setFormData={setFormData}
                                            period={{startDate: formData.start_date, endDate: formData.end_date}}
                                        />
                                    )}
                                </div>
                                <div
                                    className="mt-2">{t('subscriptionsPage.addSubscription.tabs.privateUserTab.requiredText')}</div>
                            </MProfileGroup>
                            <div className={'flex'}>
                                <button
                                    className="h-10 px-4 bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
                                    onClick={() => addSubsHandler('private_user')}
                                >
                                    {t('subscriptionsPage.addSubscription.tabs.schoolTab.buttons.addSubscription')}
                                </button>
                            </div>
                        </div>
                    </TabItem>
                </TabList>
            </div>
        </Spinner>
    );
}


export default SubscriptionAdd;
