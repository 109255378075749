import React from 'react';

function MSelect({ label, options, border = false, onChange = () => {}, path, frontField, ...props }) {

    let modifiedOptions = [...options];

    if (frontField) {
        const frontOptionIndex = modifiedOptions.findIndex(option => option.value === frontField);
        if (frontOptionIndex > -1) {
            const [frontOption] = modifiedOptions.splice(frontOptionIndex, 1);
            modifiedOptions = [frontOption, ...modifiedOptions];
        }
    }

    return (
        <div className={`flex flex-col box-border ${props.className}`}>
            {label && <label className="font-bold my-2">{label}</label>}
            <select
                className={`rounded-lg p-2 ${border ? 'rounded-lg border border-gray-400' : ''}`}
                onChange={onChange}
            >
                {modifiedOptions.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default MSelect;
