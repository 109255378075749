import React, { useState } from 'react';
import MInput from '../../components/common/m-input/MInput';
import { useChangePasswordMutation } from '../../api/AuthApi/authService';
import {useDispatch, useSelector} from 'react-redux';
import { authRegex } from '../../models/auth';
import MError from '../../components/common/m-error/MError';
import { useTranslation } from 'react-i18next';
import {setIsError, setIsSuccess} from "../../store/reducers/notificationSlice";

function PassChange(props) {

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })

    const user = useSelector(state=>state.user)

    const dispatch = useDispatch()

    const [ changePassword ] = useChangePasswordMutation();
    const {t} = useTranslation();

    const resetData = () => {
        setFormData({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
    }
    const [errors, setErrors] = useState({});

    const onHandleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value
        });
    };

    const onHandle = async (e) => {
        e.preventDefault();

        const errors = {};

        if (!formData.oldPassword.trim()) {
            errors.oldPassword = 'password';
        }

        if (!formData.newPassword.trim()) {
            errors.newPassword = 'password';
        } else if (!authRegex.password.test(formData.newPassword)) {
            errors.newPassword = 'invalidPassword';
        }

        if (!formData.confirmPassword.trim()) {
            errors.confirmPassword = 'confirmPassword';
        }

        if (formData.confirmPassword.trim() !== formData.newPassword.trim()) {
            errors.confirmPassword = 'passwordMismatch';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            const result = await changePassword({
                username: user.username,
                old_password: formData.oldPassword,
                new_password: formData.newPassword
            })
            if (result.error) {
                console.error(result.error);
                dispatch(setIsError(t(`general.models.serverErrors.changePass.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                resetData();
                dispatch(setIsSuccess({successText: 'Ваш пароль успешно изменен'}))
            }
        }
    }

    return (
        <div className="px-2 w-full box-border flex flex-col items-start justify-start">
            <div>
                <h1 className="text-3xl my-4">{t('passChangePage.changePassword')}</h1>
            </div>
            <form className="flex flex-col tablet:w-2/3 desktop:w-3/4" onSubmit={onHandle}>
                <div className="flex flex-col desktop:flex-row desktop:items-start">
                    <div className="div-rule mb-2 desktop:order-2 desktop:ml-6">
                        {t('passChangePage.rules')}
                    </div>
                    <div className="flex flex-col desktop:order-1">
                        <MInput
                            placeholder={t('passChangePage.currentPassword')}
                            className="desktop:w-[550px]"
                            name="oldPassword"
                            type="password"
                            value={formData.oldPassword}
                            setValue={onHandleChange}
                        />
                        {errors.oldPassword && <MError error={errors.oldPassword}/>}

                        <MInput
                            placeholder={t('passChangePage.newPassword')}
                            className="desktop:w-[550px]"
                            name="newPassword"
                            type="password"
                            value={formData.newPassword}
                            setValue={onHandleChange}
                        />
                        {errors.newPassword && <MError error={errors.newPassword}/>}

                        <MInput
                            placeholder={t('passChangePage.repeatPassword')}
                            className="desktop:w-[550px]"
                            name="confirmPassword"
                            type="password"
                            value={formData.confirmPassword}
                            setValue={onHandleChange}
                        />
                        {errors.confirmPassword && <MError error={errors.confirmPassword}/>}

                    </div>
                </div>
                <button className="btn-primary w-[250px] mt-2 mb-8" onClick={onHandle}>
                    {t('passChangePage.save')}
                </button>
            </form>
        </div>
    );
}

export default PassChange;
