import React, {useCallback, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MTable from '../../components/common/m-table/MTable';
import TableSelectFilter from '../../components/common/m-table/TableSelectFilter';
import {dropFilter, saveFilter} from "../../store/reducers/filterSlice";
import TableInputFilter from '../../components/common/m-table/TableInputFilter';
import MIcon from '../../components/common/m-icon/MIcon'
import MCheckbox from '../../components/common/m-checkbox/MCheckbox';
import TabList from '../../components/common/m-tab/MTabList';
import TabItem from '../../components/common/m-tab/MTabItem';
import Spinner from '../../components/common/spinner/spinner';
import {useDeleteSubsMutation, useLazyGetSubsQuery} from "../../api/SubsApi/SubsService";
import MModalWarning from "../../components/common/m-modal-warning/MModalWarning";
import InfoModal from "../../components/layout/navbar/modals/InfoModal";
import useDeviceType from "../../hooks/useDeviceType";
import {useGetPackageListQuery} from "../../api/LibraryApi/LibraryService";
import {useGetCityListQuery} from "../../api/SchoolsApi/schoolsList";

export const SUBSCRIPTIONS_VIEW_FILTER_NAME = 'SubscriptionsView'
const PAGE_SIZE = 14
const DEFAULT_FILTER = { limit: PAGE_SIZE, type:'school' }

function Subscriptions(props) {

    const [subs, setSubs] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [currentData, setCurrentData] = useState(new Map())
    const [currentPage, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState({})
    const [filtersApplied, setFiltersApplied] = useState(true)
    const [checked, setChecked] = useState([])
    const savedFilters = useSelector(state => state.filter.savedFilters[SUBSCRIPTIONS_VIEW_FILTER_NAME]) || DEFAULT_FILTER
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const isDefaultFilter = isEqual(filters, DEFAULT_FILTER)
    const isFiltersApplied = isEqual(filters, savedFilters)
    const [getSubs] = useLazyGetSubsQuery();
    const [deleteSubs] = useDeleteSubsMutation();
    const {data:packages, isLoading: isPackagesLoading} = useGetPackageListQuery();
    const packageOptions = packages?.packages?.map(item=>({name:item.name, value: item.package_id, id: item.package_id}))
    const {isMobile} = useDeviceType();
    const {data:cities} = useGetCityListQuery();
    const cityOptions =cities?.cities.map(city=>({name:city.name, value:city.city_id})) || []

    const deleteSubscriptions = async () => {
        for (const item of checked) {
            const {error} = await deleteSubs(item);
            if (error) {
                dispatch(setIsError(t(`general.models.serverErrors.subsDelete.${error.data.detail.error_code}`, `${error.data?.error_code}: ${error.data?.message}`)))
            } else {
                setChecked(prev=>prev.filter(id=>id!==item))
                setSubs(prev=>prev.filter(obj => obj.subscription_id !== item))
                setTotal(prev=>prev-1)
            }
        }
    };

    const reformatParams = (data)  => {
        let filterStr = '';
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (value) {
                    if (filterStr === '') {
                        filterStr = `?${key}=${value}`
                    } else {
                        filterStr = filterStr + `&${key}=${value}`
                    }
                }
            }
        }
        return filterStr;
    }

    const onApplyFilters = async (sub) => {
        setCurrentPage(1)
        dispatch(saveFilter({
            filterName: SUBSCRIPTIONS_VIEW_FILTER_NAME,
            filterValue: { ...filters, offset: 0 }
        }))
        setFiltersApplied(true)
    }

    const onResetFilters = () => {
        setFilters(DEFAULT_FILTER)
        dispatch(dropFilter({ filterName: SUBSCRIPTIONS_VIEW_FILTER_NAME }))
    }

    const onPageClick = (page) => {
        setCurrentPage(page)
        dispatch(saveFilter({
            filterName: SUBSCRIPTIONS_VIEW_FILTER_NAME,
            filterValue: { ...savedFilters, offset: (page - 1) * PAGE_SIZE }
        }))
    }

    const onSearch = useCallback(async (propsFilters = null) => {
        const params = reformatParams(propsFilters || savedFilters);
        setIsLoading(true)
        const { data, error } = await getSubs(params)
        setIsLoading(false)
        if(error){
            dispatch(setIsError(t(`general.models.serverErrors.subsGet.${error.data?.error_code}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else if (data && data.subscriptions?.subscriptions) {
            setSubs(data.subscriptions?.subscriptions)
            setTotal(data.subscriptions?.unique_count)
        }
    },[dispatch, getSubs, savedFilters, t])
    const onChangeFilter = (newState) => {
        setFilters(newState)
        setFiltersApplied(false)
    }

    const onChangeChecked = (recordId) => {
        if (checked.includes(recordId)) {
            setChecked(checked.filter((item) => item !== recordId))
        } else {
            setChecked([ ...checked, recordId])
        }
    }

    const columns = [
        {
            label: '',
            key: 'checkFlg',
            render: (record) => {
                const current = checked.includes(record.subscription_id)
                return <MCheckbox
                    value={current}
                    setValue={() => onChangeChecked(record.subscription_id)}
                />
            }
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.bin'),
            key: 'school_bin',
            render: (school) => isMobile ? ( <span
                className="text-blue3 font-medium text-sm underline cursor-pointer"
                onClick={()=>{
                    const currentSubscriber = subs.find(item=>item.subscription_id===school.subscription_id)
                    setIsInfoModalOpen(true)
                    setCurrentData(new Map()
                        .set("Название школы",currentSubscriber.school_name)
                        .set("Пакет","Мектеп базовый")
                        .set("Срок действия",`${currentSubscriber.start_date}-${currentSubscriber.end_date}`)
                        .set('title',currentSubscriber.school_bin)
                    )}}
            >
                {school.school_bin}
            </span>
            ) : school.school_bin
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.school_name'),
            key: 'school_name'
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.city'),
            key: 'school_city',
            showForModes: ['desktop']
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.package'),
            key: 'package_name',
            showForModes: ['tablet', 'desktop'],
            render : (record) => {
                return (<span>{record.package_name || 'Мектеп базовый'}</span>)
            }
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.valid_period'),
            key: 'start_date',
            showForModes: ['tablet', 'desktop'],
            render : (record) => {
                return (
                    <span>{`${record.start_date}–${record.end_date}`}</span>
                )
            }
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.qty'),
            key: 'count',
            showForModes: ['desktop']
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.used'),
            key: 'active_count',
            showForModes: ['desktop'],
            render : (record) => {
                return (
                    <span className={'text-green2'}>
                       {`${record.active_count}`}
                    </span>
                )
            }
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.unused'),
            key: 'inactive_count',
            showForModes: ['desktop'],
            render : (record) => {
                return (
                    <span className={'text-red4'}>
                       {`${record.inactive_count}`}
                    </span>
                )
            }
        }
    ]

    const columnsPrivate = [
        {
            label: '',
            key: 'checkFlg',
            render: (record) => {
                const current = checked.includes(record.subscription_id)
                return <MCheckbox
                    value={current}
                    setValue={() => onChangeChecked(record.subscription_id)}
                />
            }
        },
        {
            label: t('subscriptionsPage.tabs.privateUserTab.list.columns.fullname'),
            key: 'user_fullname',
            render: (record) => isMobile ? (<span
                className="text-blue3 font-medium text-sm underline cursor-pointer"
                onClick={()=>{
                    const currentSubscriber = subs.find(item=>item.subscription_id===record.subscription_id)
                    setIsInfoModalOpen(true)
                    setCurrentData(new Map()
                        .set("Логин",currentSubscriber.username)
                        .set("Пакет","Мектеп базовый")
                        .set("Срок действия",`${currentSubscriber.start_date}-${currentSubscriber.end_date}`)
                        .set('title',currentSubscriber.user_fullname)
                    )
                }
                }
            >
                {record.user_fullname}
            </span>
            ) : record.user_fullname
        },
        {
            label: t('subscriptionsPage.tabs.privateUserTab.list.columns.username'),
            key: 'username'
        },
        {
            label: t('subscriptionsPage.tabs.privateUserTab.list.columns.package'),
            key: 'package',
            showForModes: ['tablet', 'desktop'],
            render : (record) => {
                return (<span>{record.package_name || 'Мектеп базовый'}</span>)
            }
        },
        {
            label: t('subscriptionsPage.tabs.privateUserTab.list.columns.valid_period'),
            key: 'valid_period',
            showForModes: ['tablet', 'desktop'],
            render : (record) => {
                return (
                    <span>
                       {`${record.start_date}–${record.end_date}`}
                    </span>
                )
            }
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.used'),
            key: 'active_count',
            showForModes: ['desktop'],
            render : (record) => {
                return (
                    <span className={'text-green2'}>
                       {`${record.active_count}`}
                    </span>
                )
            }
        },
        {
            label: t('subscriptionsPage.tabs.schoolTab.list.columns.unused'),
            key: 'inactive_count',
            showForModes: ['desktop'],
            render : (record) => {
                return (
                    <span className={'text-red4'}>
                       {`${record.inactive_count}`}
                    </span>
                )
            }
        }
    ]

    const deleteDisabled = !checked || checked.length === 0

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-orange2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => navigate('/subscriptions/add')}
        >
            <MIcon
                type="plusCircle"
                className={'text-orange3'}
            />
            {t('subscriptionsPage.tabs.schoolTab.list.buttons.addSubscription')}
        </button>
        <button
            className={`flex w-full tablet:w-auto desktop:w-auto flex-row justify-center h-10 gap-4 px-4 items-center bg-blue3 rounded-lg text-sm text-white font-medium whitespace-nowrap ${deleteDisabled && '!bg-black/[0.3]'}`}
            onClick={()=>setIsOpen(true)}
            disabled={deleteDisabled}
        >
            <MIcon
                type="xMarkCircle"
                className={`text-blue3 ${deleteDisabled && '!text-black/[0.3]'}`}
            />
            {t('subscriptionsPage.tabs.schoolTab.list.buttons.deleteSelected')}
        </button>
    </div>


    useEffect(() => {
        onSearch()
        setFilters(savedFilters || DEFAULT_FILTER)
    }, [onSearch, savedFilters])

    const TabClickFunctions = [
        ()=>{
            setSubs([])
            const newFilters = {...filters, type:'school', package_name: null, username:null, package_id: null}
            setFilters(newFilters)
            dispatch(saveFilter({
                filterName: SUBSCRIPTIONS_VIEW_FILTER_NAME,
                filterValue: { ...newFilters }
            }))
        },
        ()=>{
            setSubs([])
            const newFilters = {...filters, type:'private_user', school_bin:null, package_name: null, school_city: null, package_id: null}
            setFilters(newFilters)
            dispatch(saveFilter({
                filterName: SUBSCRIPTIONS_VIEW_FILTER_NAME,
                filterValue: { ...newFilters }
            }))
        }

    ]

    return (
        <Spinner spinning={isLoading}>
            <div className="px-2 pt-[50px] pb-[82px]">
                <h1 className="text-[28px] font-medium mb-[40px]">{t('subscriptionsPage.header')}</h1>
                <TabList
                    activeTabIndex={savedFilters.type==='school' ? 0 : 1}
                    onClick={TabClickFunctions}
                >
                    <TabItem label={t('subscriptionsPage.tabs.schoolTab.title')}
                    >
                        {/*<h2 className={'text-2xl font-semibold m-2'}>{t('usersPage.title')}</h2>*/}
                        <div className="flex flex-col gap-2">
                            <div className="bg-card-bg rounded-xl m-2 p-4 pb-6 flex flex-none flex-col desktop:flex-row tablet:flex-row tablet:flex-wrap w-full">
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5" >
                                    <TableSelectFilter
                                        name="school_city"
                                        options={cityOptions}
                                        setState={onChangeFilter}
                                        state={filters}
                                        allText={t('subscriptionsPage.tabs.schoolTab.search.filters.school_city.all')}
                                        label={'Выберите город'}
                                    />
                                </div>
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                                    <TableSelectFilter
                                        name="package_id"
                                        options={isPackagesLoading ? [] : packageOptions}
                                        setState={onChangeFilter}
                                        state={filters}
                                        allText={t('subscriptionsPage.tabs.schoolTab.search.filters.package.all')}
                                        label={t('subscriptionsPage.tabs.schoolTab.search.filters.package.label')}
                                    />
                                </div>
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5 mb-3">
                                    <TableInputFilter
                                        name="school_bin"
                                        placeholder={t('subscriptionsPage.tabs.schoolTab.search.filters.school_bin.placeholder')}
                                        tPath={'subscriptionsPage.tabs.schoolTab.search.filters'}
                                        setState={onChangeFilter}
                                        regexTemplate={/^\d{0,12}$/}
                                        state={filters}
                                    />
                                </div>
                                <div className="w-full ml-2.5 mt-6 flex flex-col items-center gap-4">
                                    <div className="w-full flex flex-row items-center gap-4">
                                        <button
                                            className="flex flex-row h-10 px-6  items-center bg-blue2 border border-blue4 rounded-lg text-sm text-white font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                            onClick={onApplyFilters}
                                            disabled={isFiltersApplied}
                                        >
                                            {t('subscriptionsPage.tabs.schoolTab.search.buttons.apply')}
                                        </button>
                                        <button
                                            className="flex flex-row h-10 px-6  items-center border border-blue2 rounded-lg text-sm text-main-color font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                            onClick={onResetFilters}
                                            disabled={isDefaultFilter}
                                        >
                                            {t('subscriptionsPage.tabs.schoolTab.search.buttons.reset')}
                                        </button>
                                    </div>
                                    <div className="text-orange1 text-sm mr-auto">{!isFiltersApplied && 'Нажмите кнопку для применения фильтра'}</div>
                                </div>
                            </div>
                            <div className="w-full mx-2 pt-6">
                                <MTable
                                    columns={columns}
                                    data={subs}
                                    pageSize={14}
                                    total={total}
                                    currentPage={currentPage}
                                    setCurrentPage={onPageClick}
                                    foundTitle={t('subscriptionsPage.tabs.schoolTab.search.found')}
                                    buttons={buttons}
                                    uniqueKey={'subscription_id'}
                                />
                            </div>
                        </div>
                    </TabItem>
                    <TabItem
                        label={t('subscriptionsPage.tabs.privateUserTab.title')}
                    >
                        {/*<h2 className={'text-2xl font-semibold m-2'}>{t('usersPage.title')}</h2>*/}
                        <div className="flex flex-col gap-2">
                            <div className="bg-card-bg rounded-xl m-2 p-4 pb-6 flex flex-none flex-col desktop:flex-row tablet:flex-row tablet:flex-wrap w-full">
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5">
                                    <TableSelectFilter
                                        name="package_id"
                                        options={isPackagesLoading ? [] : packageOptions}
                                        setState={onChangeFilter}
                                        state={filters}
                                        allText={t('subscriptionsPage.tabs.privateUserTab.search.filters.package.all')}
                                        label={t('subscriptionsPage.tabs.schoolTab.search.filters.package.label')}
                                    />
                                </div>
                                <div className="w-full desktop:w-1/4 tablet:w-1/3 px-2.5 mb-3">
                                    <TableInputFilter
                                        name="username"
                                        placeholder={t('subscriptionsPage.tabs.privateUserTab.search.filters.username.placeholder')}
                                        tPath={'subscriptionsPage.tabs.privateUserTab.search.filters'}
                                        setState={onChangeFilter}
                                        state={filters}
                                    />
                                </div>
                                <div className="w-full ml-2.5 mt-6 flex flex-col items-center gap-4">
                                    <div className="w-full flex flex-row items-center gap-4">
                                        <button
                                            className="flex flex-row h-10 px-6  items-center bg-blue2 border border-blue4 rounded-lg text-sm text-white font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                            onClick={onApplyFilters}
                                            disabled={isFiltersApplied}
                                        >
                                            {t('subscriptionsPage.tabs.schoolTab.search.buttons.apply')}
                                        </button>
                                        <button
                                            className="flex flex-row h-10 px-6  items-center border border-blue2 rounded-lg text-sm text-main-color font-medium disabled:bg-gray1 disabled:border-gray1 disabled:text-disabled-text"
                                            onClick={onResetFilters}
                                            disabled={isDefaultFilter}
                                        >
                                            {t('subscriptionsPage.tabs.schoolTab.search.buttons.reset')}
                                        </button>
                                    </div>
                                    <div className="text-orange1 text-sm mr-auto">{!isFiltersApplied && 'Нажмите кнопку для применения фильтра'}</div>
                                </div>
                            </div>
                            <div className="w-full mx-2 pt-6">
                                <MTable
                                    columns={columnsPrivate}
                                    data={subs}
                                    pageSize={14}
                                    total={total}
                                    currentPage={currentPage}
                                    setCurrentPage={onPageClick}
                                    foundTitle={t('subscriptionsPage.tabs.privateUserTab.search.found')}
                                    buttons={buttons}
                                    uniqueKey={'subscription_id'}
                                />
                            </div>
                        </div>
                    </TabItem>
                </TabList>
            </div>
            <InfoModal isInfoModal={isInfoModalOpen} setIsInfoModal={setIsInfoModalOpen} data={currentData}/>
            <MModalWarning text={'Вы действительно хотите удалить выбранные подписки?'} isOpen={isOpen} setIsOpen={setIsOpen} onAccept={deleteSubscriptions}/>
        </Spinner>
    );
}

export default Subscriptions;
