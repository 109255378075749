import MProfileGroup from "../../components/common/m-profile-group/MProfileGroup";
import MProfileInput from "../../components/common/m-profile-input/MProfileInput";
import MProfileList from "../../components/common/m-profile-list/MProfileList";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLazyGetOauthDeleteUserQuery, useLogoutMutation} from "../../api/AuthApi/authService";
import MModalWarning from "../../components/common/m-modal-warning/MModalWarning";
import {setIsError} from "../../store/reducers/notificationSlice";
import {useEditUserMutation} from "../../api/UserApi/userEdit";
import {clearUser, setUser} from "../../store/reducers/userSlice";

import useFormData from "../../hooks/useFormData";
import MError from "../../components/common/m-error/MError";
import { userRoleOptions } from "../../models/users";
import {logOut} from "../../store/reducers/authSlice";
import MIcon from "../../components/common/m-icon/MIcon";

const Settings = () => {
    const {t} = useTranslation();
    const user = useSelector(state => state.user);
    const [deactivate] = useLazyGetOauthDeleteUserQuery();
    const [logout] = useLogoutMutation();
    const [userEdit, {isLoading}] = useEditUserMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const token = useSelector(state=>state.auth.token)

    const {formData, errors, onHandleChange, setErrors, setFormData} = useFormData({
        firstname: '',
        lastname: '',
        patronymic: ''
    });
    const dispatch = useDispatch();
    const onAccept = async () => {
            const result = await deactivate();
            if(result.error){
                //TODO ОБРАБОТКА ОШИБОК
                dispatch(setIsError('Deactivation error'))
            } else {
                await logout({access_token: token})
                dispatch(logOut())
                dispatch(clearUser())
                localStorage.clear()
            }
    }

    const onSave = async () => {
        const errors = {};
        const { firstname, lastname, patronymic } = formData;

        if (!firstname.trim()) {
            errors.firstname = 'general';
        } else if (firstname.length <2) {
            errors.firstname = 'minText2'
        }

        if (!lastname.trim()) {
            errors.lastname = 'general';
        }  else if (lastname.length < 2) {
            errors.lastname = 'minText2'
        }

        if (!patronymic.trim()) {
            errors.patronymic = 'general';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            const {username} = user;
            const result = await userEdit({
                username,
                firstname,
                lastname,
                patronymic
            });
            if (result.error) {
                dispatch(setIsError(t(`general.models.serverErrors.userEdit.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                dispatch(setUser({
                    ...user,
                    firstname,
                    lastname,
                    patronymic,
                    fullname: `${lastname} ${firstname} ${patronymic}`
                }))
            }
            setIsEdit(false)
        }

    }

    useEffect(() => {
        setFormData({
            firstname: user.firstname,
            lastname: user.lastname,
            patronymic: user.patronymic ?? ''
        })
    }, [setFormData, user.firstname, user.lastname, user.patronymic]);

    return (
        <div className="py-[50px] px-4">
            <h1 className="text-3xl m-2">Мои настройки</h1>

            <div className={'flex items-center'}>
                <div className="h-[32px] text-2xl m-2 font-semibold mt-4">{user.fullname}</div>
                {!user.kund_user_id && (
                    <span
                        onClick={() => {
                             //window.location.assign('http://localhost:3000/?session_state=fe274786-7abe-485c-b8bc-47eb5a7459dd&code=73c836f1-0a88-47d3-89b2-dfbc15972edf.fe274786-7abe-485c-b8bc-47eb5a7459dd.8374cf23-4f61-48d6-862a-9a58fabf1d07');
                            window.location.assign('http://91.147.93.80:32372/realms/mektep/protocol/openid-connect/auth?client_id=mektep&response_type=code&redirect_uri=https%3A%2F%2Ftest.okulyktar.kz%2F');
                            // window.location.assign('https://login.staging.kundelik.kz/oauth2')
                    }}
                        className={'hidden'}
                    >
                         <MIcon
                             className={'cursor-pointer'}
                             type={'kundelik'}
                         />
                    </span>
                )}
            </div>
            <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full">
                {isEdit ? (
                    <>
                        <MProfileGroup title={t('usersPage.userView.generalData.title')}>
                            <MProfileInput setValue={(e)=>onHandleChange(30, e)} placeholder={'Имя*'} record={formData} field='firstname' mode={'edit'}/>
                            {errors.firstname && <MError error={errors.firstname}/>}
                            <MProfileInput setValue={(e)=>onHandleChange(30, e)} placeholder={'Фамилия*'} record={formData} field='lastname' mode={'edit'}/>
                            {errors.lastname && <MError error={errors.lastname}/>}
                            <MProfileInput setValue={(e)=>onHandleChange(30, e)} placeholder={'Отчество'} record={formData} field='patronymic' mode={'edit'}/>
                            {errors.patronymic && <MError error={errors.patronymic}/>}
                            <MProfileInput placeholder={'Почта'} record={user} field='username' mode={'edit'} disabled={true}/>
                            <p>* Поля обязательные для заполнения</p>
                        </MProfileGroup>
                        <div>
                            <button
                                className={'btn-edit'}
                                onClick={onSave}
                                disabled={isLoading}
                            >
                                Сохранить
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <MProfileGroup title={t('usersPage.userView.generalData.title')}>
                            <MProfileInput label={t('usersPage.userView.generalData.fields.email')} record={user}
                                           field='email'/>
                        </MProfileGroup>
                        <MProfileGroup title={t('usersPage.userView.schoolData.title')}>
                            <MProfileInput label={t('usersPage.userView.schoolData.fields.school_bin')} record={user}
                                           field='school_bin'/>
                            <MProfileInput label={t('usersPage.userView.schoolData.fields.school_name')} record={user}
                                           field='school_name'/>
                            <MProfileInput label={t('usersPage.userView.schoolData.fields.school_city')} record={user}
                                           field='school_city_name'/>
                        </MProfileGroup>
                        <MProfileGroup title={t('usersPage.userView.rolesData.title')}>
                            <MProfileList list={userRoleOptions.filter((option) => user.roles?.includes(option.value))?.map((option) => t(`usersPage.search.filters.role.${option.value}`))}/>
                        </MProfileGroup>
                        <MProfileGroup title={t('usersPage.userView.subscriptionsData.title')}>
                            <MProfileList
                                list={user.subscriptions.map(item=>`${item.package_name} - ${item.start_date}-${item.end_date}`)}/>
                        </MProfileGroup>

                        {user.activeRole === 'private_user' && (
                            <div className={'flex gap-4'}>
                                <button
                                    className={'btn-edit'}
                                    onClick={()=>(setIsEdit(prev=>!prev))}
                                >
                                    Редактировать
                                </button>

                                <button
                                    className={'p-2 text-red2 border-red2 border rounded-lg'}
                                    onClick={()=> setIsOpen(true)}
                                >
                                    Деактивировать аккаунт
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
            <MModalWarning isOpen={isOpen} text={'Вы действительно хотите удалить свой аккаунт?'} setIsOpen={setIsOpen} onAccept={onAccept}/>
        </div>
    )
}
export default Settings;