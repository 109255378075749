import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisterUserAdminMutation } from '../../api/AuthApi/authService';
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import {setIsError} from "../../store/reducers/notificationSlice";
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import { userRoleOptions } from '../../models/users';
import MCheckboxGroup from '../../components/common/m-checkbox-group/MCheckboxGroup';
import Spinner from '../../components/common/spinner/spinner';

function UserAdd({type = null}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({})
    const [registerUserAdmin] = useRegisterUserAdminMutation()
    const [isLoading, setIsLoading] = useState(false)

    const needSchool = formData.role?.some(item => ['student', 'teacher', 'school_admin'].includes(item))
    const requiredFields = type === 'school' ? {
        firstname: t('general.models.errors.general'),
        lastname: t('general.models.errors.general'),
        patronymic: false,
        email: t('general.models.errors.general'),
        role: t('errors.checkRole')
    } :  {
        firstname: t('general.models.errors.general'),
        lastname: t('general.models.errors.general'),
        patronymic: false,
        email: t('general.models.errors.general'),
        school_bin: needSchool ? t('general.models.errors.general') : false,
        password: t('general.models.errors.general'),
        role: t('errors.checkRole')
    }

    useEffect(() => {
        if (!needSchool && errors.school_bin) {
            const { school_bin: keyForClear, ...other } = errors
            setErrors(other)
        }
    }, [needSchool])

    const onHandleChange = (limitMax, e, regexTemplate) => {
        const { name, value } = e.target
        if (errors[name]) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        }

        if (value.length <= limitMax) {
            if ((regexTemplate && regexTemplate.test(value)) || !regexTemplate) {
                setFormData({
                    ...formData, [name]: value
                });
            }
        }
    }

    const checkLimitMin = (limitMin, e) => {
        const { name, value } = e.target
        const isEmpty = requiredFields[name] && (!formData[name] || formData[name]?.length === 0)
        if (isEmpty) {
            setErrors({...errors, [name]: t('general.models.errors.general')})
        } else if (value.length < limitMin) {
            setErrors({...errors, [name]: `${t('errors.limitMin')} - ${limitMin}`})
        }
    }

    const checkBIN = (e) => {
        const { name, value } = e.target
        if (/^\d{12,12}$/.test(value)) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        } else {
            setErrors({...errors, [name]: t('errors.checkBIN')})
        }
    }

    const checkPassword = (e) => {
        const { name, value } = e.target
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!_%*?&]{8,}$/.test(value)) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        } else {
            setErrors({...errors, [name]: t('general.models.errors.invalidPassword')})
        }
    }

    const checkEmail = (e) => {
        const { name, value } = e.target
        if (/^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)$/.test(value)) {
            const { [name]: keyForClear, ...other } = errors
            setErrors(other)
        } else {
            setErrors({...errors, [name]: t('general.models.errors.invalidEmail')})
        }
    }

    const checkBeforeAddUser = () => {
        const invalidFields = Object.keys(requiredFields)?.filter((field) => requiredFields[field] && (!formData[field] || formData[field]?.length === 0))
        if (invalidFields?.length > 0) {
            // dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`)))
            // dispatch(setIsError('Проверьте обязательные поля'))
            const errosObject = invalidFields.reduce((acc, val) => ({ ...acc, [val]: requiredFields[val]}), {})
            setErrors({ ...errors, ...errosObject })
            return false
        }
        if (Object.keys(errors).length > 0) {
            return false
        }
        return true
    }

    const addUserHandler = async () => {
        if (checkBeforeAddUser()) {
            setIsLoading(true)
            const result = await registerUserAdmin(formData)
            setIsLoading(false)
            if (result.error) {
                dispatch(setIsError(t(`general.models.serverErrors.register.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                navigate('/users')
            }
        }
    }

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4 mt-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={addUserHandler}
        >
            {t('usersPage.userAdd.tabs.user.buttons.addUser')}
        </button>
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full mb-8">
                    {type !== 'school' && (
                        <MProfileGroup title={t('usersPage.userAdd.tabs.user.regType.title')}>
                            <div className="flex">
                                <input type="radio" value="withEmail" checked className="mx-2"/>
                                <label for="withEmail">{t('usersPage.userAdd.tabs.user.regType.withEmail')}</label>
                            </div>
                            <div className="flex">
                                <input type="radio" value="withoutEmail" disabled className="mx-2"/>
                                <label for="withEmail" disabled className="text-gray4">{t('usersPage.userAdd.tabs.user.regType.withoutEmail')}</label>
                            </div>
                        </MProfileGroup>
                    )}
                    <MProfileGroup title={t('usersPage.userAdd.tabs.user.generalData.title')}>
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.lastname')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            validate={((e) => checkLimitMin(2, e))}
                            record={formData}
                            field="lastname"
                            required={requiredFields.lastname}
                            error={errors.lastname}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.firstname')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            validate={((e) => checkLimitMin(2, e))}
                            record={formData}
                            field="firstname"
                            required={requiredFields.firstname}
                            error={errors.firstname}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.patronymic')}
                            setValue={(e) => {onHandleChange(30, e)}}
                            record={formData}
                            field="patronymic"
                            required={requiredFields.patronymic}
                            error={errors.patronymic}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.email')}
                            setValue={(e) => {onHandleChange(50, e)}}
                            validate={(e) => checkEmail(e)}
                            record={formData}
                            field="email"
                            required={requiredFields.email}
                            error={errors.email}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.school_bin')}
                            setValue={(e) => {onHandleChange(12, e, /^\d{0,12}$/)}}
                            validate={((e) => checkBIN(e))}
                            record={formData}
                            field="school_bin"
                            required={requiredFields.school_bin}
                            disabled={!needSchool}
                            error={errors.school_bin}
                        />
                        <MProfileInput
                            mode="edit"
                            placeholder={t('usersPage.userAdd.tabs.user.generalData.password')}
                            setValue={(e) => {onHandleChange(64, e)}}
                            validate={((e) => checkPassword(e))}
                            record={formData}
                            field="password"
                            required={requiredFields.password}
                            error={errors.password}
                        />
                        <div className="mt-2">{t('usersPage.userAdd.tabs.user.requiredText')}</div>
                    </MProfileGroup>
                    <MProfileGroup title={t('usersPage.userView.rolesData.title')} error={errors.role}>
                        <MCheckboxGroup
                            value={formData.role}
                            setValue={(value) => {
                                setFormData({
                                    ...formData,
                                    role: value,
                                    school_bin: value?.some(item => ['student', 'teacher', 'school_admin'].includes(item))
                                        ? formData.school_bin
                                        : null
                                })
                                const { role: keyForClear, ...other } = errors
                                setErrors(other)
                            }}
                            options={userRoleOptions?.map((item) => (
                                {
                                    label: t(`usersPage.search.filters.role.${item.value}`),
                                    value: item.value
                                }
                            ))}
                        />
                    </MProfileGroup>
                    {buttons}
                </div>
            </div>
        </Spinner>
    );
}

export default UserAdd;
