import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { saveFilter } from '../../../store/reducers/filterSlice';
import { useDispatch } from 'react-redux';

function PackageCard({img, price, type, src, searchSrc, isPriceHidden, searchFilter}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickSearch = (e) => {
        e.preventDefault()
        if (searchFilter) {
            dispatch(saveFilter({ filterName: searchFilter.filterName, filterValue: searchFilter.filterValue }))
        }
        navigate(searchSrc)
    }

    return (
        <div className="bg-card-bg rounded-lg mx-auto pb-8 w-[300px] tablet:w-[250px] desktop:w-[321px]">
            <div className={`h-[200px] flex items-center`}>
                <img src={img} alt={price} className="mx-auto"/>
            </div>
            {!isPriceHidden && (
                <>
                    <h3 className="mt-8 text-center font-bold text-lg">
                        {t('mainPage.packages.monthlyCost')}
                    </h3>
                    <h3 className="mb-8 text-center font-bold text-lg">
                        {price}
                    </h3>
                </>
            )}
            <div className="flex flex-col">
                <a href={src} target="_blank" rel="noreferrer" className="mx-6 mb-3 btn-primary">
                    {t('mainPage.packages.subscribe')}
                </a>
                <Link onClick={onClickSearch} className="mx-6 btn-secondary">
                    {t('mainPage.packages.viewBooks')}
                </Link>
            </div>
        </div>
    );
}

export default PackageCard;
