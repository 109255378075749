import HeaderInfo from "../../components/advantages/HeaderInfo";
import teacher from '../../assets/img/TeacherCircle.png'
import FooterInfo from "../../components/advantages/FooterInfo";
import Content from '../../assets/img/Content.png'
import ContentMobile from '../../assets/img/ContentMobile.png'
import LibraryLaptop from '../../assets/img/LibraryLaptop.png'
import LibraryMobile from '../../assets/img/LibraryMobile.png'
import Remote from '../../assets/img/Remote.png'
import RemoteMobile from '../../assets/img/RemoteMobile.png'
import LessTikTok from '../../assets/img/LessTikTok.png'
import LessTikTokMobile from '../../assets/img/LessTikTokMobile.png'
import AutoReview from '../../assets/img/AutoReview.png'
import AutoReviewMobile from '../../assets/img/AutoReviewMobile.png'
import Tablet from '../../assets/img/Tablet.png'
import TabletMobile from '../../assets/img/TabletMobile.png'
import OquWeb from '../../assets/img/OquWeb.png'
import OquWebMobile from '../../assets/img/OquWebMobile.png'
import Stats2 from '../../assets/img/Stats2.png'
import Stats2Mobile from '../../assets/img/Stats2Mobile.png'
import Searching from '../../assets/img/Searching.png'
import SearchingMobile from '../../assets/img/SearchingMobile.png'
import Wiki from '../../assets/img/Wiki.png'
import WikiMobile from '../../assets/img/WikiMobile.png'
import {useTranslation} from "react-i18next";

const TeacherAdvantage = () => {
    const {t} = useTranslation();
    return (
        <div>
            <HeaderInfo
                img={teacher}
                children={
                    <>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.header.text1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.header.text2')}</p>
                        <p>{t('teacherAdvantagePage.header.text3')}</p>
                    </>
                }
                title={t('teacherAdvantagePage.header.title')}
                bgColor={'teacher'}
            />
            <div
                className={'grid sm:grid-cols-1 desktop:grid-cols-2 gap-y-6 tablet:gap-y-7 desktop:gap-y-8 desktop:gap-x-[123px] p-3 tablet:p-5 desktop:p-[45px] font-medium'}>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Content} alt={'Content'}/>
                    <img className={'mb-4 desktop:hidden'} src={ContentMobile} alt={'Content'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.content1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.content2')}</p>
                        <p>{t('teacherAdvantagePage.main.content3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={LibraryLaptop} alt={'Library'}/>
                    <img className={'mb-4 desktop:hidden'} src={LibraryMobile} alt={'Library'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.library1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.library2')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.library3')}</p>
                        <p>{t('teacherAdvantagePage.main.library4')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Remote} alt={'Remote'}/>
                    <img className={'mb-4 desktop:hidden'} src={RemoteMobile} alt={'Remote'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.remote1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.remote2')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={LessTikTok} alt={'Less tik tok'}/>
                    <img className={'mb-4 desktop:hidden'} src={LessTikTokMobile} alt={'Less tik tok'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.lessTikTok1')}</p>
                        <p>{t('teacherAdvantagePage.main.lessTikTok2')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={AutoReview} alt={'Auto review'}/>
                    <img className={'mb-4 desktop:hidden'} src={AutoReviewMobile} alt={'Auto review'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.autoReview1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.autoReview2')}</p>
                        <p>{t('teacherAdvantagePage.main.autoReview3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Wiki} alt={'Wiki'}/>
                    <img className={'mb-4 desktop:hidden'} src={WikiMobile} alt={'Wiki'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.wiki1')}</p>
                        <p>{t('teacherAdvantagePage.main.wiki2')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Stats2} alt={'Statistics'}/>
                    <img className={'mb-4 desktop:hidden'} src={Stats2Mobile} alt={'Statistics'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.stats1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.stats2')}</p>
                        <p>{t('teacherAdvantagePage.main.stats3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Searching} alt={'Searching'}/>
                    <img className={'mb-4 desktop:hidden'} src={SearchingMobile} alt={'Searching'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.searching1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.searching2')}</p>
                        <p>{t('teacherAdvantagePage.main.searching3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={OquWeb} alt={'Any device'}/>
                    <img className={'mb-4 desktop:hidden'} src={OquWebMobile} alt={'Any device'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.oquWeb1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.oquWeb2')}</p>
                        <p>{t('teacherAdvantagePage.main.oquWeb3')}</p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Tablet} alt={'Integration'}/>
                    <img className={'mb-4 desktop:hidden'} src={TabletMobile} alt={'Integration'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>{t('teacherAdvantagePage.main.tablet1')}</p>
                        <p className={'mb-4'}>{t('teacherAdvantagePage.main.tablet2')}</p>
                        <p>{t('teacherAdvantagePage.main.tablet3')}</p>
                    </div>
                </div>
            </div>
            <FooterInfo
                color={'teacher'}
                children={<>
                    <p className={'mb-4'}>
                        {t('teacherAdvantagePage.footer.text1')}
                    </p>
                    <p className={'mb-4'}>
                        {t('teacherAdvantagePage.footer.text2')}
                    </p>
                    <p className={'mb-4'}>
                        {t('teacherAdvantagePage.footer.text3')}
                    </p>
                </>
                }
            />
        </div>
    )
}
export default TeacherAdvantage;