import React, {useState} from 'react';
import MIcon from "../m-icon/MIcon";

function MCollapse({title, children, ...props}) {
    const [isOpen, serIsOpen] = useState(false)
    return (
        <div className="py-2 w-full">
            <button className="flex justify-between w-full text-main-color font-bold" onClick={() => serIsOpen(!isOpen)}>
                <span>{title}</span>
                {isOpen ? <MIcon type={'arrowDown'} className={'rotate-180'}/> : <MIcon type={'arrowDown'}/>}
            </button>
            <div className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
                isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
            }`}>
                <div className="overflow-hidden mt-2">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default MCollapse;
