export function filterAndModifyUsers(data) {
    const rolesToRemove = ["superadmin", "expert_teacher", "expert_student", "private_user"];
    const keepRoles = ["teacher", "student", "school_admin"];

    return data.map(user => {
        const hasKeepRole = user.roles.some(role => keepRoles.includes(role));
        if (hasKeepRole) {
            return {
                ...user,
                roles: user.roles.filter(role => keepRoles.includes(role))
            };
        }
        if (!user.roles.some(role => rolesToRemove.includes(role))) {
            return user;
        }
        return null;
    }).filter(user => user !== null);
}