import MInput from "../../components/common/m-input/MInput";
import MIcon from "../../components/common/m-icon/MIcon";
import MTextarea from "../../components/common/m-textarea/MTextarea";
import {usePostEmailSupportMutation} from "../../api/GeneralApi/generalService";
import React from "react";
import MError from "../../components/common/m-error/MError";
import {authRegex} from "../../models/auth";
import {setIsError, setIsSuccess} from "../../store/reducers/notificationSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import useFormData from "../../hooks/useFormData";


const Support = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [sendEmail] = usePostEmailSupportMutation();

    const resetData = () => {
        setErrors({})
        setFormData({
            name: '',
            email: '',
            text: ''
        })
    }

    const {formData, errors, onHandleChange, setErrors, setFormData} = useFormData({
        name: '',
        email: '',
        text: ''
    });


    const handleEmailSend = async (e) => {
        e.preventDefault();

        const errors = {};
        const { name, email, text } = formData;

        if (!name.trim()) {
            errors.name = 'general';
        }

        if (!email.trim()) {
            errors.email = 'general';
        } else if (!authRegex.email.test(email)) {
            errors.email = 'invalidEmail';
        }

        if (!text.trim()) {
            errors.text = 'general';
        } else if (text.length < 30) {
            errors.text = 'minText30';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            resetData();
            const result = await sendEmail({
                initiator_name: name,
                initiator_email: email,
                text_to_support: text
            });
            if (result.error) {
                console.error(result.error);
                dispatch(setIsError(t(`general.models.serverErrors.emailSupport.${result.error.data?.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
            } else {
                dispatch(setIsSuccess({successText: t('supportPage.successMessage')}))
            }
        }
    };

    return (
        <div className={'p-4 tablet:p-5 desktop:p-[45px] pb-[92px]'}>
           <h1 className={'mb-7 text-[32px] font-medium'}>{t('supportPage.support')}</h1>
            <div className={'flex flex-col tablet:flex-row gap-8 tablet:gap-5 desktop:gap-[88px]'}>
                <form className={'p-6 desktop:p-10 rounded-lg transition-all flex flex-col gap-3 bg-card-bg flex-1'}>
                    <MInput placeholder={t('supportPage.name')} setValue={(e)=>{onHandleChange(30, e)}} value={formData.name} name={'name'} error={errors.name}/>
                    {errors.name && <MError error={errors.name}/>}
                    <MInput placeholder={t('supportPage.email')} setValue={(e)=>{onHandleChange(50, e)}} value={formData.email} type={'email'} name={'email'} error={errors.email}/>
                    {errors.email && <MError error={errors.email}/>}
                    <MTextarea placeholder={t('supportPage.textareaPlaceholder')} className={'mb-2'} setValue={(e)=>{onHandleChange(1000, e)}} value={formData.text} name={'text'} error={errors.text}/>
                    {errors.text && <MError error={errors.text}/>}
                    <button
                        className={'btn-primary w-fit'}
                        onClick={handleEmailSend}
                    >
                        {t('supportPage.send')}
                    </button>
                </form>
                <div className={'flex-1 gap-4 flex flex-col'}>
                    <p className={'text-2xl font-medium'}>{t('supportPage.socials')}</p>
                    <div className={'flex gap-4 mb-4'}>
                        <a href="https://www.facebook.com/profile.php?id=61564933192780&mibextid=LQQJ4d">
                            <MIcon
                                type={'facebookFilled'}
                                className={'text-main-color w-[67px] h-[67px] flex items-center justify-center rounded-lg border-[1px] border-[rgba(0,0,0,0.1)] cursor-pointer'}
                            />
                        </a>
                        <a href="https://www.instagram.com/oqulyqtar2024/">
                            <MIcon
                                type={'instagram'}
                                className={'text-main-color w-[67px] h-[67px] flex items-center justify-center rounded-lg border-[1px] border-[rgba(0,0,0,0.1)] cursor-pointer'}
                            />
                        </a>
                        <a href="https://youtube.com/@oqulyqtar?si=m799mYnBx6nmiebH">
                            <MIcon
                                type={'youtubeFilled'}
                                className={'text-main-color w-[67px] h-[67px] flex items-center justify-center rounded-lg border-[1px] border-[rgba(0,0,0,0.1)] cursor-pointer'}
                            />
                        </a>
                        <a href="https://www.tiktok.com/@oqulyqtar?_t=8p3L8pGJuEw&_r=1">
                            <MIcon
                                type={'tiktokFilled'}
                                className={'text-main-color w-[67px] h-[67px] flex items-center justify-center rounded-lg border-[1px] border-[rgba(0,0,0,0.1)] cursor-pointer'}
                            />
                        </a>
                    </div>
                    <p className={'text-2xl font-medium'}>{t('supportPage.phones')}</p>
                    <div className={'flex flex-col gap-2'}>
                        <p>+7 771 558-58-28</p>
                        <p>+7 771 722-72-71</p>
                        <p>+7 771 722-73-27</p>
                    </div>
                    <p className={'text-2xl font-medium'}>{t('supportPage.email')}</p>
                    <p>info@oqulyqtar.kz</p>
                </div>
            </div>
        </div>
    )
}
export default Support;