import React from 'react';
import PackageCarousel from './PackageCarousel';
import { useTranslation } from 'react-i18next';

function Packages({isPriceHidden, className}) {
    const {t} = useTranslation();
    return (
        <div id={'packages-section'} className={`flex flex-col desktop:flex-row items-center desktop:items-start my-20 relative ${className}`}>
            <div className="desktop:w-1/4 flex flex-col justify-center w-full tablet:max-w-[1032px] desktop:max-w-[100%]">
                <h5 className="text-main-color text-2xl mb-6 font-medium desktop:mx-0">
                    {t('mainPage.packages.packagesAndPricesTitle')}
                </h5>
                <p className="mb-6 desktop:mx-0">
                    {t('mainPage.packages.packagesAndPricesDescription')}
                </p>
            </div>
            <div className="w-[310px] tablet:w-[780px] desktop:w-[1024px] mx-auto">
                <PackageCarousel isPriceHidden={isPriceHidden}/>
            </div>
        </div>
    );
}

export default Packages;
