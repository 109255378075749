const DeleteCircleBlue = ({className}) => {
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="12" fill="#0080C6"/>
                <path d="M15.5361 8.46436L8.46506 15.5354" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M15.5361 15.5356L8.46507 8.46458" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        </span>
    )
}
export default DeleteCircleBlue;