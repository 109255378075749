import usePagination, {DOTS} from "./usePagination";
import {useState} from "react";
import MIcon from "../m-icon/MIcon";
import MTooltip from "../m-tooltip/MTooltip";

export function MPagination({
                               totalCount,
                               pageSize,
                               siblingCount = 1,
                                setCurrent,
                                currentPage
                           }) {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    const [hoveredIndex, setHoveredIndex] = useState(null);

    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
        return null;
    }

    return (
        <div className="justify-end mx-auto flex w-full">
            <div className="justify-center items-center gap-x-3 flex">
                {paginationRange &&
                    paginationRange.map((pageNumber, index) => {
                        const isBefore = paginationRange[index - 1] < currentPage;
                        if (pageNumber === DOTS) {
                            return <MTooltip tooltip={`${isBefore? 'Предыдущие' : 'Следующие'} ${siblingCount + 2} ${siblingCount<3 ? 'страницы' : 'страниц' }`} key={index}>
                                <div
                                    className={`h-5 w-5 text-center ${hoveredIndex === index && "flex justify-center"} cursor-pointer`}
                                    onClick={() => {
                                        isBefore ? setCurrent(currentPage - (siblingCount + 2)) : setCurrent(currentPage + (siblingCount + 2))
                                    }}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >{
                                    (hoveredIndex === index ? (isBefore ?
                                        <MIcon type='doubleArrowRight' className={'rotate-180 text-blue3'}/> :
                                        <MIcon type='doubleArrowRight' className={'text-blue3'}/>) : '...')

                                }</div>
                            </MTooltip>

                        }
                        const isActiveButton = currentPage === pageNumber;

                        return (
                            <button
                                key={index}
                                className={`px-3 py-[6px] border border-blue4 rounded-lg text-blue3 font-bold ${
                                    isActiveButton ? 'bg-blue3 text-white' : ''
                                }`}
                                onClick={() => {
                                    setCurrent(pageNumber)
                                }}
                            >
                                {pageNumber}
                            </button>
                        );
                    })}
            </div>
        </div>
    );
}

export default MPagination;