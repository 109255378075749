import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice'
import authReducer from './reducers/authSlice'
import { api } from '../api/api';
import notificationReducer from "./reducers/notificationSlice";
import filterReducer from "./reducers/filterSlice";
import spinnerReducer from "./reducers/spinnerSlice";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    user: userReducer,
    auth: authReducer,
    notification: notificationReducer,
    filter: filterReducer,
    spinner: spinnerReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});
