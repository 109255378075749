import React from 'react';
import {useTranslation} from "react-i18next";

function PackageItemBooks(props) {
    const {t} = useTranslation();
    return (
        <table className="w-full my-2">
            <thead>
                <tr>
                    <td className="p-1 border border-gray-300 border-l-0 text-center font-bold">{t('currentPackagePage.table.subjects')}</td>
                    {[...Array(11)].map((_, index) => (
                        <td key={index} className="p-1 bg-card-bg border border-gray-300 text-center">
                            {`${t('currentPackagePage.table.enClass').startsWith('currentPackagePage') ? '' : t('currentPackagePage.table.enClass')} ${index+1} ${t('currentPackagePage.table.class')}`}
                        </td>
                    ))}
                    <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">{t('currentPackagePage.table.total')}</td>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.biology')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">9</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.geography')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">14</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.art')}</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">6</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.informatics')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">4</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.history')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">6</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">6</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">25</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.Kazakh-literature')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">9</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.Kazakh-language')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">14</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.maths')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center "></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">3</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">6</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">8</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">27</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.military')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">4</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.fundamentals-of-law')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center "></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">10</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.world')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">2</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.russian')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">3</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">5</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">20</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.labor-training')}</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">3</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">17</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.physics')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center "></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">1</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">13</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('general.models.categories.chemistry')}</td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center "></td>
                <td className="p-1 border border-gray-300 text-center"></td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">2</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 border border-gray-300 text-center bg-card-bg">4</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">14</td>
            </tr>
            <tr>
                <td className="p-1 border border-gray-300 text-center border-l-0">{t('currentPackagePage.table.total')}</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">5</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">8</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">8</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold"></td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">12</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">16</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">21</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">19</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">18</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">40</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold">41</td>
                <td className="p-1 bg-card-bg border border-gray-300 text-center font-bold border-r-0">188</td>
            </tr>
            </tbody>
        </table>
    );
}

export default PackageItemBooks;
