import React, { useState } from 'react';
import packageMob from '../../assets/img/PackageMob.png';
import packageTab from '../../assets/img/PackageTab.png';
import packageDesk from '../../assets/img/PackageDesk.png';
import PackageItem from './PackageItem';
import {useTranslation} from "react-i18next";
import {useGetPublisherListQuery} from "../../api/LibraryApi/LibraryService";

function PackagePage(props) {
    const {data} = useGetPublisherListQuery();
    const [currentPublisher, setCurentPublisher] = useState({
        name: 'Мектеп',
        publisher_id: 1
    })
    const {t} = useTranslation();
    return (
        <div className="flex flex-col mt-6 p-5">
            <h5 className="text-3xl mb-6">

                    {!t('currentPackagePage.mainText').startsWith('currentPackagePage') && t('currentPackagePage.mainText')}
                    {" "}
                    {t(`currentPackagePage.publishers.${currentPublisher.name}`)}
                    {" "}
                    {!t('currentPackagePage.afterName').startsWith('currentPackagePage') && t('currentPackagePage.afterName')}
            </h5>
            <div className="flex flex-col desktop:flex-row">
                <div className="desktop:order-2">
                    <img src={packageMob} alt="book" className="w-svw tablet:hidden"/>
                    <img src={packageTab} alt="book" className="hidden tablet:w-svw tablet:block desktop:hidden"/>
                    <img src={packageDesk} alt="book" className="hidden desktop:block desktop:w-svw desktop:order-2"/>
                    <div className='hidden desktop:block'>
                        <PackageItem publisher={currentPublisher}/>
                    </div>
                </div>
                <div className="flex flex-col tablet:flex-row desktop:flex-col my-2 tablet:border tablet:border-white tablet:border-b-gray-200 desktop:order-1 desktop:border-0 desktop:mr-4 desktop:h-screen">
                    {data?.publishers?.map(publisher => {
                        return (
                            <div
                                key={publisher.publisher_id}
                                className={`${publisher.name === currentPublisher.name ? 'bg-card-bg' : ''} p-2 hover:cursor-pointer border border-white border-b-gray-200 tablet:border-0 desktop:border desktop:border-white desktop:border-b-gray-200`}
                                onClick={() => {
                                    setCurentPublisher(publisher)
                                }}
                            >
                                {!t('currentPackagePage.mainText').startsWith('currentPackagePage') && t('currentPackagePage.mainText')} {t(`currentPackagePage.publishers.${publisher.name}`)} {!t('currentPackagePage.afterName').startsWith('currentPackagePage') && t('currentPackagePage.afterName')}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="desktop:hidden">
                <PackageItem publisher={currentPublisher}/>
            </div>
        </div>
    );
}

export default PackagePage;
