import { createSlice } from '@reduxjs/toolkit';

const auth = createSlice({
    name: 'auth',
    initialState: {
        isAuth: false,
        isLoginTypeModal: false,
        token: null
    },
    reducers: {
        setIsAuth(state, action) {
            state.isAuth = action.payload
        },
        setCredentials(state,action){
            state.token = action.payload
        },
        setIsLoginTypeModal(state, action) {
            state.isLoginTypeModal = action.payload
        },
        logOut(state,action) {
            state.isAuth = false;
            state.token = '';
            localStorage.clear();
        }
    }
})

export default auth.reducer
export const { setIsAuth,setCredentials, setIsLoginTypeModal,logOut } = auth.actions;
