import React from 'react';
import downloadIcon from '../../assets/icons/download.png';
import { useTranslation } from 'react-i18next';

function FaqCard({title, fileName, src, ...props}) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col tablet:flex-row my-6">
            <div className="mb-6 tablet:w-1/3">
                <h3 className="text-xl mb-2">{title}</h3>
                <div className="flex flex-row">
                    <div className="flex justify-center items-center pr-4">
                        <img src={downloadIcon} alt="download" className="hover:cursor-pointer"/>
                    </div>
                    <div>
                        <span>{fileName}</span>
                        <div className="mt-2">
                            <span className="text-main-color mr-2 hover:cursor-pointer">{t('faqPage.download')}</span>
                            <span className="text-gray-400 mr-2">137 {t('faqPage.kb')}</span>
                            <span className="text-gray-400">.mp4</span>
                        </div>
                    </div>

                </div>
            </div>
            <video id="player" playsInline controls className="tablet:w-2/3 desktop:w-1/2">
                <source src={src} type="video/mp4" />
            </video>
        </div>
    );
}

export default FaqCard;
