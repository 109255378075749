import {useRegisterUserAdminMutation} from "../../../api/AuthApi/authService";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Spinner from "../../../components/common/spinner/spinner";
import useDeviceType from "../../../hooks/useDeviceType";

const UserAdded = ({username, firstname, lastname, index, setCount, setIsError, setShadowCount}) => {
    const [registerUser] = useRegisterUserAdminMutation();
    const school_bin = useSelector(state => state.user.school_bin);
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const {isMobile} = useDeviceType();
    const register = async () => {
        const result = await registerUser({
            username: username.toLowerCase(),
            firstname: firstname,
            lastname: lastname,
            role: ['student'],
            email: username.toLowerCase(),
            school_bin: school_bin
        })
        if (result.error) {
            setError('Ошибка')
            setIsError(true)
        } else {
            setCount(prev => prev + 1)
        }

        setShadowCount(prev=>prev+1)
        setIsLoading(false)
    }
    useEffect(() => {
        setIsLoading(true)
        register()
    }, []);

    return (
        <Spinner spinning={isLoading} className={'w-[12px] h-[12px]'}>
            <div className={'flex font-medium gap-3 tablet:gap-[29px] py-2'}>
                <p className={'min-w-[40px]'}>{index + 1}.</p>

                <p className={'min-w-[172px]'}>{firstname}</p>
                {!isMobile && <p className={'min-w-[190px]'}>{lastname}</p>}

                <p className={`min-w-[66px] ${error ? 'text-red2' : 'text-green2'}`}>
                    {isLoading ? '' : error ? error : 'Добавлен'}
                </p>
            </div>
        </Spinner>
    )
}
export default UserAdded;