import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdvantagesCard from '../../main-page/advantages/AdvantagesCard';
import school from '../../../assets/img/School.png'
import teacher from '../../../assets/img/Teacher.png'
import student from '../../../assets/img/Student.png'
import privateUser from '../../../assets/img/Private-user.png'
import { useTranslation } from 'react-i18next';

function CardsCarousel(props) {
    const {t} = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 834,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                <div>
                    <AdvantagesCard
                        img={school}
                        title={t('mainPage.advantages.school.title')}
                        text={t('mainPage.advantages.school.text')}
                        src="/advantages/school"
                    />
                </div>
                <div>
                    <AdvantagesCard
                        img={teacher}
                        title={t('mainPage.advantages.teacher.title')}
                        text={t('mainPage.advantages.teacher.text')}
                        src="/advantages/teacher"
                    />
                </div>
                <div>
                    <AdvantagesCard
                        img={student}
                        title={t('mainPage.advantages.student.title')}
                        text={t('mainPage.advantages.student.text')}
                        src="/advantages/student"
                    />
                </div>
                <div>
                    <AdvantagesCard
                        img={privateUser}
                        title={t('mainPage.advantages.privateUser.title')}
                        text={t('mainPage.advantages.privateUser.text')}
                        src="/advantages/privateUser"
                    />
                </div>
            </Slider>
        </>
    );
}

export default CardsCarousel;
