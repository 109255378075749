import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useLazyGetUserViewByAdminQuery} from '../../api/UserApi/userView';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {setIsError, setIsSuccess} from "../../store/reducers/notificationSlice";
import MIcon from '../../components/common/m-icon/MIcon'
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import MProfileList from '../../components/common/m-profile-list/MProfileList';

import MModalWarning from "../../components/common/m-modal-warning/MModalWarning";
import {useAdminDeleteUserMutation} from "../../api/AuthApi/authService";
import Spinner from '../../components/common/spinner/spinner';
import { userRoleOptions } from '../../models/users';
import {useLazyGetSubsQuery} from "../../api/SubsApi/SubsService";

function UserView(props) {
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const myUsername = useSelector(state => state.user.username)
    const navigate = useNavigate()
    const { username, role } = useParams()
    const {t} = useTranslation()
    const [getSubs] = useLazyGetSubsQuery();
    const [getUserData] = useLazyGetUserViewByAdminQuery()
    const [deleteUser] = useAdminDeleteUserMutation()
    const [userInfo, setUserInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const handleDeleteUser = async () => {
        setIsLoading(true)
        const result = await deleteUser({
            "username": userInfo.email,
            "access_token":token
        })
        setIsLoading(false)
        if(result.error){
            dispatch(setIsError(t(`general.models.serverErrors.adminUserDelete.${result.error.data.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
        } else {
            dispatch(setIsSuccess({successText: 'Пользователь успешно удалён', successNavigation: '/users'}))
        }
    }

    useEffect(() => {
        if (username) {
            getUserInfo()
        }
    }, [username])

    const getUserInfo = async () => {
        setIsLoading(true)
        const userData = await getUserData({ username })
        setIsLoading(false)
        const { data, error } = userData
        if (error) {
            dispatch(setIsError(t(`general.models.serverErrors.UView.${error.data?.error_code}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else {
            setUserInfo(data.user)
           const {data:subsData, error:subError} = await getSubs(`?username=${username}`)
            if(subError) {
                dispatch(setIsError(t(`general.models.serverErrors.getSubs.${error.data?.error_code}`, `${error.data?.error_code}: ${error.data?.message}`)))
            } else {
                setUserInfo({...data.user, subs:subsData.subscriptions.subscriptions})
            }
        }
    }

    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4 mt-4">
        <button
            className="btn-edit flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4"
            onClick={() => navigate(`/users/edit/${username}/${role}`)}
        >
            {t('usersPage.userView.buttons.editUser')}
        </button>
        {/*<button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => {}}
        >
            {t('usersPage.userView.buttons.changePassword')}
        </button>*/}
        {myUsername && (myUsername !== username) && (
            <button
                className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center border border-red3 rounded-lg text-sm text-red3 font-medium whitespace-nowrap"
                onClick={() => {
                    setIsOpen(prev => !prev)
                }}
            >
                <MIcon
                    type="close"
                    className={'text-red3'}
                />
                {t('usersPage.userView.buttons.deleteUser')}
            </button>
        )}
    </div>

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <h1 className="text-3xl m-2">{t('usersPage.header')}</h1>
                <div className="h-[32px] text-2xl m-2 font-semibold mt-4">{userInfo?.fullname}</div>
                <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full">
                    <MProfileGroup title={t('usersPage.userView.generalData.title')}>
                        <MProfileInput label={t('usersPage.userView.generalData.fields.email')} record={userInfo} field='email'/>
                    </MProfileGroup>
                    <MProfileGroup title={t('usersPage.userView.schoolData.title')}>
                        <MProfileInput label={t('usersPage.userView.schoolData.fields.school_bin')} record={userInfo} field='school_bin'/>
                        <MProfileInput label={t('usersPage.userView.schoolData.fields.school_name')} record={userInfo} field='school_name'/>
                        <MProfileInput label={t('usersPage.userView.schoolData.fields.school_city')} record={userInfo} field='school_city'/>
                    </MProfileGroup>
                    <MProfileGroup title={t('usersPage.userView.rolesData.title')}>
                        <MProfileList list={userRoleOptions.filter((option) => userInfo?.roles?.includes(option.value))?.map((option) => t(`usersPage.search.filters.role.${option.value}`))}/>
                    </MProfileGroup>
                    <MProfileGroup title={t('usersPage.userView.subscriptionsData.title')}>
                        <MProfileList list={userInfo?.subs?.length ? userInfo.subs.map(item=>(`Мектеп базовый - ${item.start_date}-${item.end_date}`)) : []}/>
                    </MProfileGroup>
                    {buttons}
                </div>
                <MModalWarning setIsOpen={setIsOpen} isOpen={isOpen} text={'Вы действительно хотите удалить пользователя?'} onAccept={handleDeleteUser}/>
            </div>
        </Spinner>
    );
}

export default UserView;
