import HeaderInfo from "../../components/advantages/HeaderInfo";
import student from '../../assets/img/StudentCircle.png'
import FooterInfo from "../../components/advantages/FooterInfo";
import Content from '../../assets/img/Content.png'
import ContentMobile from '../../assets/img/ContentMobile.png'
import Education from '../../assets/img/Education.png'
import EducationMobile from '../../assets/img/EducationMobile.png'
import Backpack from '../../assets/img/Backpack.png'
import BackpackMobile from '../../assets/img/BackpackMobile.png'
import Stats from '../../assets/img/Stats.png'
import StatsMobile from '../../assets/img/StatsMobile.png'
import Searching2 from '../../assets/img/Searching2.png'
import Searching2Mobile from '../../assets/img/Searching2Mobile.png'
import LibraryLaptop from '../../assets/img/LibraryLaptop.png'
import LibraryMobile from '../../assets/img/LibraryMobile.png'
import OquWeb from '../../assets/img/OquWeb.png'
import OquWebMobile from '../../assets/img/OquWebMobile.png'
import Tasks from '../../assets/img/Tasks.png'
import Tablet from '../../assets/img/Tablet.png'
import TabletMobile from '../../assets/img/TabletMobile.png'
import Searching from '../../assets/img/Searching.png'
import SearchingMobile from '../../assets/img/SearchingMobile.png'
import {useTranslation} from "react-i18next";

const StudentAdvantage = () => {
    const {t} = useTranslation();
    return (
        <div>
            <HeaderInfo
                img={student}
                children={
                    <>
                        <p className={'mb-4'}>{t('studentAdvantagePage.header.text1')}</p>
                        <p>{t('studentAdvantagePage.header.text2')}</p>
                        <p>{t('studentAdvantagePage.header.text3')}</p>
                    </>
                }
                title={t('studentAdvantagePage.header.title')}
                bgColor={'student'}
            />
            <div
                className={'grid sm:grid-cols-1 desktop:grid-cols-2 gap-y-6 tablet:gap-y-7 desktop:gap-y-8 desktop:gap-x-[123px] p-3 tablet:p-5 desktop:p-[45px] font-medium'}>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Backpack} alt={'Backpack'}/>
                    <img className={'mb-4 desktop:hidden h-[252px] w-[285px]'} src={BackpackMobile} alt={'Backpack'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.backpack1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.backpack2')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.backpack3')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.backpack4')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={LibraryLaptop} alt={'Library'}/>
                    <img className={'mb-4 desktop:hidden'} src={LibraryMobile} alt={'Library'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.library1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.library2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.library3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Stats} alt={'Stats'}/>
                    <img className={'mb-4 desktop:hidden'} src={StatsMobile} alt={'Stats'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.stats1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.stats2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.stats3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Searching} alt={'Searching'}/>
                    <img className={'mb-4 desktop:hidden'} src={SearchingMobile} alt={'Searching'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.searching1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.searching2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.searching3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Tasks} alt={'Tasks'}/>
                    <img className={'mb-4 w-[285px] h-[253px] desktop:hidden'} src={Tasks} alt={'Tasks'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.tasks1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.tasks2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.tasks3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Education} alt={'Education'}/>
                    <img className={'mb-4 desktop:hidden'} src={EducationMobile} alt={'Education'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.education1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.education2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.education3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Tablet} alt={'Tablet'}/>
                    <img className={'mb-4 desktop:hidden'} src={TabletMobile} alt={'Tablet'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.tablet1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.tablet2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.tablet3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Searching2} alt={'Searching'}/>
                    <img className={'mb-4 desktop:hidden'} src={Searching2Mobile} alt={'Searching'}/>
                    <div>
                    <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.Searching1')}
                        </p>
                        <p className={'mb-4'}>
                            {t('studentAdvantagePage.main.Searching2')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.Searching3')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={OquWeb} alt={'Any device'}/>
                    <img className={'mb-4 desktop:hidden'} src={OquWebMobile} alt={'Any device'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.oquWeb1')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.oquWeb2')}
                        </p>
                    </div>
                </div>
                <div className={'tablet:flex desktop:block gap-4'}>
                    <img className={'mb-4 hidden desktop:block'} src={Content} alt={'Content'}/>
                    <img className={'mb-4 desktop:hidden'} src={ContentMobile} alt={'Content'}/>
                    <div>
                        <p className={'font-bold text-[20px] tablet:text-[24px] text-main-color mb-8'}>
                            {t('studentAdvantagePage.main.content1')}
                        </p>
                        <p>
                            {t('studentAdvantagePage.main.content2')}
                        </p>
                    </div>
                </div>
            </div>
            <FooterInfo
                color={'student'}
                children={
                    <div>
                        <p className={'mb-4'}>{t('studentAdvantagePage.footer.text1')}</p>
                        <p className={'mb-4'}>{t('studentAdvantagePage.footer.text2')}</p>
                        <p className={'mb-6'}>{t('studentAdvantagePage.footer.text3')}</p>
                        <p className={'mb-6'}>{t('studentAdvantagePage.footer.text4')}</p>
                    </div>
                }
            />
        </div>
    )
}
export default StudentAdvantage;