import React, {useState} from 'react';
import EyeOpened from "../../../assets/img/Eye opened.png";
import EyeClosed from "../../../assets/img/Eye Closed.png";

function MInput({placeholder, value, setValue, type = 'text', error, name, ...props}) {
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    return (
        <>
            {type==='password' ? (
                <div className={'relative'}>
                    <input
                        type={isPasswordShown ? 'text' : 'password'}
                        placeholder={placeholder}
                        value={value}
                        name={name}
                        onChange={setValue}
                        className={`p-3 border w-full pr-11 border-gray-400 rounded-xl m-1 ${error && 'border-2 border-red-700'} ${props.className}`}
                    />

                    <img
                        className={'absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer'}
                        src={isPasswordShown ? EyeOpened : EyeClosed}
                        alt='see password'
                        onClick={() => setIsPasswordShown(prev => !prev)}
                    />
                </div>
            ) : (
                <input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    onChange={setValue}
                    className={`p-3 border border-gray-400 w-full rounded-xl m-1 ${error && 'border-2 border-red-700'} ${props.className}`}
                />
            )}

        </>
    );
}

export default MInput;
