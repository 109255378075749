const TabItem = ({ id, onClick, children }) => (
    <div
        className="tab-panel"
        role="tabpanel"
        aria-labelledby={`tab-${id}`}
        id={`panel-${id}`}
        onClick={onClick}
    >
        {children}
    </div>
)

export default TabItem
