import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import MModal from '../../../common/m-modal/MModal';
import MInput from '../../../common/m-input/MInput';
import MCheckbox from '../../../common/m-checkbox/MCheckbox';
import MError from '../../../common/m-error/MError';

import { setActiveRole, setUser } from '../../../../store/reducers/userSlice';
import { setCredentials, setIsAuth } from '../../../../store/reducers/authSlice';

import { useAuthUserMutation } from '../../../../api/AuthApi/authService';

import { useTranslation } from 'react-i18next';
import { useLazyGetUserViewQuery } from '../../../../api/UserApi/userView';
import Cookies from 'universal-cookie';
import { authRegex } from "../../../../models/auth";
import {setIsError} from "../../../../store/reducers/notificationSlice";
import Spinner from '../../../common/spinner/spinner';
import useFormData from "../../../../hooks/useFormData";

function LoginModal({ isLoginModal, setIsLoginModal, setIsClicked, setIsForgotModal, setIsChooseRoleModal }) {
    const dispatch = useDispatch();
    const [onAuthUser] = useAuthUserMutation();
    const [getUserData] = useLazyGetUserViewQuery();

    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const {formData, errors, onHandleChange, setErrors, setFormData} = useFormData({
        username: '',
        password: '',
        checkboxState: false
    });


    const onForgotPasswordClick = () => {
        setIsForgotModal(true);
        setIsLoginModal(false);
        setFormData({
            username: '',
            password: '',
            checkboxState: false
        });
    };

    const onModalClose = () => {
        setIsLoginModal(false);
        setIsClicked(false);
        setFormData({
            username: '',
            password: '',
            checkboxState: false
        });
        setErrors({});
    }

    const onHandleAuthButton = useCallback(async (e)=>{
        e.preventDefault();
        //TODO Доделать логику "запомнить меня на данном устройстве"
        const { username, password, checkboxState } = formData;

        const errors = {};

        if (!password.trim()) {
            errors.password = 'general';
        } else if(!authRegex.password.test(password)){
            errors.other = 'invalidPassword';
        }

        if (!username.trim()) {
            errors.username = 'general';
        } else if (!authRegex.email.test(username)) {
            errors.username = "invalidLogin"
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            setIsLoading(true)
            const result = await onAuthUser({ username, password, grant_type: 'password' });
            if (result.error) {
                if(result.error.data?.error_code === 10100) {
                    setErrors(prev=>({...prev, other: 'wrongData'}))
                } else {
                    onModalClose()
                    dispatch(setIsError(t(`general.models.serverErrors.auth.${result.error?.error_code}`, `${result.error?.error_code}: ${result.error?.message}`)))
                    console.error(result.error);
                }
            } else {
                dispatch(setCredentials(result.data.access_token));
                cookies.set('jwt_auth', result.data.access_token, { httpOnly: true });
                localStorage.setItem('jwt_refresh', JSON.stringify(result.data.refresh_token))
                if(result.data.roles.length>1) {
                    dispatch(setUser({roles: result.data.roles, username: username}));
                    setIsChooseRoleModal(true)
                    onModalClose()
                } else {
                    dispatch(setActiveRole(result.data.roles[0]))
                    dispatch(setIsAuth(true));
                    const userData = await getUserData({ username, role: result.data.roles[0] });
                    if (userData.error) {
                        console.error(userData.error);
                        dispatch(setIsError(t(`general.models.serverErrors.UView.${result.error?.error_code}`, `${result.error?.error_code}: ${result.error?.message}`)))
                    } else {
                        const user = { ...userData.data.user };
                        localStorage.setItem('user',JSON.stringify({...user, activeRole: user.roles[0]}))
                        dispatch(setUser({...user, activeRole: user.roles[0]}));
                    }
                    onModalClose()
                }
            }
            setIsLoading(false)
        }
    },[cookies, dispatch, formData, getUserData, onAuthUser, setErrors, setIsChooseRoleModal])

    useEffect(() => {
        const listener = event => {
            if (event.key === "Enter" || event.key === "NumpadEnter") {
                onHandleAuthButton(event)
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [onHandleAuthButton]);

    return (
        <MModal
            active={isLoginModal}
            setActive={setIsLoginModal}
            onCloseModal={() => {
                setFormData({
                    username: '',
                    password: '',
                    checkboxState: false
                });
                setErrors({});
            }}
        >
            <Spinner spinning={isLoading}>
                <div className="flex flex-col max-w-[436px]">
                <h5 className="text-xl font-bold mx-auto my-3">{t('general.header.loginModal.title')}</h5>

                <MInput
                    placeholder={t('general.header.loginModal.loginPlaceholder')}
                    value={formData.username}
                    setValue={(e) => onHandleChange(50, e)}
                    name="username"
                    type='email'
                    error={errors.username || errors.other}
                />

                {errors.username && <MError error={errors.username}/>}

                    <MInput
                        placeholder={t('general.header.loginModal.passPlaceholder')}
                        type={'password'}
                        className={'w-[calc(100%-8px)]'}
                        value={formData.password}
                        setValue={(e) => onHandleChange(64, e)}
                        name="password"
                        error={errors.password || errors.other}
                    />

                {(errors.password || errors.other) && <MError error={errors.other || errors.password}/>}

                <MCheckbox
                    label={t('general.header.loginModal.rememberMe')}
                    value={formData.checkboxState}
                    setValue={() => setFormData(({
                        ...formData,
                            checkboxState: !formData.checkboxState
                        }))}
                    />

                    <button
                        className="btn-primary my-2"
                        onClick={onHandleAuthButton}
                    >
                        {t('general.header.loginModal.enter')}
                    </button>

                    <button
                        className="btn-secondary"
                        onClick={onForgotPasswordClick}
                    >
                        {t('general.header.loginModal.forgotPass')}
                    </button>
                </div>
            </Spinner>
        </MModal>
);
}

export default LoginModal;
