import React from 'react';
import { useTranslation } from 'react-i18next';

function TableInputFilter({ name, placeholder, label, setState, state, tPath, regexTemplate, max=1000, ...props }) {
    const {t} = useTranslation();
    return (
        <div className={`flex flex-col box-border ${props.className}`}>
            <label className="text-xs font-normal text-black/[0.5] my-2">{label || t(`${tPath}.${name}.label`)}</label>
            <input
                placeholder={placeholder}
                value={state[name] || ''}
                onChange={e => {
                    const value = e.target.value
                    if(max>=e.target.value.length) {
                        if (regexTemplate) {
                            if (regexTemplate.test(value)) {
                                setState({ ...state, [name]: value})
                            }
                        } else {
                            setState({ ...state, [name]: value})
                        }
                    }
                }}
                className="h-10 rounded-lg p-2 text-sm border border-black/[0.1]"
            />
        </div>
    );
}

export default TableInputFilter;
