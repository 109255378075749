import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoginTypeModal } from '../../store/reducers/authSlice';

function Book(props) {
    const location = useLocation();
    const isAuth = useSelector(state=> state.auth.isAuth);
    const dispatch = useDispatch();
    const iframeRef = useRef(null);

    useEffect(() => {
        if(!isAuth) {
            dispatch(setIsLoginTypeModal(true));
        }
    }, []);

    useEffect(() => {
        if (iframeRef.current) {
            setIframeStyles()
        }
    }, [iframeRef.current])

    const setIframeStyles = () => {
        try {
            const bookIframe = window.frames['book-iframe'].document
            if (bookIframe) {
                var style = document.createElement('style');
                style.textContent = '.math-keyboard-wrapper.opiq-math-keyboard{top: 1px;bottom:unset!important};'
                    + 'html:not(.oui-app-page-without-main-menu) #oui-page-header{top:0!important}#oui-header{display:none!important}'
                bookIframe?.head?.appendChild(style);
            }
        }
        catch (e) {}
    }

    return (
        <>
            {
               isAuth
                   ? <iframe ref={iframeRef} name="book-iframe" title="site" src={location.state.url} className="relative w-[100vw] h-[150vh] box-border overflow-x-hidden" allowFullScreen></iframe>
                   : <div className="w-[100vw] h-[100vh] box-border overflow-x-hidden">
                    <h1 className="text-2xl text-center pt-8">Для просмотра учебника необходимо авторизоваться</h1>
                   </div>
            }
        </>
    );
}

export default Book;
