const TikTokFilled = ({className}) => {
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
                <path
                    d="M14.8744 17.3793V7.03406C14.8744 7.03406 16.1076 8.97764 20.5657 9.09746C20.8037 9.10345 21 8.91859 21 8.69436V5.77085C21 5.55262 20.8163 5.38059 20.5865 5.36861C17.1292 5.1829 15.4869 2.67532 15.3259 0.374853C15.3105 0.161752 15.1141 0 14.8879 0H11.3628C11.1275 0 10.9375 0.179724 10.9375 0.402239V16.847C10.9375 18.6331 9.49431 20.1873 7.60776 20.272C5.45157 20.3687 3.69803 18.6177 3.96495 16.5492C4.15315 15.0874 5.39366 13.891 6.93548 13.6881C7.24131 13.6479 7.54171 13.6453 7.83034 13.6753C8.08641 13.7027 8.31261 13.5221 8.31261 13.2782V10.347C8.31261 10.1381 8.14522 9.95842 7.92445 9.94558C7.48199 9.9182 7.02867 9.92847 6.56902 9.97725C3.16781 10.3418 0.423486 12.9453 0.047081 16.1623C-0.449665 20.4072 3.05018 24 7.43765 24C11.5455 24 14.8753 20.8506 14.8753 16.9651"
                    fill="currentColor"/>
            </svg>
        </span>
    )
}
export default TikTokFilled;