import React from 'react';
import TabItem from '../../components/common/m-tab/MTabItem';
import TabList from '../../components/common/m-tab/MTabList';
import { useTranslation } from 'react-i18next';
import UserAddSchoolAdmin from "../users/UserAddSchoolAdmin";

function SchoolAdminCreate({ role = null }) {

    const {t} = useTranslation()


    return (
        <div className="p-2">
            <h1 className="text-3xl m-2"> {t('usersPage.header')} </h1>
                <div className="text-2xl m-2 font-semibold mt-4">{t('usersPage.userAdd.title')}</div>
            <TabList isTabVisible={false} >
                <TabItem>
                    <UserAddSchoolAdmin/>
                </TabItem>
            </TabList>
        </div>
    );
}

export default SchoolAdminCreate;
