import React from 'react';
import Info from './info/Info';
import About from './about/About';
import Language from './language/Language';
import SocialMedia from './social-media/SocialMedia';

function Footer(props) {
    return (
        <div className="bg-[#F2F8FB] flex flex-col tablet:flex-row desktop:px-11 desktop:py-12 px-4 py-8 pt-12 box-border tablet:h-[294px] desktop:h-[353px]">
            <div className="flex flex-col tablet:flex-row tablet:w-1/2">
                <Info/>
                <About/>
            </div>
            <div className="flex flex-col desktop:flex-row tablet:w-1/2">
                <Language/>
                <SocialMedia/>
            </div>
        </div>
    );
}

export default Footer;
