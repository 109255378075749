const PlusCircleGreen = ({className}) => {
    return (
        <span className={className}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#659561"/>
                <path d="M11.9492 5V18.8995" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M18.8984 11.9492H4.99894" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </span>
    );
};

export default PlusCircleGreen;

