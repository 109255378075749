import { api } from '../api';
import {setIsSuccess} from "../../store/reducers/notificationSlice";

const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        registerUser: build.mutation({
            query: (payload) => ({
                url: '/public/register',
                method: 'POST',
                body: { ...payload, username: payload.email, role: ['private_user'] }
            })
        }),
        registerUserAdmin: build.mutation({
            query: (payload) => ({
                url: '/public/register',
                method: 'POST',
                body: { ...payload, username: payload.email, status_id: 1 }
            })
        }),
        authUser: build.mutation({
            query: (payload) => ({
                url: '/public/auth',
                method: 'POST',
                body: { ...payload }
            })
        }),
        resetPassword: build.mutation({
            query: (payload) => ({
                url: '/public/reset-pass-email',
                method: 'POST',
                body: { ...payload }
            })
        }),
        resetPasswordNew: build.mutation({
            query: (payload) => ({
                url: `/public/reset-pass?username=${payload.username}`,
                method: 'PATCH',
                body: { new_password: payload.new_password }
            })
        }),
        changePassword: build.mutation({
            query: (payload) => ({
                url: '/oauth/change-pass',
                method: 'POST',
                body: { ...payload }
            })
        }),
        confirmRegistration: build.mutation({
            query: (token) => ({
                url: `/public/registration-confirm?registration_token=${token}`,
                method: 'GET'
            })
        }),
        confirmResetPassword: build.mutation({
            query: ({token, is_registration}) => ({
                url: `/public/reset-pass-confirm-email?token=${token}&is_registration=${is_registration}`,
                method: 'GET'
            })
        }),
        adminDeleteUser: build.mutation({
            query: (payload) => ({
                url: `/public/admin_delete_user`,
                method: 'POST',
                body: {...payload}
            })
        }),
        getOauthDeleteUser: build.query({
            query: () => ({
                url: `/public/delete_user`,
                method: 'POST'
            })
        }),
        logout: build.mutation({
            query: (payload) => ({
                url: `/oauth/logout`,
                method: 'POST',
                body: payload
            })
        }),
        setKundUser: build.mutation({
            query: (payload) => ({
                url: `/oauth/set-kund-user`,
                method: 'POST',
                body: payload
            })
        }),
    })
});

export const {
    useRegisterUserMutation,
    useRegisterUserAdminMutation,
    useAuthUserMutation,
    useResetPasswordMutation,
    useResetPasswordNewMutation,
    useChangePasswordMutation,
    useConfirmRegistrationMutation,
    useConfirmResetPasswordMutation,
    useAdminDeleteUserMutation,
    useLazyGetOauthDeleteUserQuery,
    useLogoutMutation,
    useSetKundUserMutation
} = authApi;
