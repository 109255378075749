const Home = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M2 10.1707C2 8.81684 2.68488 7.55483 3.82005 6.81697L9.82004 2.91697C11.1456 2.05534 12.8544 2.05534 14.18 2.91697L20.18 6.81697C21.3151 7.55483 22 8.81684 22 10.1707V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V10.1707Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M8.5 18H15.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
          </svg>
        </span>
    );
};

export default Home;
