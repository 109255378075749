import React from 'react';

import { icons } from './constants';

const MIcon = ({
    type,
    ...props
}) => {
    const CurrentIcon = icons[type];

    return (
        <CurrentIcon {...props}/>
    );
};

export default MIcon;
