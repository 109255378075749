export const statisticsData = {
    count_of_schools: 0,
    student: 0,
    teacher: 0,
    private_user: 0,
    school_admin: 0,
    expert: 0
}

export const schoolsCityOptions = [
    {
        value: "Алматы"
    },
    {
        value: "Астана"
    },
]
