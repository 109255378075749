import { useEffect } from 'react';

export const useClickOutside = (
    ref,
    callback,
    event,
) => {
    const triggerEvent = event || 'click';

    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(e);
        }
    };

    useEffect(() => {
        document.addEventListener(triggerEvent, handleClick);
        return () => {
            document.removeEventListener(triggerEvent, handleClick);
        };
    });
};