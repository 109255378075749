import en from './trans/en.json'
import ru from './trans/ru.json'
import kz from './trans/kz.json'

import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-browser-languagedetector'
import i18n from 'i18next'

const resources = {
    ru: {
        translation: ru,
    },
    en: {
        translation: en,
    },
    kz: {
        translation: kz,
    }
}
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng:'ru',
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'lng',
        },
        interpolation: {
            escapeValue: false
        }
    })


export default i18n