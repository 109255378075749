import { api } from '../api';

const generalApi = api.injectEndpoints({
    endpoints: (build) => ({
        postEmailSupport: build.mutation({
            query: (payload) => ({
                url: 'email/support',
                method: 'POST',
                body: {...payload}
            })
        })
    }),
});


export const { usePostEmailSupportMutation } = generalApi;