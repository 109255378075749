import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useDeleteSchoolMutation, useGetCityListQuery, useGetSchoolMutation} from '../../api/SchoolsApi/schoolsList';
import { setIsError, setIsSuccess } from '../../store/reducers/notificationSlice';
import MIcon from '../../components/common/m-icon/MIcon';
import MProfileGroup from '../../components/common/m-profile-group/MProfileGroup';
import MProfileInput from '../../components/common/m-profile-input/MProfileInput';
import Spinner from '../../components/common/spinner/spinner';
import MModalWarning from '../../components/common/m-modal-warning/MModalWarning';

function SchoolView(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const {t} = useTranslation();
    const [ getSchool ] = useGetSchoolMutation();
    const [ deleteSchool ] = useDeleteSchoolMutation();
    const [ school, setSchool ] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const {data:cities} = useGetCityListQuery();

    useEffect(() => {
        if(id) {
            getSchoolInfo();
        }
    }, [id]);

    const removeSchool = async () => {
        setIsLoading(true)
        const result = await deleteSchool(school.school_id)
        setIsLoading(false)
        if(result.error){
            dispatch(setIsError(t(`general.models.serverErrors.adminUserDelete.${result.error.data.error_code}`, `${result.error.data?.error_code}: ${result.error.data?.message}`)))
        } else {
            dispatch(setIsSuccess({successText: 'Школа успешно удалена', successNavigation: '/schools'}))
        }
    }


    const buttons = <div className="flex w-full tablet:w-auto desktop:w-auto flex-col tablet:flex-row desktop:flex-row items-center gap-4 mt-4">
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center bg-blue2 rounded-lg text-sm text-white font-medium whitespace-nowrap"
            onClick={() => navigate(`/schools/edit/${id}`)}
        >
            {t('schoolPage.schoolView.buttons.edit')}
        </button>
        <button
            className="flex w-full tablet:w-auto desktop:w-auto flex-row justify-center gap-4 h-10 px-4 items-center border border-red3 rounded-lg text-sm text-red3 font-medium whitespace-nowrap disabled:cursor-not-allowed disabled:text-gray-400 disabled:border-gray-400"
            onClick={() => setIsOpen(prev => !prev)}
            disabled={school && school.users_count && school.users_count > 0}
        >
            <MIcon
                type="close"
                className={`${school && school.users_count > 0 ? "text-gray-400" :  "text-red3"}`}
            />
            {t('schoolPage.schoolView.buttons.delete')}
        </button>
    </div>

    const getSchoolInfo = async () => {
        setIsLoading(true)
        const schoolInfo = await getSchool(id);
        setIsLoading(false)
        const { data, error } = schoolInfo
        if (error) {
            // console.error(userData.error);
            dispatch(setIsError(t(`general.models.serverErrors.UView.${error.data?.error_code}`, `${error.data?.error_code}: ${error.data?.message}`)))
        } else {
            setSchool({...data.school, city:cities?.cities.find(city=>city.city_id===data.school.city_id).name })
        }
    }

    return (
        <Spinner spinning={isLoading}>
            <div className="p-2">
                <h1 className="text-3xl m-2">{t('schoolPage.header')}</h1>
                <div className="text-2xl m-2 font-semibold mt-4">{ school && school.name ? school.name : '' }</div>
                <div className="bg-card-bg rounded-xl px-6 py-4 flex flex-none flex-col w-full">
                    <MProfileGroup title={t('schoolPage.schoolView.title')}>
                        <MProfileInput label={t('schoolPage.schoolView.bin')} record={school} field='bin'/>
                        <MProfileInput label={t('schoolPage.schoolView.name')} record={school} field='name'/>
                        <MProfileInput label={t('schoolPage.schoolView.city')} record={school} field='city'/>
                        <MProfileInput label={t('schoolPage.schoolView.address')} record={school} field='address'/>
                        <MProfileInput label={t('schoolPage.schoolView.contact')} record={school} field='school_contact'/>
                    </MProfileGroup>
                    {buttons}
                    {school && school.users_count > 0 &&
                        <div className="mt-2 text-gray-400">Удаление школы невозможно, так как в ней имеются пользователи. Перед удалением переведите пользователей в действующие школы.</div>
                    }
                </div>
                <MModalWarning setIsOpen={setIsOpen} isOpen={isOpen} text={'Вы действительно хотите удалить школу?'} onAccept={removeSchool}/>
            </div>
        </Spinner>
    );
}

export default SchoolView;
