import React from 'react'
import './spinner.css'

function Spinner({ spinning, className, children, fullScreen = false }) {        
    const spinElement = <div className={`absolute animate-spin -ml-1 mr-3 h-10 w-10 left-1/2 top-[45%] text-gray-600 z-10 ${className || ''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>
    
    return fullScreen
        ? <div className={`fullScreen w-full ${spinning ? 'showOverlay' : ''}`}>
            {spinElement}
        </div>
        : <div className='relative w-full h-full'>
            {spinning && <div>
                {spinElement}
            </div>}
            <div className={`w-full ${spinning ? 'overlayBlur' : ''}`}>
                {children}
            </div>
        </div>
}

export default Spinner
