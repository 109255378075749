import {useState} from "react";
import useDeviceType from "../../hooks/useDeviceType";
import MSelect from "../../components/common/m-select/MSelect";

const Student = () => {
    const [activeTab, setActiveTab] = useState('not-done');
    const {isMobile} = useDeviceType();
    const options = [
        {
            name:'Не сданы',
            value: 'not-done'
        },
        {
            name:'Сданы',
            value: 'done'
        },
        {
            name:'Результаты',
            value: 'results'
        }
    ]
    return (
        <div className="py-[50px] px-5 flex flex-col gap-[50px]">
            <p className={'font-medium text-[28px]'}>Главная</p>
            <div>
                <p className={'font-bold text-xl mb-4'}>Работы</p>
                <div className={'flex mb-4'}>
                    {isMobile ? <MSelect options={options} border={true} className={'flex-auto'}/> : (
                        <>
                            <div className={'border-gray6 border-b w-4'}/>
                            <div
                                className={`px-4 py-3 font-medium text-sm ${activeTab === 'not-done' ? 'border-gray6 border border-b-0 rounded-t-lg' : 'border-b text-main-color'}`}
                                onClick={() => setActiveTab('not-done')}>
                                Не сданы
                            </div>
                            <div className={'border-gray6 border-b w-4'}/>
                            <div
                                className={`px-4 py-3 font-medium text-sm ${activeTab === 'done' ? 'border-gray6 border border-b-0 rounded-t-lg' : 'border-b text-main-color'}`}
                                onClick={() => setActiveTab('done')}
                            >
                                Сданы
                            </div>
                            <div className={'border-gray6 border-b w-4'}/>
                            <div
                                className={`px-4 py-3 font-medium text-sm ${activeTab === 'results' ? 'border-gray6 border border-b-0 rounded-t-lg' : 'border-b text-main-color'}`}
                                onClick={() => setActiveTab('results')}
                            >
                                Результаты
                            </div>
                            <div className={'border-gray6 border-b flex-auto'}/>
                        </>
                    )}
                </div>
                <div>
                   <p className={'text-gray4'}>Данные отсутствуют</p>
                    {/*{isMobile ? (*/}
                    {/*    <div className={'font-medium text-sm flex flex-col gap-2 border-b pb-5'}>*/}
                    {/*        <div>*/}
                    {/*            <p className={'mb-1 text-gray4'}>Название</p>*/}
                    {/*            <p>Урок 17. Что такое природные ресурсы</p>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <p className={'mb-1 text-gray4'}>Тип</p>*/}
                    {/*            <p>Контрольная работа</p>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <p className={'mb-1 text-gray4'}>Предмет</p>*/}
                    {/*            <p>Математика 3 кл.</p>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <p className={'mb-2 text-gray4'}>Срок</p>*/}
                    {/*            <p>30.03.24</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    <table className={'w-full mb-6'}>*/}
                    {/*        <thead>*/}
                    {/*        <tr className={'border-b-[1px] border-gray3 text-gray4 text-sm'}>*/}
                    {/*            <th className={'w-10 text-start font-medium pb-2'}>№</th>*/}
                    {/*            <th className={'text-start font-medium pb-2 w-[482px]'}>Название</th>*/}
                    {/*            <th className={'w-48 text-start font-medium pb-2'}>Тип</th>*/}
                    {/*            <th className={'w-48 text-start font-medium pb-2'}>Предмет</th>*/}
                    {/*            <th className={'w-40 text-start font-medium pb-2'}>Срок</th>*/}
                    {/*        </tr>*/}
                    {/*        </thead>*/}
                    {/*        <tbody>*/}
                    {/*        <tr className={'font-medium whitespace-nowrap overflow-ellipsis'}>*/}
                    {/*            <td className={'py-4'}>{1.}</td>*/}
                    {/*            <td className={'overflow-ellipsis max-w-[482px] overflow-hidden pr-2'}>Раздел II. КАРТОГРАФИЯ И ГЕОГРАФИЧЕСКИЕ БАЗЫ ДАННЫХ. Географические карты</td>*/}
                    {/*            <td>{14}</td>*/}
                    {/*            <td>{13}</td>*/}
                    {/*            <td>{14}</td>*/}
                    {/*        </tr>*/}
                    {/*        <tr className={'font-medium'}>*/}
                    {/*            <td className={'py-4'}>{2.}</td>*/}
                    {/*            <td className={'overflow-ellipsis max-w-[482px] overflow-hidden pr-2'}>{13}</td>*/}
                    {/*            <td>{14}</td>*/}
                    {/*            <td>{13}</td>*/}
                    {/*            <td>{14}</td>*/}
                    {/*        </tr>*/}
                    {/*        <tr className={'font-medium'}>*/}
                    {/*            <td className={'py-4'}>{3.}</td>*/}
                    {/*            <td className={'overflow-ellipsis max-w-[482px] overflow-hidden pr-2'}>{13}</td>*/}
                    {/*            <td>{14}</td>*/}
                    {/*            <td>{13}</td>*/}
                    {/*            <td>{14}</td>*/}
                    {/*        </tr>*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*)}*/}
                </div>
            </div>
        </div>
    )
}

export default Student;