import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {useSelector} from "react-redux";

const RequireAuth = ({ children, roles }) => {
    const location = useLocation();
    const isAuth = useSelector(state => state.auth.isAuth);
    const currentUser = useSelector(state=>state.user);

    if (!isAuth) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (roles && !roles.includes(currentUser?.activeRole)) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default RequireAuth;