import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Privacy(props) {
    const { t } = useTranslation();
    return (
        <div className="flex justify-start flex-col px-8">
            <h1 className="my-10 font-bold text-3xl">{t('privacyPage.mainTitle')}</h1>
            <p className="mb-6">{t('privacyPage.effectiveDate')}</p>
            <p className="mb-6">{t('privacyPage.seeAlsoEula')}
                <Link className="underline text-main-color" to="/eula">{t('privacyPage.link')}</Link>
                {!t('privacyPage.afterLink').startsWith('privacyPage') && t('privacyPage.afterLink')}
                .
            </p>
            <ol>
                <li className="w-full tablet:w-[769px]">
                    <h2 className="font-bold mb-2">{t('privacyPage.1.title')}</h2>
                    <p className="mb-6">
                        {t('privacyPage.1.text1')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.1.text2')}
                        {t('privacyPage.1.tel')} <span className={'whitespace-nowrap'}>+7 (775) 007 29 00</span>, e-mail:&nbsp;
                        <a
                            className="underline text-main-color"
                            href="mailto:dgmektep@inbox.ru">
                             dgmektep@inbox.ru
                        </a>
                        .
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.1.text3')}
                        <Link
                            className="underline text-main-color"
                            to="/"
                        >oqulyqtar.kz</Link>{t('privacyPage.1.text3.1')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.1.text4')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.1.text5')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.1.text6')}
                    </p>
                </li>
                <li className="w-full tablet:w-[769px]">
                    <h2 className="font-bold mb-2">{t('privacyPage.2.title')}</h2>
                    <p className="mb-6">
                        {t('privacyPage.2.text1')} <Link
                        className="underline text-main-color"
                        to="/faq"
                    >{t('privacyPage.2.text1.1')}</Link>
                        {!t('privacyPage.2.afterLink').startsWith('privacyPage') && t('privacyPage.2.afterLink')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.2.text2')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.2.text3')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.2.text4')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.2.text5')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.2.text6')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.2.text7')}
                    </p>
                </li>
                <li className="w-full tablet:w-[769px]">
                    <h2 className="font-bold mb-2">{t('privacyPage.3.title')}</h2>
                    <p className="mb-6">
                        {t('privacyPage.3.subtitle')}
                    </p>
                    <ol className={'list-disc'}>
                        <li className="ml-8 mb-6">
                                {t('privacyPage.3.3.1')}
                        </li>
                        <li className="ml-8 mb-6">
                                {t('privacyPage.3.3.2')}
                        </li>
                        <li className="ml-8 mb-6">
                                {t('privacyPage.3.3.3')}
                        </li>
                        <li className="ml-8 mb-6">
                                {t('privacyPage.3.3.4')}
                        </li>
                        <li className="ml-8 mb-6">
                                {t('privacyPage.3.3.5')}
                        </li>
                    </ol>
                </li>
                <li>
                    <div className="w-full tablet:w-[769px]">
                        <h2 className="font-bold mb-2">{t('privacyPage.4.title')}</h2>
                        <p className="mb-6">
                            {t('privacyPage.4.text1')}
                        </p>
                        <p className="mb-6">
                            {t('privacyPage.4.text2')}
                        </p>
                        <p className="mb-6">
                            {t('privacyPage.4.text3')}
                        </p>
                    </div>
                </li>
                <li className="w-full tablet:w-[769px]">
                    <h2 className="font-bold mb-2">{t('privacyPage.5.title')}</h2>
                    <p className="mb-6">
                        {t('privacyPage.5.text1')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.5.text2')}
                    </p>
                </li>
                <li>
                    <div className="w-full tablet:w-[769px]">
                        <h2 className="font-bold mb-2">{t('privacyPage.6.title')}</h2>
                        <p className="mb-6">
                            {t('privacyPage.6.text1')}
                        </p>
                        <p className="mb-6">
                            {t('privacyPage.6.text2')}
                        </p>
                    </div>
                </li>
                <li className="w-full tablet:w-[769px]">
                    <h2 className="font-bold mb-2">{t('privacyPage.7.title')}</h2>
                    <p className="mb-6">
                        {t('privacyPage.7.text1')}
                    </p>
                    <p className="mb-6">
                        {t('privacyPage.7.subtitle')}
                    </p>
                    <ol className={'list-disc'}>
                        <li className="ml-8 mb-6">
                            {t('privacyPage.7.1.text')}
                        </li>
                        <li className="ml-8 mb-6">
                            {t('privacyPage.7.2.text')}
                        </li>
                        <li className="ml-8 mb-6">
                            {t('privacyPage.7.3.text')}
                        </li>
                        <li className="ml-8 mb-6">
                            {t('privacyPage.7.4.text')}
                        </li>
                        <li className="ml-8 mb-6">
                            {t('privacyPage.7.5.text')}
                        </li>
                        <li className="ml-8 mb-6">
                            {t('privacyPage.7.6.text')}
                        </li>
                    </ol>
                    <p className="mb-6">
                        {t('privacyPage.7.text2')}
                    </p>
                </li>
                <li className="w-full tablet:w-[769px] mb-6">
                    <h2 className="font-bold mb-3">{t('privacyPage.8.title')}</h2>
                    <h3 className="mb-2 font-bold">
                        {t('privacyPage.8.subtitle1')}
                    </h3>
                    <p className={'mb-6'}>
                        {t('privacyPage.8.text1')}
                    </p>
                    <h3 className="mb-2 font-bold">
                        {t('privacyPage.8.subtitle2')}
                    </h3>
                    <p>
                        {t('privacyPage.8.text2.1')}
                        <a className="underline text-main-color" href="mailto:dgmektep@inbox.ru">dgmektep@inbox.ru</a>
                        {t('privacyPage.8.text2.2')}
                    </p>
                </li>
            </ol>
        </div>
    );
}

export default Privacy;
