import MModal from "../../../components/common/m-modal/MModal";
import React, {useState} from "react";
import MTextarea from "../../../components/common/m-textarea/MTextarea";
import {authRegex} from "../../../models/auth";
import MError from "../../../components/common/m-error/MError";
import {useTranslation} from "react-i18next";
import MIcon from "../../../components/common/m-icon/MIcon";
import MTable from "../../../components/common/m-table/MTable";
import UserList from "./UserList";
import MSelect from "../../../components/common/m-select/MSelect";

const ImportStudentsModal = ({isOpen = false, setIsOpen}) => {
    const [page, setPage] = useState(1);
    const [studentsData, setStudentsData] = useState('')
    const [students, setStudents] = useState([]);
    const [error, setError] = useState(null);
    const {t} = useTranslation();
    const [order, setOrder] = useState(['email', 'lastname', 'firstname']);

    function parseStudentsData(text) {
        let error = null;

        const lines = text.split('\n').map(item => item.trim()).filter(item => item);

        if (lines.length === 0) {
            error = t(`general.models.errors.general`)
        }

        const studentsArray = lines.map(line => {
            const filteredLines = line.split(/[\s;]+/);
            if (filteredLines.length !== 3
                ||
                !filteredLines.find(item => authRegex.email.test(item))
                ||
                filteredLines.filter(item => !authRegex.email.test(item)).some(item => (item.length < 2 || item.length > 30))
            ) {
                error = 'Проверьте правильность введёных вами данных'
            }
            return filteredLines;
        });
        return {studentsArray, error};
    }

    function checkIfArrayIsUnique(myArray) {
        return myArray.length === new Set(myArray).size;
    }

    const handleImport = () => {
            if (checkIfArrayIsUnique(order)) {
                setPage(3)
            } else {
                setError('The fields must be different')
            }
        }

    const onChange = (index, newValue) => {
        setOrder(prevArray => {
            if (index >= 0 && index < prevArray.length) {
                const newArray = [...prevArray];
                newArray[index] = newValue;
                return newArray;
            }
        });
    };

    const sortData = () => {
        const {studentsArray, error} = parseStudentsData(studentsData.trim())
        setError(error)
        setStudents(studentsArray)
        if (!error) setPage(2)
    }

    const options = [
        {
            value: 'email',
            name: 'Эл.почта'
        },
        {
            value: 'lastname',
            name: 'Фамилия'
        },
        {
            value: 'firstname',
            name: "Имя"
        }
    ]

    const columns = [
        {
            label: 'Первая колонка',
            key: '0'
        },
        {
            label: 'Вторая колонка',
            key: '1'
        },
        {
            label: 'Третья колонка',
            key: '2'
        }
    ]

    return (
        <MModal active={isOpen} setActive={setIsOpen} onCloseModal={() => {
            setError(null)
            setStudentsData('')
            setStudents([])
            setPage(1)
        }}>
            <div className={'tablet:min-w-[664px] tablet:max-w-[664px]'}>
                {page === 1 && (
                    <>
                        <h3 className={'font-bold text-[24px] mb-6'}>Импорт учеников</h3>

                        <div className={'div-rule mb-4 text-sm font-medium'}>
                            Внесите данные учеников – имя и фамилию. Вы можете вставить текст из Excel или из Google
                            Sheets, или использовать точку с запятой в качестве разделителя полей.
                        </div>

                        <MTextarea
                            placeholder={'Введите данные в формате\n' +
                                'Электронная почта; Фамилия; Имя'}
                            className={'!border-blue7 max-h-[114px] min-h-[114px] resize-none'}
                            value={studentsData}
                            setValue={(e) => setStudentsData(e.target.value)}
                        />

                        {error && <MError errorMessage={error} className={'mb-6'}/>}

                        <div className={`flex justify-end ${!error && 'mb-6'}`}>
                            <button
                                className={'btn-primary-library-school_admin px-6 py-3'}
                                onClick={sortData}
                            >
                                Далее
                            </button>
                        </div>
                    </>
                )}

                {page === 2 && (
                    <>
                        <h3 className={`font-bold text-[24px] mb-6`}>Импорт учеников</h3>
                        <div className={'div-rule mb-6'}>
                            Проверьте содержание колонок.
                        </div>
                        <div className={'flex flex-col tablet:flex-row gap-2'}>
                            <div className={'flex flex-col flex-1'}>
                                <p>В первой колонке</p>

                                <MSelect
                                    options={options}
                                    border={true}
                                    onChange={(e) => onChange(0, e.target.value)}
                                />
                            </div>

                            <div className={'flex flex-col flex-1'}>
                                <p>Во второй колонке</p>

                                <MSelect
                                    options={options}
                                    border={true}
                                    frontField={'lastname'}
                                    onChange={(e) => onChange(1, e.target.value)}
                                />
                            </div>

                            <div className={'flex flex-col flex-1'}>
                                <p>В третьей колонке</p>

                                <MSelect
                                    options={options}
                                    border={true}
                                    frontField={'firstname'}
                                    onChange={(e) => onChange(2, e.target.value)}
                                />
                            </div>
                        </div>

                        {error && <MError errorMessage={error} className={'mb-6'}/>}

                        <MTable
                            columns={columns}
                            data={students}
                            foundTitle={'Идентифицировано'}
                            total={students.length}
                            rowsClassName={'!border-0'}
                            pageSize={Infinity}
                        />

                        <div className={'flex flex-col tablet:flex-row gap-5 tablet:gap-0 justify-between'}>
                            <button
                                className={'btn-secondary-border px-4 py-3 flex gap-3'}
                                onClick={() => setPage(1)}
                            >
                                <MIcon type={'bigArrowLeft'}/>
                                Назад
                            </button>

                            <button
                                className={'btn-primary-library-school_admin px-6 py-3 order-first tablet:order-last'}
                                onClick={handleImport}
                            >
                                Начать импорт
                            </button>
                        </div>
                    </>
                )}

                {page === 3 && (
                    <>
                        <UserList students={students} order={order}/>
                    </>
                )}
            </div>
        </MModal>
    )
}
export default ImportStudentsModal;