const BigArrowLeft = ({className}) => {
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2.10156 12H21.9006" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M8 6L2 12L8 18" stroke="#0080C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </span>
    )
}
export default BigArrowLeft;