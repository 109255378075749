import React, { useEffect, useState } from 'react';
import MInput from '../../../common/m-input/MInput';
import MError from '../../../common/m-error/MError';
import EyeOpened from '../../../../assets/img/Eye opened.png';
import EyeClosed from '../../../../assets/img/Eye Closed.png';
import MModal from '../../../common/m-modal/MModal';
import { useResetPasswordNewMutation } from '../../../../api/AuthApi/authService';
import { useTranslation } from 'react-i18next';
import { authRegex } from '../../../../models/auth';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useFormData from "../../../../hooks/useFormData";

function NewPasswordModal({ isOpen, setIsOpen, setError, setIsOk, ...props }) {
    const [username, setUsername] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isConfirmPassShown, setIsConfirmPassShown] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const {t} = useTranslation();

    const [ resetPasswordNew, {isLoading} ] = useResetPasswordNewMutation()

    const navigate = useNavigate();

    useEffect(() => {
        setUsername(searchParams.get('username'))
    }, []);

    const {formData, errors, onHandleChange, setErrors, setFormData} = useFormData({
        password: '',
        confirmPassword: ''
    });

    const onHandle = async (e) => {
        e.preventDefault();

        const errors = {};
        const { password, confirmPassword } = formData;

        if (!password.trim()) {
            errors.password = 'general';
        } else if (!authRegex.password.test(password)) {
            errors.password = 'invalidPassword';
        }

        if (!confirmPassword.trim()) {
            errors.confirmPassword = 'confirmPassword';
        }

        if (confirmPassword.trim() !== password.trim()) {
            errors.confirmPassword = 'passwordMismatch';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            const res = await resetPasswordNew({new_password: password, username})
            if (res.data && res.data.status && res.data.status === 'ok') {
                setIsOk(true)
            } else {
                setError('errors.common')
            }
            setIsOpen(false)
            resetData()
            setTimeout(()=>{
                navigate('/')
            },2000)
        }
    }
    const resetData = () => {
        setFormData({
            password: '',
            confirmPassword: ''
        });
        setErrors({});
    };

    const onCloseModal = () => {
        resetData();
    };

    return (
        <MModal
            active={isOpen}
            setActive={setIsOpen}
            onCloseModal={onCloseModal}
        >
            <div className="flex flex-col w-[460px]">
                <h5 className="text-xl font-bold mx-auto my-3">{t('general.header.NewResetPasswordModal.title')}</h5>

                <form
                    className="flex flex-col"
                    onSubmit={onHandle}
                >

                    <div className={'relative'}>
                        <MInput
                            placeholder={t('general.header.NewResetPasswordModal.pass')}
                            setValue={(e) => onHandleChange(64, e)}
                            value={formData.password}
                            name="password"
                            error={errors.password}
                            type={isPasswordShown ? 'text' : 'password'}
                            className={'w-[calc(100%-8px)]'}
                        />

                        <img
                            className={'absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer'}
                            src={isPasswordShown ? EyeOpened : EyeClosed}
                            alt='see password'
                            onClick={() => setIsPasswordShown(prev => !prev)}
                        />
                    </div>

                    {errors.password && <MError error={errors.password}/>}

                    <div className={'relative'}>
                        <MInput
                            placeholder={t('general.header.NewResetPasswordModal.confirmPass')}
                            setValue={(e) => onHandleChange(64, e)}
                            value={formData.confirmPassword}
                            name="confirmPassword"
                            error={errors.confirmPassword}
                            type={isConfirmPassShown ? 'text' : 'password'}
                            className={'w-[calc(100%-8px)]'}
                        />

                        <img
                            className={'absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer'}
                            src={isConfirmPassShown ? EyeOpened : EyeClosed}
                            alt='see password'
                            onClick={() => setIsConfirmPassShown(prev => !prev)}
                        />
                    </div>

                    {errors.confirmPassword && <MError error={errors.confirmPassword}/>}

                    <button
                        className={`btn-primary my-2`}
                        type="submit"
                        disabled={isLoading}
                    >
                        {t('general.header.NewResetPasswordModal.send')}
                    </button>
                </form>
            </div>
        </MModal>
    );
}

export default NewPasswordModal;
