import React from 'react';
import MModal from '../../../common/m-modal/MModal';
import oqulyqtar from '../../../../assets/img/img.png';
import kundalik from '../../../../assets/img/Kundalik.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setIsLoginTypeModal } from '../../../../store/reducers/authSlice';

function LoginTypeModal({ isTypeModal, setIsLoginModal, ...props }) {
    const dispatch = useDispatch();

    const openLogin = () => {
        dispatch(setIsLoginTypeModal(false));
        setIsLoginModal(true);
    }

    const {t} = useTranslation();

    return (
        <MModal active={isTypeModal}  setActive={(value) => dispatch(setIsLoginTypeModal(value))}>
            <div className="flex flex-col">
                <h5 className="text-xl font-bold mx-auto my-3">{t('general.header.loginTypeModal.title')}</h5>
                <p className="text-xs mx-auto">{t('general.header.loginTypeModal.chooseType')}</p>
                <div className="flex flex-row justify-between items-center m-4 gap-[8px] tablet:gap-[48px]">
                    <img
                        src={oqulyqtar}
                        className="hover:cursor-pointer"
                        alt="oqulyqtar"
                        width="144"
                        onClick={() => openLogin()}
                    />
                    <img
                        src={kundalik}
                        className="hover:cursor-pointer h-[60px]"
                        alt="kundalik"
                        height="60"
                        onClick={()=>{
                            window.location.assign('http://91.147.93.80:32372/realms/mektep/protocol/openid-connect/auth?client_id=mektep&response_type=token&redirect_uri=https%3A%2F%2Ftest.okulyktar.kz%2F');
                        }}
                    />
                </div>
            </div>
        </MModal>
    );
}

export default LoginTypeModal;
