import { api } from '../api';

const userListApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsersList: build.mutation({
            query: (filters) => ({
                url: `/user/list${filters && filters}`,
                method: 'GET'
            })
        })
    })
})

export const { useGetUsersListMutation } = userListApi;
