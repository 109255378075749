import React from 'react';
import { useTranslation } from 'react-i18next';

function FilterSelect({ name, options = [], label, language, setState, state, withPath = true,  ...props }) {
    const {t} = useTranslation();
    return (
        <div className={`flex flex-col box-border ${props.className}`}>
            <label className="font-bold my-2">{label}</label>
            <select
                className="rounded-lg p-2"
                value={state[name]}
                onChange={e => {
                    setState({ ...state, [name]: e.target.value})
                }}
            >
                <option value={''} key={'all'}>{t('libraryPage.filters.all')}</option>
                {name === 'subject'
                    ? options.sort(
                        (a, b) => {
                            let nameA = t(`libraryPage.filters.${name}.${a.value}`)
                            let nameB = t(`libraryPage.filters.${name}.${b.value}`)
                            return nameA.localeCompare(nameB);
                        }).map(option => {
                        return (
                            <option value={option.value} key={option.value}>{withPath ? t(`libraryPage.filters.${name}.${option.value}`) : option.name}</option>
                        )
                    })
                    : options.map(option => {
                        return (
                            <option value={option.value} key={option.value}>{withPath ? t(`libraryPage.filters.${name}.${option.value}`) : option.name}</option>
                        )
                    })}
            </select>
        </div>
    );
}

export default FilterSelect;
