import React from 'react';
import atamura from '../../../assets/img/atamura.png';
import mektep from '../../../assets/img/mektep.png';
import kokzhiek from '../../../assets/img/kokzhiek.png';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { subjectsOptions } from '../../../models/library';

function Books(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const {t} = useTranslation();

    return (
        <div className="flex flex-col items-center my-5">
            <h5 className="text-main-color font-medium text-2xl mb-5 text-center">
                {t('mainPage.books.title')}
            </h5>

            <p className="desktop:w-[690px] text-center mb-5">
                {t('mainPage.books.description')}
            </p>

            <div className="inline-block tablet:hidden w-80 my-10 content-center">
                <Slider {...settings}>
                    <div>
                        <img src={mektep} alt={'Mektep'} className="tablet:px-5 mx-auto"/>
                    </div>
                    <div>
                        <img src={atamura} alt={'Atamura'} className="tablet:px-5 mx-auto"/>
                    </div>
                </Slider>
            </div>
            <div className="flex-wrap flex-row justify-center items-center my-10 hidden tablet:flex">
                <img src={mektep} alt={'Mektep'} className="px-5"/>
                <img src={atamura} alt={'Atamura'} className="px-5"/>
            </div>
            <div className="flex flex-row flex-wrap mx-5 px-5 justify-center">
                {subjectsOptions.sort((a, b) => {
                    let nameA = t(`libraryPage.filters.subject.${a.value}`)
                    let nameB = t(`libraryPage.filters.subject.${b.value}`)
                    return nameA.localeCompare(nameB);
                }).map(item => {
                    if(!item.dontShow) {
                        return (<Link
                            className="btn-secondary-border m-1"
                            key={item.value}
                            to={`library?subject=${item.value}`}
                        >
                            {t(`libraryPage.filters.subject.${item.value}`)}
                        </Link>)
                    }
                })}
            </div>
                <Link to={'/library'}>
                    <button className={'btn-primary m-2'}>
                        {t('mainPage.books.viewLibrary')}
                    </button>
                </Link>
        </div>
    );
}

export default Books;
