const Instagram = ({className}) => {
    return (
        <span className={className}>
          <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <rect
                x="0.75"
                y="0.75"
                width="18.5"
                height="18.5"
                rx="4.25"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <circle
                cx="10"
                cy="10"
                r="5.25"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <circle
                cx="16"
                cy="4"
                r="1"
                fill="currentColor"
            />
          </svg>
        </span>
    );
};

export default Instagram;
