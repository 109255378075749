import React, {useEffect, useLayoutEffect} from 'react';
import carousel1 from '../../assets/img/Carousel1.png'
import carousel2 from '../../assets/img/Carousel2.png'
import carousel3 from '../../assets/img/Carousel3.png'
import carousel1en from '../../assets/img/Carousel1en.png'
import carousel2en from '../../assets/img/Carousel2en.png'
import carousel3en from '../../assets/img/Carousel3en.png'
import carousel1kz from '../../assets/img/Carousel1kz.png'
import carousel2kz from '../../assets/img/Carousel2kz.png'
import carousel3kz from '../../assets/img/Carousel3kz.png'
import About from '../../components/main-page/about/About';
import Books from '../../components/main-page/books/Books';
import Advantages from '../../components/main-page/advantages/Advantages';
import Packages from '../../components/main-page/packages/Packages';
import Blocks from '../../components/main-page/blocks/Blocks';
import i18n from "i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {roles} from "../../models/auth";
import {useSetKundUserMutation} from "../../api/AuthApi/authService";

function MainPage(props) {
    const isAuth = useSelector(state=>state.auth.isAuth);
    const role = useSelector(state=>state.user.activeRole);
    const [setKundUser] = useSetKundUserMutation()
    const navigate = useNavigate();
    const linkKundUser = async (code) => {
        const result = await setKundUser({
            kund_user_id: code
        });
    }
    useLayoutEffect(() => {
        const hash = window.location.hash;
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code')
       if(code){
           console.log(code)
           linkKundUser(code)
       }
        if (hash === '#packages-section') {
            const packagesSection = document.getElementById('packages-section');
            if (packagesSection) {
                setTimeout(() => {
                    packagesSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    window.history.replaceState(null, null, window.location.pathname);
                }, 0);
            }
        }
        if(isAuth){
            navigate(roles[role])
        }
    }, [isAuth, navigate, role]);
    return (
        <div className={'px-[10px]'}>
            {i18n.language.startsWith('ru')  ? (
                <>
                    <img src={carousel1} alt={'Logo'} className="desktop:w-svw py-5 hidden tablet:hidden desktop:inline-block"/>
                    <img src={carousel2} alt={'Logo'} className="tablet:w-svw py-5 hidden desktop:hidden tablet:inline-block"/>
                    <img src={carousel3} alt={'Logo'} className="w-svw py-5 tablet:hidden desktop:hidden"/>
                </>
            ) : i18n.language.startsWith('en')  ? (
                <>
                    <img src={carousel1en} alt={'Logo'} className="desktop:w-svw py-5 hidden tablet:hidden desktop:inline-block"/>
                    <img src={carousel2en} alt={'Logo'} className="tablet:w-svw py-5 hidden desktop:hidden tablet:inline-block"/>
                    <img src={carousel3en} alt={'Logo'} className="w-svw py-5 tablet:hidden desktop:hidden"/>
                </>
            ) : (
                <>
                    <img src={carousel1kz} alt={'Logo'} className="desktop:w-svw py-5 hidden tablet:hidden desktop:inline-block"/>
                    <img src={carousel2kz} alt={'Logo'} className="tablet:w-svw py-5 hidden desktop:hidden tablet:inline-block"/>
                    <img src={carousel3kz} alt={'Logo'} className="w-svw py-5 tablet:hidden desktop:hidden"/>
                </>
            )
            }
            <About/>
            <Books/>
            <Advantages/>
            <Packages/>
            <Blocks/>
        </div>
    );
}

export default MainPage;
