import React from 'react';
import books from '../../../assets/img/books.png'
import checkMark from '../../../assets/icons/check-mark.png'
import { useTranslation } from 'react-i18next';

function About(props) {
    const {t} = useTranslation();

    return (
        <div className="flex flex-col tablet:flex-row py-5">
            <img src={books} alt={'books'} className=""/>
            <div className="flex flex-col justify-center text-base">
                <p className="text-main-color text-2xl font-medium pb-4">
                    {t('mainPage.about.oqulyqtar')}
                </p>
                <div className="flex flex-row gap-3 pb-3">
                    <img src={checkMark} alt={'books'} className="h-[11px] mt-2"/>
                    <p >
                        {t('mainPage.about.usage')}
                    </p>
                </div>
                <div className="flex flex-row gap-3 pb-3">
                    <img src={checkMark} alt={'books'} className="h-[11px] mt-2"/>
                    <p>
                        {t('mainPage.about.advantage')}
                    </p>
                </div>
                <div className="flex gap-3 flex-row">
                    <img src={checkMark} alt={'books'} className="h-[11px] mt-2"/>
                    <p >
                        {t('mainPage.about.clients')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
