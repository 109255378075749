import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { useConfirmResetPasswordMutation } from '../../api/AuthApi/authService';
import NewPasswordModal from '../../components/layout/navbar/modals/NewPasswordModal';

function ConfirmationReset(props) {
    const [isOk, setIsOk] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const {t} = useTranslation();
    const [ confirmResetPassword ] = useConfirmResetPasswordMutation();

    useEffect(() => {
        const token = searchParams.get('registration_token')
        const is_registration = searchParams.get('is_registration')
        if(token && is_registration) {
            getConfirmation(token, is_registration)
        } else if(token) {
            getConfirmation(token)
        }
    }, []);

    const getConfirmation = async (token, is_registration) => {
        const res = await confirmResetPassword({token, is_registration})
        if (res.data && res.data.status && res.data.status === 'ok') {
            setIsOpen(true)
        } else if (res.error && res.error.data && res.error.data?.error_code === 11111) {
            setError('confirmation.change-password-expired')
        } else {
            setError('errors.common')
        }
    }
    return (
        <div className="flex flex-col items-center pt-6">
            {
                isOk
                    ? <div>{t('confirmation.change-password')}</div>
                    : <div>{t(`${error}`)}</div>
            }
            <Link to="/" className="underline pt-2 text-xs">{t('confirmation.to-main-page')}</Link>

            <NewPasswordModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setError={setError}
                setIsOk={setIsOk}
            />
        </div>
    );
}

export default ConfirmationReset;
