export function formatDate(dateString) {
    const [day, month, year] = dateString.split('.');
    return `${year.trim()}-${month.trim()}-${day.trim()}`;
}

export function validateDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();
    now.setDate(now.getDate() - 1);

    if (start.getTime() === end.getTime()) {
        return false;
    } else if (end.getTime() < start.getTime()) {
        return false;
    } else if (start.getTime() < now.getTime()) {
        return false;
    } else return start.getTime() <= end.getTime();
}