import React from 'react';
import MainPage from './MainPage';
import TeacherMain from '../../components/teacher/TeacherMain';
import { useSelector } from 'react-redux';

function MainPageLayout(props) {
    const role = useSelector(state => state.user.role);

    return (
        <>
            {
                role === 'teacher' ? (<TeacherMain/>) : (<MainPage/>)
            }
        </>
    );
}

export default MainPageLayout;
