import { api } from '../api';

const libraryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getList: build.mutation({
            query: (filters) => ({
                url: `/library/list${filters && filters}`,
                method: 'GET'
            })
        }),
        getPublisherList: build.query({
            query: () => ({
                url: `/library/publisher-list`,
                method: 'GET'
            })
        }),
        getPackageList: build.query({
            query: () => ({
                url: `/library/package-list`,
                method: 'GET'
            })
        }),
        addBookToFavorite: build.mutation({
            query: (payload) => ({
                url: `/library/add-to-favorite`,
                method: 'POST',
                body: {...payload}
            })
        }),
        deleteBookFromFavorite: build.mutation({
            query: (payload) => ({
                url: `/library/delete-from-favorite`,
                method: 'DELETE',
                body: {...payload}
            })
        }),
        getCollectionList: build.query({
            query: () => ({
                url: `/library/collection-list`,
                method: 'GET'
            })
        })
    })
})

export const { useGetListMutation, useGetPublisherListQuery, useGetPackageListQuery, useAddBookToFavoriteMutation, useDeleteBookFromFavoriteMutation, useGetCollectionListQuery} = libraryApi;
