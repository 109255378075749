import React from 'react';
import Ellipse from '../../../assets/img/Ellipse.png'

function BlockCard({img, title, text}) {
    return (
        <div className="desktop:w-[663px] desktop:h-[288px] tablet:h-[216px] mx-8 desktop:mx-2 bg-card-bg m-2 rounded-xl p-8 relative">
            <div className="flex flex-col tablet:mr-40 z-50 relative">
                <h6 className="font-bold mb-4">{title}</h6>
                <p>
                    {text}
                </p>
            </div>
            <img src={Ellipse} alt="э" className="hidden tablet:block absolute bottom-0 right-0 z-0 rounded-br-xl"/>
            <img src={img} alt={title} className="hidden tablet:block absolute bottom-1 right-4"/>
        </div>
    );
}

export default BlockCard;
