import { api } from '../api';

const subsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSubs: build.query({
            query: (filters) => ({
                url: `/subscription/list${filters && filters}`,
                method: 'GET'
            })
        }),
        postSubs: build.mutation({
            query: (payload) => ({
                url: `/subscription/create`,
                method: 'POST',
                body: payload
            })
        }),
        deleteSubs: build.mutation({
            query: (id) => ({
                url: `/subscription/delete?subscription_id=${id}`,
                method: 'DELETE'
            })
        }),
        setSubUser: build.mutation({
            query: (payload) => ({
                url: `/subscription/set-subsc-user`,
                method: 'POST',
                body: payload
            })
        }),
        deleteSubUser: build.mutation({
            query: (payload) => {
                return {
                    url: `/subscription/unset-subsc-user`,
                    method: 'DELETE',
                    body: payload,
                };
            },
        }),
    })
})

export const {
    useLazyGetSubsQuery,
    usePostSubsMutation,
    useDeleteSubsMutation,
    useSetSubUserMutation,
    useDeleteSubUserMutation
} = subsApi;
