import { createSlice } from '@reduxjs/toolkit';

const spinner = createSlice({
    name: 'spinner',
    initialState: {
        loading: false,
    },
    reducers: {
        setIsGlobalLoading(state, action) {
            state.loading = action.payload
        },
    }
})

export default spinner.reducer
export const { setIsGlobalLoading } = spinner.actions;
