const Schools = ({className}) => {
    return (
        <span className={className}>
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.4948 4.332C12.6423 3.57423 11.3577 3.57423 10.5052 4.332L4.50518 9.66533C4.02483 10.0923 3.75 10.7043 3.75 11.347V21.2498H5.25L5.25 15.9478C5.24997 15.0493 5.24994 14.3001 5.32991 13.7053C5.41432 13.0775 5.59999 12.5107 6.05546 12.0553C6.51093 11.5998 7.07773 11.4141 7.70552 11.3297C8.3003 11.2498 9.04952 11.2498 9.94801 11.2498H14.052C14.9505 11.2498 15.6997 11.2498 16.2945 11.3297C16.9223 11.4141 17.4891 11.5998 17.9445 12.0553C18.4 12.5107 18.5857 13.0775 18.6701 13.7053C18.7501 14.3001 18.75 15.0493 18.75 15.9478V21.2498H20.25V11.347C20.25 10.7043 19.9752 10.0923 19.4948 9.66533L13.4948 4.332ZM21.75 21.2498V11.347C21.75 10.2759 21.2919 9.25585 20.4914 8.54422L14.4914 3.21089C13.0705 1.94793 10.9295 1.94793 9.50864 3.21089L3.50864 8.54422C2.70805 9.25585 2.25 10.2759 2.25 11.347V21.2498H2C1.58579 21.2498 1.25 21.5856 1.25 21.9998C1.25 22.414 1.58579 22.7498 2 22.7498H22C22.4142 22.7498 22.75 22.414 22.75 21.9998C22.75 21.5856 22.4142 21.2498 22 21.2498H21.75ZM17.25 21.2498V15.9998C17.25 15.0358 17.2484 14.3882 17.1835 13.9052C17.1214 13.4437 17.0142 13.2462 16.8839 13.1159C16.7536 12.9856 16.5561 12.8784 16.0946 12.8163C15.6116 12.7514 14.964 12.7498 14 12.7498H10C9.03599 12.7498 8.38843 12.7514 7.90539 12.8163C7.44393 12.8784 7.24643 12.9856 7.11612 13.1159C6.9858 13.2462 6.87858 13.4437 6.81654 13.9052C6.75159 14.3882 6.75 15.0358 6.75 15.9998V21.2498H17.25ZM9.25 8.99981C9.25 8.58559 9.58579 8.24981 10 8.24981H14C14.4142 8.24981 14.75 8.58559 14.75 8.99981C14.75 9.41402 14.4142 9.74981 14 9.74981H10C9.58579 9.74981 9.25 9.41402 9.25 8.99981ZM8.25 15.4998C8.25 15.0856 8.58579 14.7498 9 14.7498H15C15.4142 14.7498 15.75 15.0856 15.75 15.4998C15.75 15.914 15.4142 16.2498 15 16.2498H9C8.58579 16.2498 8.25 15.914 8.25 15.4998ZM8.25 18.4998C8.25 18.0856 8.58579 17.7498 9 17.7498H15C15.4142 17.7498 15.75 18.0856 15.75 18.4998C15.75 18.914 15.4142 19.2498 15 19.2498H9C8.58579 19.2498 8.25 18.914 8.25 18.4998Z"
                      fill="currentColor"/>
            </svg>
        </span>
    )
}

export default Schools;