import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PackageCard from './PackageCard';
import atamura from '../../../assets/img/atamura.png';
import mektep from '../../../assets/img/mektep.png';
import kokzhiek from '../../../assets/img/kokzhiek.png';
import eksmo from '../../../assets/img/eksmo.png';
import anaTili from '../../../assets/img/Ana-tili.png';
import { useTranslation } from 'react-i18next';
import { LIBRARY_VIEW_FILTER_NAME } from '../../../views/library/Library';

function PackageCarousel({isPriceHidden}) {
    const {t} = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 834,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                <div>
                    <PackageCard
                        img={mektep}
                        src="https://mektep.kz/opiqreg"
                        price={t('mainPage.packages.price')}
                        searchSrc="/library"
                        searchFilter={{
                            filterName: LIBRARY_VIEW_FILTER_NAME,
                            filterValue: { publisher: "Мектеп" }
                        }}
                        type="Заказать в издательстве"
                        isPriceHidden={isPriceHidden}
                    />
                </div>
                <div>
                    <PackageCard
                        img={atamura}
                        src="https://oqulyqtar.atamura.kz/"
                        price={t('mainPage.packages.price')}
                        searchSrc="/library"
                        searchFilter={{
                            filterName: LIBRARY_VIEW_FILTER_NAME,
                            filterValue: { publisher: "Атамұра" }
                        }}
                        type="Заказать в издательстве"
                        isPriceHidden={isPriceHidden}
                    />
                </div>
            </Slider>
        </>
    );
}

export default PackageCarousel;
