import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {dropFilter} from '../../../../store/reducers/filterSlice';
import {LIBRARY_VIEW_FILTER_NAME} from '../../../../views/library/Library';

function About(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const clearLibraryFilters = () => {
        dispatch(dropFilter({ filterName: LIBRARY_VIEW_FILTER_NAME }))
    }

    return (
        <div className="tablet:w-1/2 text-footer-text text-sm flex flex-col mb-4 order-1 tablet:order-2 mx-2">
            <p className="font-bold mb-2">{t('general.footer.aboutProject')}</p>
            <Link to="/library" className="footer-link mb-1" onClick={clearLibraryFilters}>{t('general.footer.library')}</Link>
            <Link to="/packages" className="footer-link mb-1">{t('general.footer.packages')}</Link>
            <Link to="/eula" className="footer-link mb-1">{t('general.footer.termsOfUse')}</Link>
            <Link to="/privacy" className="footer-link mb-1">{t('general.footer.privacyPolicy')}</Link>
            <Link to="/support" className="footer-link mb-1">{t('general.footer.supportService')}</Link>
        </div>
    );
}

export default About;
