import React, {useEffect} from 'react';
import Navbar from './navbar/Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './footer/Footer';
import {useSelector} from "react-redux";

function Layout(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const username = useSelector(state=>state.user.username);
    const user = JSON.parse(localStorage.getItem('user'))

    return (
        <div className="relative min-h-[100vh]">
            <header>
                <Navbar/>
            </header>
            <main className={`tablet:pb-[353px] ${location.state && location.state.url ? 'overflow-hidden' : 'mx-auto desktop:px-6 max-w-[1440px]'}`}>
                {user ? (username ? <Outlet /> : <></>) : <Outlet/>}
            </main>
            <footer className="w-full tablet:absolute bottom-0">
                <Footer/>
            </footer>
        </div>
    );
}

export default Layout;
