const MCheckboxGroup = ({ value = [], setValue, options }) => {
    const handleChange = (checkboxKey) => {
        if (value.includes(checkboxKey)) {
            setValue(value.filter(item => item !== checkboxKey))
        } else {
            setValue([...value, checkboxKey])
        }
    }

    return <div className="flex flex-col gap-2">
        {options?.map(item => (
            <div key={item.value}>
                <input
                    onChange={() => handleChange(item.value)}
                    type="checkbox"
                    className="mx-2"
                    name={item.value}
                    value={item.value}
                    checked={value.includes(item.value)}
                />
                {item.label}
            </div>
        ))}
    </div>
}

export default MCheckboxGroup