import {
    addMonths,
    format,
    isBefore,
    parse,
    subDays,
    subMonths
} from 'date-fns';
import {ru, enUS, kk } from 'date-fns/locale';
import {memo, useRef, useState} from 'react';
import {useClickOutside} from "../../../hooks/useClickOutside";
import Day from "./components/Day";
import MIcon from "../../common/m-icon/MIcon";
import {getStructuredDate, isBetweenDates} from "./utils";
import {useTranslation} from "react-i18next";

const dayTitles = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const PeriodSelectCalendar = ({
    toggleCaledar,
    selectedDate,
    setFormData,
    period
}) => {
    const ref = useRef(null);

    const [currentDate, setCurrentDate] = useState((selectedDate));

    const structuredDate = getStructuredDate(currentDate);

    const handleDateChange = (modifyDate) => {
        setCurrentDate(modifyDate(currentDate, 1));
    };

    useClickOutside(ref, () => {
        toggleCaledar(prev => !prev);
    });

    const handleDateSelect = (date) => {
        setFormData(prev => {
            if (!prev.start_date) {
                return {...prev, start_date: format(date, 'dd.MM.yyyy')};
            } else {
                toggleCaledar(prev => !prev);
                return {...prev, end_date: format(date, 'dd.MM.yyyy'), totalDate: prev.start_date+ " - " + format(date, 'dd.MM.yyyy') };
            }
        });
    }

    const {t, i18n} = useTranslation();

    return (
        <div
            ref={ref}
            onClick={e => e.stopPropagation()}
            className={'border border-black bg-white rounded shadow absolute left-4 top-4 z-10'}
        >
            <div className={'m-3 text-gray4 font-medium flex justify-between items-center'}>
                <div className={'flex'}>


                    {/*<div*/}
                    {/*    className={''}*/}
                    {/*    onClick={() => handleDateChange(subYears)}*/}
                    {/*/>*/}

                    <span
                        className={'rotate-180 cursor-pointer'}
                        onClick={() => handleDateChange(subMonths)}
                    >
                        <MIcon type={'arrowRight'}/>
                    </span>
                </div>

                <div className={'tracking-[1px]'}>
                    <span className={'px-2'}>
                        {format(currentDate, 'yyyy')}
                    </span>

                    <span className={'px-2'}>
                        {format(currentDate, 'LLLL', {locale: i18n.language==='ru' ? ru : i18n.language==='en' ? enUS : kk })}
                    </span>
                </div>

                <div>
                     <span
                         className={'rotate-180 cursor-pointer'}
                         onClick={() => handleDateChange(addMonths)}
                     >
                        <MIcon type={'arrowRight'}/>
                    </span>

                    {/*<div*/}
                    {/*    onClick={() => handleDateChange(addYears)}*/}
                    {/*/>*/}
                </div>
            </div>

            <table className={'border-collapse border-spacing-0 m-4'}>
                <tbody>
                <tr className={'text-gray4 text-sm border-b border-gray4 border-spacing-[10px]'}>
                    {dayTitles.map((title) => (
                        <th className="p-[10px]" key={title}>
                            {t(`general.days.${title}`)}
                        </th>
                    ))}
                </tr>

                {structuredDate.map((week, i) => (
                    <tr
                        key={i}
                    >
                        {week.map((date) => {
                            const inPeriod = isBetweenDates(date, parse(period.startDate, 'dd.MM.yyyy', new Date()), parse(period.endDate, 'dd.MM.yyyy', new Date()), (period.startDate ? 'endDate' : 'startDate'));
                            return (
                                <td
                                    key={date.getTime()}
                                    className={`p-[10px] hover:bg-blue6 text-center transition-all duration-[.1s] cursor-pointer ${(inPeriod || isBefore(date, subDays(selectedDate,1))) && '!bg-blue6 !cursor-not-allowed'} `}
                                    onClick={() => (inPeriod || isBefore(date, subDays(selectedDate,1))) ? () => {
                                    } : handleDateSelect(date)}
                                >
                                    <Day
                                        date={date}
                                        currentDate={currentDate}
                                        selectedStartDate={selectedDate}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default memo(PeriodSelectCalendar);